@use "sass:map";
@use "../settings/colors";
@use "../settings/fonts";
@use "../settings/paths";
@use "../mixins/typographic-item" as typographic;
@use "../functions/em-calc";
@use "../zurb/unit";
@use "headings-mixins";

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-family: fonts.$disp-bold;
	line-height: 1.1;
	margin-bottom: 0.75rem;
	margin-top: 1em;
	padding: 0;
	font-weight: fonts.$font-weight-black;

	&:first-child {
		margin-top: 0;
	}

	&.has-background {
		padding: 1.25em 2.375em;
	}

	&.has-icon {
		align-items: center;
		display: flex;

		img {
			margin-right: unit.rem-calc(8);
		}
	}
}

h1,
.h1 {
	@include headings-mixins.h1();
}

h1.small {
	@include headings-mixins.h1((
		"font-size": (
			36,
			48,
			56,
			63,
			70,
		)
	));
}

h2,
.h2 {
	@include headings-mixins.h2();

	&.font-h2 {
		font-size: em-calc.func(42, 13);
		grid-column: 1/-1;
		max-width: 580px;
		margin-left: auto;
		margin-right: auto;
		width: 100%;
	}
}

h3,
.h3 {
	@include headings-mixins.h3();

	&.smaller-h3 {
		font-size: unit.rem-calc(24, 13);
	}
}

h4,
.h4 {
	@include headings-mixins.h4();
}

h5,
.h5 {
	@include headings-mixins.h5();
}

h6,
.h6 {
	@include headings-mixins.h6();
}

.is-style-uppercase {
	@include headings-mixins.h4((
		"font-size": (
			12,
			12,
			12,
			14,
			16,
		),
	));
	letter-spacing: em-calc.func(2, 16);
	margin-top: 3rem;
	text-transform: uppercase;

	&:not(:last-child) {
		margin-bottom: 3rem;
	}

	&::after {
		content: "";
		display: block;
		background-color: colors.$red-light;
	    height: 2px;
	    margin-top: 1rem;
	    width: 2.5em;
	}
	&.has-text-align-center::after {
		margin: 1rem auto 0 auto;
	}
	&.has-text-align-right::after {
		margin-left: auto;
	}
}
