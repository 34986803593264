@use "../settings/colors";
@use "../settings/fonts";
@use "../settings/numbers";
@use "../functions/clamps";
@use "../functions/em-calc";
@use "../mixins/fluid-property";
@use "../zurb/breakpoint";
@use "../zurb/unit" as unit;

.quote {
	$prefix: ".quote";
	color: #FFFFFF;

	&__text {
		font-family: fonts.$disp-bold;
		font-weight: fonts.$font-weight-bold;
		font-size: clamps.func(24px, 31px, large);
		line-height: 1.4;
		margin-bottom: 0.875em;
	}

	&__image {
		position: relative;

		&:before {
			content: "";
			display: block;
			padding-top: 100%;
		}

		img {
			border-radius: 100%;
			height: 100%;
			left: 0;
			object-fit: cover;
			position: absolute;
			top: 0;
			width: 100%;
			max-width: 215px;
		}
	}

	&__cite {
		line-height: 1.4;
		margin-top: 1em;
		position: relative;
	}

	&__name {
		font-family: fonts.$disp-bold;
		font-weight: fonts.$font-weight-bold;
		font-size: 20px;
	}

	&__job-title {
		font-family: fonts.$disp-medium;
		font-size: 16px;
	}

	&.is-style-default {
		background-color: colors.$baker-black;
		font-family: fonts.$disp-medium;
		display: grid;
		grid-template-columns: 1fr 11.25rem 10.125rem 32rem 10.125rem 11.25rem 1fr;

		
		@media screen and (max-width: 1256px) and (min-width:992px) {
			grid-template-columns:1fr #{numbers.$default-column-width} #{numbers.$narrow-column-width} #{numbers.$default-column-width} 1fr;
		}

		@media screen and (max-width: 991px) {
			grid-template-columns: min(7vw, 50px) auto min(7vw, 50px) !important;
		}

		#{$prefix}__inner {
			grid-column: 1/-1 !important;
			padding-bottom: clamps.func(60, 80, large);
			padding-top: clamps.func(50, 90, large);

			@include breakpoint.mx(small) {
				padding-left: 5%;//unit.rem-calc(16, 13);
				padding-right: 5%;//unit.rem-calc(16, 13);
			}

			@include breakpoint.mx(medium) {
				align-items: center;
				display: flex;
			}

			@media screen and (min-width: 992px) and (max-width: 1255px) {
				grid-column: 2/-2 !important;
			}
		}

		#{$prefix}__content {

			@include breakpoint.mx(medium) {
				padding-left: 8%;
				padding-right: 8%;
			}

			@include breakpoint.mx(large) {
				padding-left: 0;
				//padding-left: 20%;
				//padding-right: 10%;
			}
		}

		#{$prefix}__subtitle {
			font-size: 1.25rem;
			font-weight: fonts.$font-weight-bold;
			line-height: 1.7;
			margin-bottom: .25em;
			text-align: center;
		}

		#{$prefix}__title {
			margin-bottom: 1.25em;

			@include breakpoint.mx(medium) {
				margin-bottom: 2em;
			}

			img {
				display: block;
				margin: 0 auto;
			}
		}

		#{$prefix}__image-container {
			flex: 0 0 auto;
			margin: 0 auto;
			max-width: 350px;
			width: 100%;

			@include breakpoint.mx(medium) {
				max-width: em-calc.func(215) !important;
			}
		}

		#{$prefix}__image {
			margin: 0 auto;
			max-width: 212px;

			@include breakpoint.mx(medium) {
				max-width: 100%;
			}
		}

		#{$prefix}__footer {
			@include breakpoint.mx(medium) {
				display: flex;
				justify-content: center;
			}
		}

		#{$prefix}__cite {
			text-align: center;
		}

		#{$prefix}__link {
			font-family: fonts.$text-regular;
			font-size: 14px;
			line-height: 1.5;

			a {
				align-items: center;
				color: #FFFFFF;
				display: inline-flex;
				text-decoration: underline;

				&:hover {
					color: #CCCCCC !important;
					text-decoration: none;
				}
			}

			img {
				margin-left: 10px;
			}
		}
	}

	&.is-style-vertical {
		background-color: colors.$red-light;
		padding: em-calc.small(24);

		@include breakpoint.mx(medium) {
			padding: em-calc.xxlarge(78) em-calc.xxlarge(46) em-calc.xxlarge(78) em-calc.xxlarge(66); // We know what the values are supposed to be at its largest size, so set padding based on that.
		}

		#{$prefix}__image-container {
			align-items: center;
			display: flex;
		}

		#{$prefix}__image {
			flex: 0 0 auto;
			margin-right: 24px;
			width: 80px; // Should be the same for all breakpoints, so we use pixels.
		}

		#{$prefix}__cite {
			margin-top: 0;
		}
	}
}
