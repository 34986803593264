@use 'zurb/unit' as unit;
@use 'settings/fonts' as fonts;
@use 'settings/colors' as colors;

// special styling for /request-info/ and /visit/ pages
body.page-id-2162, body.page-id-2329 {
	.callout-contact {
		background: none;

		@media print,screen and (min-width: 48em) {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
	.callout-contact__form {
		max-width: 100%;
	}
}

.page-id-2216 {
	.callout-contact .callout-contact__sub-text br{
		display: block;
	}

	.tribe-compatibility-container .tribe-events-view--widget-events-list .tribe-events-widget-events-list__events .tribe-events-widget-events-list__event-row .tribe-events-widget-events-list__event-date-tag-month {
    	line-height: 1.2em !important;
	}

	.tribe-events-header__messages {
		display: none !important;
	}
}

.callout-contact {
	background: #F7FAFA;
	padding-bottom: 4.375em;
	margin-top: 0;
	
	@media (min-width: 992px) {
		padding: 4em 0 6.5625em
	}
}

.callout-contact__copy {
	padding-bottom: 2.8125em;
	text-align: center;

	@media (min-width: 992px) {
		padding-bottom: 4.6875em
	}
}

.callout-contact__intro {
	color: #E7002D;
	font-family: fonts.$disp-medium;
	font-weight: fonts.$font-weight-bold;
	line-height: 1.5em;
	padding: 2em 0 .125em;
	font-size: unit.rem-calc(16, 13);

	@media (min-width: 992px) {
		padding-bottom: 1.125em;
		font-size: unit.rem-calc(13, 13);
	}
}

.callout-contact__cta {
	font-family: fonts.$disp-bold;
	font-weight: fonts.$font-weight-black;
	font-size: unit.rem-calc(24, 13);
	letter-spacing: -.02083em;
	padding-bottom: .375em;

	@media (min-width: 992px) {
		font-size: unit.rem-calc(44, 13);
		letter-spacing: -.01852em;
		line-height: 1.11111em;
		padding-bottom: .03704em
	}
}

.callout-contact__sub-text p {
	font-family: fonts.$body;
	font-size: unit.rem-calc(18, 13);
	line-height: 1.5em;

	@media (min-width: 992px) {
		font-size: unit.rem-calc(16, 13);
		line-height: 1.5em;
            max-width: 700px;
            margin: 1rem auto;
            padding: 0 20px;

		br {
			display: none
		}
	}
}

.callout-contact__form {
	margin: 0 auto;
	max-width: 33.6875em;
	position: relative
}

.callout-contact__form-field {
	padding-bottom: 2.125em
}

.callout-contact__form-field label {
	display: block;
	font-family: fonts.$disp-medium;
	margin-bottom: 1.0625em;
}

@media (min-width: 992px) {
	.callout-contact__form-field label {
		margin-bottom: 1.25em
	}
}

.callout-contact__form-field input {
	border: solid 1px #D2D2D2;
	display: block;
	height: 3.75em;
	line-height: 3.75em;
	padding: 0 .9375em;
	width: 100%
}

@media (min-width: 992px) {
	.callout-contact__form-field--half {
		float: left;
		width: 50%;
	}
	.callout-contact__form-field--half:last-child input {
		border-left: none
	}
}

.callout-contact__form-field-multi-col::after {
	clear: both;
	content: "";
	display: block
}

.callout-contact__form-submit {
	display: block;
	font-family: fonts.$disp-medium;
	transition: all .45s ease;
	width: 100%;
}

@media (min-width: 992px) {
	.callout-contact__form-submit {
		margin-top: .5em
	}
}

.callout-contact__error-message {
	display: none;
	padding: 0 0 2.5em;
}

.callout-contact label.error {
	display: none !important
}

.callout-contact input.error {
	border: solid 1px #D2D2D2;
	border-color: red
}

.callout-contact__form .wFormContainer .primaryAction {
	background: 0 0;
	border: solid 3px colors.$red-dark;
	color: colors.$red-dark;
	margin-top: 0;
	max-width: none;
	font-weight: fonts.$font-weight-bold;
}

.callout-contact__form .wFormContainer .primaryAction:hover {
	background: #E7002D;
	border-color: #E7002D;
	color: #FFFFFF
}

.content .callout-contact .callout-contact__cta {
	font-size:1.5rem
}
.content .callout-contact .callout-contact__copy {
	padding-bottom:24px
}
@media screen and (max-width: 62em) {
	.callout-contact .callout-contact__copy .callout-contact__cta:first-child {
		padding-top:32px
	}
}
@media screen and (max-width: 47.99875em) {
	.content .callout-contact {
		margin:0 -20px;
		padding:40px 20px
	}
}
@media print,screen and (min-width: 48em) {
	.content .callout-contact {
		padding-bottom:50px;
		padding-top:50px
	}
}
@media screen and (min-width: 23.4375em)and (max-width: 47.9375em) {
	.content .callout-contact .callout-contact__cta {
		font-size:calc(20.1832061069px + 1.0178117048vw)
	}
}
@media screen and (min-width: 48em) {
	.content .callout-contact .callout-contact__cta {
		font-size:28px
	}
}
