@use "sass:map";
@use "sass:math";
@use "../mixins/typographic-item";
@use "../settings/colors";
@use "../settings/fonts";
@use "../settings/numbers";
@use "../functions/em-calc";
@use "../zurb";

@mixin button($settings: ()) {
	$border-size: 2px;

	$defaults: (
		"border-color": colors.$red-light,
		"background": colors.$red-light,
		"color": colors.$white,
		"padding-left": 2rem,
		"padding-right": 2rem,
		"padding-top": calc(1rem - 2px),
		"padding-bottom": calc(1rem - 2px),
	);
	$args: map.merge($defaults, $settings);

	@include typographic-item.mx($args);
	align-items: center;
	border-style: solid;
	border-radius: 10em;
	border-width: $border-size;
	cursor: pointer;
	display: inline-flex;
	font-family: fonts.$disp-medium;
	font-size: em-calc.func(16, 13);
	//font-weight: 500;
	font-weight: fonts.$font-weight-bold;
	justify-content: center;
	line-height: 1;
	max-width: 100%;
	position: relative;
	text-decoration: none;
	white-space: nowrap;
	text-overflow: ellipsis;
	transition: background-color .2s ease;
}

@mixin button-focus($settings: ()) {
	$defaults: (
		"border-radius": 100px,
		"stroke-color": colors.$red-light,
	);
	$args: map.merge($defaults, $settings);

	$border-radius: map.get($args, "border-radius");
	$stroke-color: map.get($args, "stroke-color");

	outline: none; // Outline is replaced with fancy border.

	&:before {
		$offset: 7px;
		border: 2px dotted $stroke-color;
		border-radius: #{$border-radius};
		content: "";
		height: calc(100% + #{$offset} * 2);
		left: $offset * -1;
		pointer-events: none;
		position: absolute;
		top: $offset * -1;
		width: calc(100% + #{$offset} * 2);
	}
}
