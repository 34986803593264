@use "sass:math";
@use "../mixins/absolute-fit";
@use "../mixins/object-fit";

.wp-block-embed {
	display: block !important; // Override the display:grid.
	width: 100%;

	&.wp-embed-aspect-16-9 {

		.wp-block-embed__wrapper {
			position: relative;

			&:before {
				content: "";
				display: block;
				padding-top: math.div(9, 16) * 100%;
			}
		}

		iframe {
			@include absolute-fit.mx();
			@include object-fit.mx();
		}
	}
}