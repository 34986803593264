@use "settings/colors" as color;
@use "settings/fonts" as fonts;
@use "mixins/typographic-item" as typographic;
@use "zurb/unit" as unit;
@use "zurb" as z;

.footer-section {
	$module: ".footer-section";
	background-color: #1f2228;
	height: auto;
	width: 100%;
	position: relative
}

@media screen and (max-width: 991px) {
	.footer-section {
		padding-bottom:4.375em
	}
}

body .footer-section__wrapper {
  padding: 1.875em 0 !important;
}

@media screen and (max-width: 1455px) {
	body .footer-section__wrapper {
		padding:1.875em 1.25em !important;
	}
}

@media screen and (min-width: 769px) {
	.footer-section__wrapper {
		padding-top:1.875em !important;
		padding-bottom: 1.875em !important;
	}
}

.footer-section__rows, .footer-section .wp-block-column {
	list-style: none;
	color: #c9ced1;
	font-family: fonts.$disp-regular;
	-webkit-font-smoothing: antialiased;
	line-height: 1;
	font-size: unit.rem-calc(16, 13);
	padding: 0;
	float: left;
	display: block;
	margin-right: 1.69492%;
	width: 83.05085%;

	p {
		font-family: fonts.$disp-regular, sans-serif;
	}
}

.footer-section #menu-disclosures li,
.footer-section .wp-block-column:last-child p {
    line-height: 1.63;
}

.footer-section__wrapper .wp-block-column:last-child {
	display: flex;
	flex-direction: column;

	.widget {
		&:nth-of-type(1) {
			order: 4;
			padding: 1em 0 2em;
		}

		&:last-child {
			order: 3;
		}
	}

	@media screen and (min-width: 769px) {
		display: block;

		.widget {
			&:nth-of-type(1) {
				padding: 0;
				padding-bottom: 0;
				padding-top: 0;
			}
		}
	}
}

.footer-section__rows:last-child, .footer-section .wp-block-column:last-child {
	margin-right: 0
}

@media screen and (min-width: 769px) {
	.footer-section__rows, .footer-section .wp-block-column {
		float:left;
		display: block;
		margin-right: 1.69492%;
		margin: 0;
		line-height: 2.25
	}

	.footer-section__rows:last-child, .footer-section .wp-block-column:last-child {
		margin-right: 0
	}
}

.footer-section__title, 
.footer-section .widgettitle, 
.footer-section .wp-block-column h2,
.footer-section .wp-block-column:last-child p:not(.footer-section__title-mobile),
.footer-section .menu-admissions-container li:not(:first-child),
.footer-section .menu-about-baker-container li:not(:first-child),
.footer-section .menu-tools-resources-container li:not(:first-child)  {
	display: none
}

@media screen and (min-width: 769px) {
	.footer-section__title, .footer-section .widgettitle, .footer-section .wp-block-column h2 {
		display:block;
		font-size: 1em;
		color: #fff;
		font-family: fonts.$disp-medium;
		-webkit-font-smoothing: antialiased;
		line-height: 2.25;
	}

	.footer-section .wp-block-column:last-child p:not(.footer-section__title-mobile),
	.footer-section .menu-admissions-container li:not(:first-child),
	.footer-section .menu-about-baker-container li:not(:first-child),
	.footer-section .menu-tools-resources-container li:not(:first-child) {
		display: block;
	}

	.footer-section .menu-admissions-container li:first-child,
	.footer-section .menu-about-baker-container li:first-child,
	.footer-section .menu-tools-resources-container li:first-child {
		display: none;
	}
}

.footer-section__title-mobile, 
.footer-section .menu-admissions-container li:first-child,
.footer-section .menu-about-baker-container li:first-child,
.footer-section .menu-tools-resources-container li:first-child {
	font-size: 1em;
	line-height: 2.25;
	color: #fff;
	display: block;
	margin-right: 1.69492%;
	width: 49.15254%;
	font-family: fonts.$disp-medium;
	-webkit-font-smoothing: antialiased
}

.footer-section__title-mobile:last-child {
	margin-right: 0
}

@media screen and (min-width: 769px) {
	.footer-section__title-mobile {
		display:none
	}
}

.footer-section__title-mobile_end-text {
	padding-bottom: 1.875em
}

@media screen and (min-width: 769px) {
	.footer-section__title-mobile_end-text {
		display:none
	}
}

.footer-section__links a, .footer-section .wp-block-column a {
	text-decoration: none;
	color: #c9ced1;
	font-size: unit.rem-calc(16, 13);
}

.footer-section__links a:hover, .footer-section .wp-block-column a:hover {
	text-decoration: none;
	color: #fff
}

.footer-section__links_mobile {
	display: none
}

@media screen and (min-width: 769px) {
	.footer-section__links_mobile {
		display:block
	}
}

@media screen and (min-width: 769px) {
	.footer-section__links_social-icons {
		padding-top:1.125em;
		padding-bottom: 1.125em;
	}
}

.footer-section__links_social-icons_group {
	padding-right: .75em
}

.social-icons_facebook svg:hover #facebook #Oval {
	stroke: #3b5998;
	fill: #3b5998
}

.social-icons_facebook svg:hover #facebook #path-1 {
	fill: #3b5998
}

.social-icons_facebook svg:hover #facebook #Shape {
	fill: #fff
}

.social-icons_youtube svg:hover #youtube #Oval {
	stroke: #e52d27;
	fill: #e52d27
}

.social-icons_youtube svg:hover #youtube #path-1 {
	fill: #e52d27
}

.social-icons_youtube svg:hover #youtube #Shape {
	fill: #fff
}

.social-icons_twitter svg:hover #twitter #Oval {
	stroke: #42c0fb;
	fill: #42c0fb
}

.social-icons_twitter svg:hover #twitter #path-1 {
	fill: #42c0fb
}

.social-icons_twitter svg:hover #twitter #Shape {
	fill: #fff
}

.social-icons_instagram svg:hover #instagram #Oval {
	fill: url(#img1);
	stroke: url(#instaGradient1)
}

.social-icons_instagram svg:hover #instagram #Fill-1 {
	fill: #fff
}

.social-icons_linkedin svg:hover #linkedin #Oval {
	stroke: #4875b4;
	fill: #4875b4
}

.social-icons_linkedin svg:hover #linkedin #path-1 {
	fill: #4875b4
}

.social-icons_linkedin svg:hover #linkedin #Shape {
	fill: #fff
}

.footer-section__links_contact {
	display: none
}

@media screen and (min-width: 769px) {
	.footer-section__links_contact {
		display:block;
		color: #c9ced1;
		font-family: fonts.$disp-regular;
		-webkit-font-smoothing: antialiased;
		font-size: 1em;
		line-height: 1.63
	}
}

.footer-section__links_contact .footer-section__links_contact_underlined {
	text-decoration: none;
	font-family: fonts.$text-regular;
	-webkit-font-smoothing: antialiased
}

.footer-section__links_contact .footer-section__links_contact_underlined:hover {
	color: #E7002D
}

.footer-section__links_contact-mobile {
	display: block;
	color: #c9ced1;
	font-family: fonts.$disp-regular;
	-webkit-font-smoothing: antialiased;
	font-size: 1em;
	line-height: 1.63;
	float: left;
	display: block;
	margin-right: 1.69492%;
	width: 100%
}

.footer-section__links_contact-mobile:last-child {
	margin-right: 0
}

@media screen and (min-width: 769px) {
	.footer-section__links_contact-mobile {
		display:none
	}
}

.footer-section__links_contact-mobile a {
	color: #c9ced1
}

.footer-section__links_contact-mobile .footer-section__links_contact_underlined {
	text-decoration: none!important;
	font-family: fonts.$text-regular;
	-webkit-font-smoothing: antialiased
}

.footer-section__links_contact-mobile_end-text {
	padding-bottom: .875em
}

@media screen and (min-width: 769px) {
	.footer-section__links_contact-mobile_end-text {
		display:none
	}
}

.footer-section__links_address,.footer-section__links_phone {
	display: none
}

@media screen and (min-width: 769px) {
	.footer-section__links_address,.footer-section__links_phone {
		display:block
	}
}

.footer-section__links_contact_underlined {
	text-decoration: none!important;
	font-family: fonts.$text-regular;
	-webkit-font-smoothing: antialiased
}

.footer-section__links_contact_break {
	color: #c9ced1
}

.footer-section__bottom {
	padding-top: 1.875em;
	padding-bottom: 1.875em;
	color: #c9ced1;
	display: block
}

@media screen and (min-width: 769px) {
	.footer-section__bottom {
		border-top:solid 1px #505258;
		padding-top: 1.875em !important;
		padding-bottom: 1.875em !important;
	}
}

.footer-section__logo {
	display: none
}

@media screen and (min-width: 769px) {
	.footer-section__logo {
		float:left;
		display: block;
		margin-right: 1.69492%;
		width: 15.25424%
	}

	.footer-section__logo:last-child {
		margin-right: 0
	}
}

@media screen and (min-width: 992px) {
	.footer-section__logo {
		margin-right:0
	}
}

.footer-section__logo img {
	width: 8.4375em
}

.footer-section__logo-mobile {
	position: absolute;
	top: 2.5em;
	right: 1.25em;
	float: left;
	display: block;
	margin-right: 1.69492%;
	width: 32.20339%
}

.footer-section__logo-mobile:last-child {
	margin-right: 0
}

@media screen and (min-width: 769px) {
	.footer-section__logo-mobile {
		display:none
	}
}

.footer-section__accredited {
	color: #c9ced1;
	margin-top: 0;
	font-size: .75em;
	line-height: 1.5;
	font-family: fonts.$text-regular;
	-webkit-font-smoothing: antialiased;
	float: left;
	display: block;
	margin-right: 1.69492%;
	width: 100%
}

.footer-section__accredited:last-child {
	margin-right: 0
}

@media screen and (min-width: 769px) {
	.footer-section__accredited {
		float:left;
		display: block;
		margin-right: 1.69492%;
		width: 49.15254%
	}

	.footer-section__accredited:last-child {
		margin-right: 0
	}

	.footer-section__accredited a:hover {
		color: #fff
	}
}

.footer-section__accredited a {
	color: #c9ced1
}

.footer-section__link_underline, .footer-section__accredited a  {
	font-family: fonts.$text-regular;
	-webkit-font-smoothing: antialiased;
	cursor: pointer;
	text-decoration: underline;
}

.footer-section__link_underline a:focus {
	outline: 2px dotted #E7002D
}

.footer-section__bottom_rights-reserved {
	float: left;
	display: block;
	margin-right: 1.69492%;
	width: 100%;
	font-size: .75em;
	font-family: fonts.$text-regular;
	-webkit-font-smoothing: antialiased;
	padding-top: 1.875em;
	padding-bottom: 1.875em
}

.footer-section__bottom_rights-reserved:last-child {
	margin-right: 0
}

@media screen and (min-width: 769px) {
	.footer-section__bottom_rights-reserved {
		float:left;
		display: block;
		margin-right: 1.69492%;
		width: 23.72881%;
		margin-left: 8.47458%;
		padding-top: 1.3125em
	}

	.footer-section__bottom_rights-reserved:last-child {
		margin-right: 0
	}
}

.footer-section__bottom_rights-reserved:hover {
	color: #c9ced1
}

.footer-section ul.menu {
  padding-left: 0 !important;
}

@media screen and (max-width: 769px){
  .footer-section__wrapper .wp-block-columns {
    display: flex;
    flex-direction: column;
  }

  .footer-section__wrapper .menu {
    margin: 0;
  }

  .footer-section .menu-admissions-container li:first-child,
  .footer-section .menu-about-baker-container li:first-child,
  .footer-section .menu-tools-resources-container li:first-child,
  body .footer-section .wp-block-column .footer-section__title-mobile {
    line-height: 0.7;
  }

}


/* WLIQ */

.footer-section {
	padding-bottom: 4.375em;
}

@media screen and (max-width: 1455px){
	body .footer-section__wrapper {
		padding: 1.875em 1.25em !important;
	}
}

@media screen and (max-width: 1360px){
	.footer-section__links_social-icons > a {
		width: 45px;
		height: 45px;
	}

	.footer-section__links_social-icons > a[href*=linkedin] svg,
	.footer-section__links_social-icons > a[href*=twitter] svg,
	.footer-section__links_social-icons > a[href*=youtube] svg,
	.footer-section__links_social-icons > a[href*=instagram] svg {
		width: 20px;
	}

	.footer-section__links_social-icons > a[href*=facebook] svg {
		width: 14px;
	}
}

@media screen and (max-width: 999px){
	.footer-section__links_social-icons > a {
		width: 38px;
		height: 38px;
	}

	.footer-section__links_social-icons > a[href*=linkedin] svg,
	.footer-section__links_social-icons > a[href*=twitter] svg,
	.footer-section__links_social-icons > a[href*=youtube] svg,
	.footer-section__links_social-icons > a[href*=instagram] svg {
		width: 15px;
	}

	.footer-section__links_social-icons > a[href*=facebook] svg {
		width: 10px;
	}
	.footer-section__links_social-icons > a {
		width: 30px;
		height: 30px;
	}

	.footer-section__links_social-icons > a[href*=linkedin] svg,
	.footer-section__links_social-icons > a[href*=twitter] svg,
	.footer-section__links_social-icons > a[href*=youtube] svg,
	.footer-section__links_social-icons > a[href*=instagram] svg {
		width: 14px;
	}

	.footer-section__links_social-icons > a[href*=facebook] svg {
		width: 9px;
	}
	.campus-modal-content {
		transform: translate(0, 50%);
	}
	.campus-modal-content .campus-Slides {
		max-width: 90%;
	}
	.campus-modal-content .prev, .campus-modal-content .next {
		width: 25px;
	}
}

@media screen and (max-width: 769px){
	.footer-section__wrapper .wp-block-columns {
		display: flex;
		flex-direction: column;
	}

	.footer-section__wrapper .menu {
		margin: 0;
	}

	.footer-section .menu-admissions-container li:first-child,
	.footer-section .menu-about-baker-container li:first-child,
	.footer-section .menu-tools-resources-container li:first-child,
	body .footer-section .wp-block-column .footer-section__title-mobile {
		line-height: 0.7;
	}
	
	.footer-section__links_social-icons {
		justify-content: left;
	}

	.footer-section__links_social-icons > a {
		width: 55px;
		height: 55px;
		margin-left: 15px;
	}

	.footer-section__links_social-icons > a:first-child {
		margin-left: 0;
	}

	.footer-section__links_social-icons > a[href*=linkedin] svg,
	.footer-section__links_social-icons > a[href*=twitter] svg,
	.footer-section__links_social-icons > a[href*=youtube] svg,
	.footer-section__links_social-icons > a[href*=instagram] svg {
		width: 23px;
	}

	.footer-section__links_social-icons > a[href*=facebook] svg {
		width: 14px;
	}
	.splash-slider__mobile {
		min-height: 50vh;
	}
	.text-image--image-first-on-mobile .text-image__inner {
		display: flex;
		flex-direction: column;
	}

	.text-image--image-first-on-mobile .text-image__image {
		margin-bottom: 0;
		order: -1;
	}
}