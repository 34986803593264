@use "sass:map";
@use "../settings/fonts";
@use "../functions/em-calc";
@use "../mixins/typographic-item";
@use "../zurb/unit" as unit;

@mixin p($settings: ()) {
	$defaults: (
		"margin-bottom": 0,
		"margin-top": 0,
	);
	$args: map.merge($defaults, $settings);
	font-family: fonts.$body;
	line-height: 1.7;

	@include typographic-item.mx($args);

	&:not(:last-child) {
		margin-bottom: 1rem;
	}

	font-size: unit.rem-calc(16, 13);

	@media screen and (min-width:992px) {
		font-size: unit.rem-calc(14, 13);
	}

	@media screen and (min-width:1380px) {
		font-size: unit.rem-calc(16, 13);
	}

	@media screen and (min-width:1680px) {
		font-size: unit.rem-calc(18, 13);
	}

	@media screen and (min-width:2000px) {
		font-size: unit.rem-calc(20, 13);
	}
}

@mixin p-small($settings: ()) {
	$defaults: (
		"font-size": em-calc.func(14, 13),
	);
	$args: map.merge($defaults, $settings);

	@include typographic-item.mx($args);
}

@mixin p-large($settings: ()) {
	$defaults: (
		"font-size": em-calc.func(20, 13),
	);
	$args: map.merge($defaults, $settings);

	@include typographic-item.mx($args);
}

@mixin p-huge($settings: ()) {
	$defaults: (
		"font-size": em-calc.func(24, 13),
	);
	$args: map.merge($defaults, $settings);

	@include typographic-item.mx($args);
}

@mixin p-sans($settings: ()) {
	$defaults: (
		"font-family": $text-regular,
	);
	$args: map.merge($defaults, $settings);

	@include typographic-item.mx($args);
}

@mixin blockquote($settings: ()) {
	$defaults: (
		"font-size": (22, 26),
		"margin-top": 0,
	);
	$args: map.merge($defaults, $settings);
	font-weight: 300;
	line-height: 1.5;

	@include typographic-item.mx($args);
}
