@use "../settings/colors";
@use "../settings/fonts";
@use "../settings/namespaces";
@use "../functions/em-calc";

.wp-block-cnpblock-subtitle {
	color: colors.$red-light;
	font-family: fonts.$disp-medium;
	font-size: em-calc.func(14, 13);
	font-weight: fonts.$font-weight-bold;
	//letter-spacing: -0.2px;
	letter-spacing: 1px;
	line-height: 1.7;
	margin-bottom: 0.25em;

	+ :is(#{namespaces.$headings}) {
		margin-top: 0;
	}
}