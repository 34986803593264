@use "zurb/unit";

body.blog,
.post-type-archive-news {
    .sidebar__widget .social-media-widget {

        @media screen and (min-width:992px) {
            justify-content: space-between;

            a {
                width: 35px;
                min-width: 35px;
                height: 35px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-width: 2px;

                &[href*=facebook] svg {
                    width: 12px;
                }

                &[href*=youtube] svg,
                &[href*=twitter] svg,
                &[href*=instagram] svg {
                    width: 20px;
                }

                &[href*=linkedin] svg {
                    width: 17px;
                }
            }
        }
    }

    .events-feed__title a {
        color: #000;
    }

    .events-feed__event:last-child {
        border-bottom: 1px solid #C9CED1;
    }

    .navigation.pagination {
        margin-bottom: 0;

        .nav-links * {
            font-size: unit.rem-calc(18, 13);
        }
    }

    .layout-sidebar-right--stroke-bottom > .row {
        border-bottom: none !important;
    }
}

@media screen and (max-width: 650px){
    .blog__footer-image {
        display: none;
    }

    .blog__footer-bio {
        width: 100%;
        display: block;
    }
}

@media screen and (max-width: 420px){
    .footer-section__links_social-icons > a {
        width: 40px;
        height: 40px;
        margin-left: 15px;
    }

    .footer-section__links_social-icons > a[href*=linkedin] svg,
    .footer-section__links_social-icons > a[href*=twitter] svg,
    .footer-section__links_social-icons > a[href*=youtube] svg,
    .footer-section__links_social-icons > a[href*=instagram] svg {
        width: 18px;
    }

    .footer-section__links_social-icons > a[href*=facebook] svg {
        width: 11px;
    }
}
@media screen and (max-width: 340px){
    .footer-section__links_social-icons > a {
        width: 35px;
        height: 35px;
    }

    .footer-section__links_social-icons > a[href*=linkedin] svg,
    .footer-section__links_social-icons > a[href*=twitter] svg,
    .footer-section__links_social-icons > a[href*=youtube] svg,
    .footer-section__links_social-icons > a[href*=instagram] svg {
        width: 16px;
    }

    .footer-section__links_social-icons > a[href*=facebook] svg {
        width: 10px;
    }
}