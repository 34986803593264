@use "settings/breakpoints";

.hide {
	display: none;
}

.show,
.show-block {
	display: block;
}

.show-flex {
	display: flex;
}

.show-inline {
	display: inline;
}

.srt,
.screen-reader-text {
	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	white-space: nowrap !important;
	border: 0 !important;
}

@each $name, $size in breakpoints.$sizes {
	@media (min-width: #{$size}) {
		.hide-#{$name} {
			display: none;
		}
		.show-#{$name},
		.show-#{$name}-block {
			display: block;
		}
		.show-#{$name}-flex {
			display: flex;
		}
		.show-#{$name}-inline {
			display: inline;
		}
	}
}
