@use "sass:math";
@use "sass:string";
@use "../zurb/breakpoint";

/**
 * @param $properties Either a single or a list of comma-separated properties
 * @param $from The from value as a pixel.
 * @param $to The to value as a pixel.
 * @param $from-width The from breakpoint-width as a pixel.
 * @param $to-width The to breakpoint-width as a pixel.
 */
@mixin mx($properties, $from, $to, $from-width, $to-width, $continue: true, $important: false) {
	$slope: math.div(($to - $from), ($to-width - $from-width));
	$base: $from - $slope * $from-width;
	$force: null;
	@if ($important == true) {
		$force: !important;
	}

	@each $property in $properties {
		@media screen and #{breakpoint.func($from-width)} and #{breakpoint.func($to-width - 1 down)} {
			#{$property}: calc(#{$base} + #{100vw * $slope}) $force;
		}

		@if true == $continue {
			@media screen and #{breakpoint.func($to-width)} {
				#{$property}: $to $force;
			}
		}
	}
}
