@use "zurb";
@use "settings/colors";
@use "settings/numbers";
@use "settings/fonts";
@use "mixins/fluid-property";
@use "zurb/breakpoint";
@use "zurb/unit";

$switch-breakpoint: large;
$switch-breakpoint-down: medium down;
$left-padding: (n, n, 30, 40, 90);

body {
	padding-top: unit.rem-calc(100, 13);

	.pagenav {
		top: 101px;
	}

	@media screen and (min-width: 821px) {
		padding-top: unit.rem-calc(136, 13);
	}

	@include breakpoint.mx(medium) {
		.pagenav {
			top: 113px;
		}
	}
}

.logged-in {
	#wpadminbar {
		position: fixed; // Make it always positioned-fixed.
	}

	body {

		@include breakpoint.mx(large) {
			padding-top: 147px;
		}
	}

	.main-header {
		padding-top: 46px;

		@include breakpoint.mx(783) {
			padding-top: 32px;
		}
	}
}

body.user-is-tabbing li.footer-section__link_underline a:focus,
body.user-is-tabbing li.footer-section__links a:focus {
	outline: 2px dotted #E7002D;
}

body.user-is-tabbing a:focus,
body.user-is-tabbing button:focus,
body.user-is-tabbing h1:focus,
body.user-is-tabbing input:focus,
body.user-is-tabbing select:focus {
	outline: 2px dotted #000000;
	transition: none
}

body:not(.user-is-tabbing) a,
body:not(.user-is-tabbing) button,
body:not(.user-is-tabbing) h1,
body:not(.user-is-tabbing) input,
body:not(.user-is-tabbing) li.footer-section__links a,
body:not(.user-is-tabbing) select {
	outline: 0
}

.main-header {
	background: #FFFFFF;
	border-bottom: solid .0625em transparent;
	font-family: fonts.$text-medium;
	position: fixed;
	top: 0;
	transition: all .45s ease;
	width: 100%;
	z-index: 1000;

	.row {
		max-width: unit.rem-calc( 1150, 13 );

		@media screen and (min-width: 1380px) {
			max-width: unit.rem-calc( 1240px, 13 );
		}

		@media screen and (min-width: 1680px) {
			max-width: unit.rem-calc( 1416px, 13 );
		}
	}
}

@media (max-width: 991px) {
	.main-header--menu-open {
		z-index: 20001
	}
}

.main-header:before {
	bottom: 0;
	content: "";
	left: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
	transition: all .45s ease
}

.main-header:after {
	bottom: 0;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .15);
	content: "";
	display: block;
	height: 2px;
	left: 0;
	position: absolute;
	right: 0;
	width: 100%;
	z-index: 9999;
}

.main-header__container {
	align-items: center;
	display: flex;
	height: 7.14em;
	justify-content: space-between;
	padding: 0;
	position: relative;
	transition: .2s height ease-in-out;
	will-change: height;
}

@media (min-width: 992px) {
	.main-header__container {
		height: 7.3125em
	}
}

.main-header--isSquashed .main-header__container {
	height: 81px;
}

@media (min-width: 992px) {
	.main-header--isSquashed .main-header__container {
		height: 80px;
	}
}

.main-header__mobile-footer-links {
	background: #E7002D;
	bottom: 0;
	display: none;
	height: 4.5625em;
	left: 0;
	position: fixed;
	right: 0;
	text-align: center;
	transition: none;
	z-index: 1000
}

@media (min-width: 992px) {
	.main-header__mobile-footer-links {
		display: none
	}
}

.main-header__mobile-footer-link {
	color: #FFFFFF;
	display: block;
	float: left;
	font-family: fonts.$disp-medium;
	height: 4.5625em;
	line-height: 4.5625em;
	width: 33.33%;
}

.main-header__logo {
	display: block;
	height: 3em;
	position: relative;
	width: 6.25em;
}

@media (min-width: 375px) {
	.main-header__logo {
		width: 6.875em
	}
}

@media (max-width: 767px) {
	.main-header__logo {
		margin-right: 1.5em;
	}
}

@media (min-width: 992px) {
	.main-header__logo {
		height: 3.625em;
		width: 8.4375em;
	}
}

.main-header__logo img {
	bottom: 0;
	height: 100%;
	left: 0;
	max-width: 100%;
	//position: absolute; // Removed this so we can size it down on squashed.
	right: 0;
	top: 0;
	transition: all .45s ease;
	width: 100%;
}

.main-header__logo-img-white {
	opacity: 0
}

.main-header__left-nav {
	align-items: center;
	display: flex;
}

.main-header__left-nav-links {
	display: none;
	margin-left: 1.5em
}

@media (min-width: 992px) {
	.main-header__left-nav-links {
		display: flex;
	}
}

.main-header__left-nav-links a:hover {
	color: #E7002D
}

.main-header__left-nav-link-item {
	float: left
}

.main-header__left-nav-links a {
	color: #1F2228;
	display: block;
	font-family: fonts.$disp-medium;
	font-weight: fonts.$font-weight-bold;
	padding: 0 1.125em;
	font-size: unit.rem-calc(16, 13);
}

.main-header__left-nav-link-item--isPhone a,
.main-header__left-nav-link--isPhone a {
	align-items: center;
	display: inline-flex;
	min-height: 44px;
}

.main-header__left-nav-link-item--isPhone a img,
.main-header__left-nav-link--isPhone a img,
.main-header__left-nav-link--isPhone a svg {
	display: block;
	margin-right: 0.5em;
}

.main-header__search-bar {
	left: 6.25em;
	opacity: 0;
	position: absolute;
	right: 0;
	transition: all 0s ease;
	z-index: -1;
}

@media (min-width: 375px) {
	.main-header__search-bar {
		left: 7.1875em
	}
}

@media (min-width: 992px) {
	.main-header__search-bar {
		left: 9.375em
	}
}

.main-header__search-bar--active {
	opacity: 1;
	z-index: 9;
}

.main-header__search-input-close {
	background: url(../img/icons/close--black.svg) 50% no-repeat;
	background-size: .875em;
	border: none;
	content: "";
	cursor: pointer;
	height: 1.25em;
	position: absolute;
	right: 0;
	top: .9375em;
	width: 1.25em;
}

@media (min-width: 992px) {
	.main-header__search-input-close {
		top: 1.25em
	}
}

.main-header__search-input {
	-webkit-appearance: none;
	border: 0;
	border-radius: 0;
	font-family: fonts.$disp-bold;
	font-weight: fonts.$font-weight-bold;
	font-size: 1.5em;
	height: 2.08333em;
	letter-spacing: -.04167em;
	padding-left: .83333em;
	width: 100%;
}

@media (min-width: 992px) {
	.main-header__search-input {
		border-width: .03704em;
		font-size: 3.375em;
		height: 1.07407em;
		letter-spacing: -.01852em;
		padding-left: .37037em;
	}
}

.main-header__right-nav {
	display: flex;
	text-align: right;
}

@media (min-width: 992px) {
	.main-header__right-nav {
		padding: .0625em 1.1875em 0 0
	}
}

.main-header__right-btn {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	display: inline-block;
	height: 3.125em;
	line-height: 3.125em;
	vertical-align: middle;
	font-size: unit.rem-calc(16, 13);
	font-weight: fonts.$font-weight-bold;
}

@media (min-width: 992px) {
	.main-header__right-btn {
		float: right;
		height: 3.4375em;
		line-height: 3.4375em;
	}
}

.main-header__call-btn {
	background: url(../img/icons/phone--black.svg) 50% no-repeat;
	width: 2.1875em
}

@media (min-width: 375px) {
	.main-header__call-btn {
		width: 2.6875em
	}
}

@media (max-width: 767px) {
	.main-header__call-btn {
		display: none !important;
	}
}

@media (min-width: 992px) {
	.main-header__call-btn {
		display: none
	}
}

.main-header__search-btn {
	background: url(../img/icons/search--black.svg) 50% no-repeat;
	border: 0;
	cursor: pointer;
	margin-right: .25em;
	transition: all .45s ease;
	width: 2.1875em;
}

@media (min-width: 375px) {
	.main-header__search-btn {
		width: 2.6875em
	}
}

.main-header__search-btn:hover {
	background-image: url(../img/icons/search--red.svg);
	color: #E7002D;
}

@media (min-width: 992px) {
	.main-header__search-btn {
		background-position: 1.5em 50%;
		border-right: #E5E5E5 solid .0625em;
		padding-left: 3em;
		padding-right: 1.25em;
		width: auto;
	}
	.main-header__search-btn:before {
		content: "Search";
		display: inline-block;
		font-family: fonts.$text-medium;
		position: relative;
	}
}

.main-header__menu-toggle {
	background: url(../img/icons/hamburger--black.svg) 1.125em 50% no-repeat;
	background-size: 1.25em auto;
	border-bottom: 0;
	border-left: #E5E5E5 solid .0625em;
	border-right: 0;
	border-top: 0;
	cursor: pointer;
	padding-left: 2.5em;
	transition: background .45s ease;
	width: 5.4375em;
}

.main-header__menu-toggle:before {
	content: "Menu";
	font-family: fonts.$text-medium
}

@media (min-width: 992px) {
	.main-header__menu-toggle {
		background-position: .8125em 1.25em;
		border-bottom: 0;
		border-left: none;
		border-top: 0;
		float: left;
		margin-right: 1.4375em;
	}
	.main-header__menu-toggle:hover {
		background-image: url(../img/icons/hamburger--red.svg);
		color: #E7002D;
	}
}

.main-header__menu-toggle--active.main-header__menu-toggle {
	background-image: url(../img/icons/close--red.svg);
	background-size: .875em auto
}

.main-header__menu-toggle--active.main-header__menu-toggle:before {
	content: "Close"
}

.main-header__menu-toggle--active.main-header__menu-toggle:hover {
	background-image: url(../img/icons/close--red.svg)
}

.main-header--cim.main-header {
	border-bottom-color: #CC2F20
}

.main-header--cim.main-header:after {
	display: none
}

.main-header--cim.main-header:before {
	background-color: #DF3742;
	background-image: linear-gradient(to right, #DF3742, #D9232F);
	opacity: 1
}

.main-header--cim.main-header .main-header__left-nav-link,
.main-header--cim.main-header .main-header__right-btn {
	color: #FFFFFF
}

.main-header--cim.main-header .main-header__right-btn {
	border-color: rgba(255, 255, 255, .2)
}

.main-header--cim.main-header .main-header__logo-img-white {
	opacity: 1
}

.main-header--cim.main-header .main-header__logo-img-red {
	opacity: 0
}

.main-header--cim.main-header .main-header__menu-toggle {
	background-image: url(../img/icons/hamburger--white.svg)
}

.main-header--cim.main-header .main-header__menu-toggle--active,
.main-header--cim.main-header .main-header__menu-toggle--active:hover {
	background-image: url(../img/icons/close--white.svg)
}

.main-header--cim.main-header .main-header__search-btn {
	background-image: url(../img/icons/search--white.svg)
}

.main-header--cim.main-header .main-header__call-btn {
	background-image: url(../img/icons/phone--white.svg)
}

.main-header--adi.main-header {
	border-bottom-color: #0F3B63
}

.main-header--adi.main-header:after {
	display: none
}

.main-header--adi.main-header:before {
	background-color: #1E68AA;
	background-image: linear-gradient(to right, #1E68AA, #0F3B63);
	opacity: 1
}

.main-header--adi.main-header .main-header__left-nav-link,
.main-header--adi.main-header .main-header__right-btn {
	color: #FFFFFF
}

.main-header--adi.main-header .main-header__right-btn {
	border-color: rgba(255, 255, 255, .2)
}

.main-header--adi.main-header .main-header__logo-img-white {
	opacity: 1
}

.main-header--adi.main-header .main-header__logo-img-red {
	opacity: 0
}

.main-header--adi.main-header .main-header__menu-toggle {
	background-image: url(../img/icons/hamburger--white.svg)
}

.main-header--adi.main-header .main-header__menu-toggle--active,
.main-header--adi.main-header .main-header__menu-toggle--active:hover {
	background-image: url(../img/icons/close--white.svg)
}

.main-header--adi.main-header .main-header__search-btn {
	background-image: url(../img/icons/search--white.svg)
}

.main-header--adi.main-header .main-header__call-btn {
	background-image: url(../img/icons/phone--white.svg)
}

.main-header--skip-container__link {
	cursor: pointer;
	display: block;
	left: -50px;
	padding: 1rem;
	position: fixed;
	text-decoration: none;
	top: -50px;
	transition: none;
	font-family: fonts.$text-medium;
	font-size: unit.rem-calc(16, 13);
	
	&:focus {
		background-color: #E7002D;
		color: #FFFFFF;
		left: 0;
		top: 0;
		-ms-transform: none;
		transform: none;
		transition: none;
		width: -webkit-max-content;
		width: -moz-max-content;
		width: max-content;
		z-index: 1001;
	}
}

.main-header--grad.main-header {
	background: 0 0;
	border-bottom-color: rgba(206, 206, 206, .3)
}

.main-header--grad.main-header:after {
	background-color: #FFFFFF;
	display: none
}

.main-header--grad.main-header .main-header__left-nav-link,
.main-header--grad.main-header .main-header__right-btn {
	color: #FFFFFF
}

.main-header--grad.main-header .main-header__right-btn {
	border-color: rgba(255, 255, 255, .2)
}

.main-header--grad.main-header .main-header__logo-img-white {
	opacity: 1
}

.main-header--grad.main-header .main-header__logo-img-red {
	opacity: 0
}

.main-header--grad.main-header .main-header__menu-toggle {
	background-image: url(../img/icons/hamburger--white.svg)
}

.main-header--grad.main-header .main-header__menu-toggle--active,
.main-header--grad.main-header .main-header__menu-toggle--active:hover {
	background-image: url(../img/icons/close--white.svg)
}

.main-header--grad.main-header .main-header__search-btn {
	background-image: url(../img/icons/search--white.svg)
}

.main-header--grad.main-header .main-header__call-btn {
	background-image: url(../img/icons/phone--white.svg)
}

.main-header--grad.main-header--sub {
	border-color: #FFFFFF
}

.main-header-bg--grad {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 25rem;
	width: 100%;
	z-index: 10000;
}

@media (min-width: 992px) {
	.main-header-bg--grad {
		height: 56.25rem
	}
}

.main-header-bg--grad--sub {
	background-color: #1F2228
}

.page-header.main-header--grad {
	background: 0 0
}

.page-header.main-header--grad--degree {
	background-color: #1F2228
}

.main-header--grad__sub-text {
	color: #FFFFFF;
	max-width: 50rem;
	padding: .625rem 0 1.6875rem;
}

@media (min-width: 992px) {
	.main-header--grad__sub-text {
		padding: .75rem 0 2.8125rem
	}
}

.main-header--grad__sub-text p {
	font-family: fonts.$body;
	font-size: 1.125rem;
	line-height: 1.6875rem;
}

@media (min-width: 992px) {
	.main-header--grad__sub-text {
		font-size: 1.25rem;
		line-height: 1.875rem;
		max-width: 50rem
	}
}

.main-header--grad__wrap {
	left: 0;
	margin: 0 5.25em;
	margin-left: auto;
	margin-right: auto;
	padding: 0 1.25em;
	position: absolute;
	right: 0;
	top: 130%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

@media (min-width: 992px) {
	.main-header--grad__wrap {
		left: 0;
		margin-left: auto;
		margin-right: auto;
		max-width: 92.5em;
		padding: 4.375em 0;
		padding-left: 2em;
		padding-right: 2em;
		padding-top: 13.0625rem;
		top: 122%;
	}
}

.mega-nav {
	background: #FFFFFF;
	bottom: 0;
	display: none;
	font-family: fonts.$disp-medium;
	left: 0;
	-ms-overflow-style: none;
	padding-bottom: 6.25em;
	position: fixed;
	right: 0;
	top: 101px;
	z-index: 999;
}

@media screen and (min-width: 768px) {
	.main-header--isSquashed .main-header__logo {
		padding: .5em 0;
	}	
}

.main-header--isSquashed .mega-nav {
	top: 81px;
}

.main-header__left-nav-links a i {
	margin-right: .5em;
}

.main-header__left-nav-link--isGray a,
.main-header__left-nav-link--isRed a {
	border-radius: 3px;
	margin-left: 10px;
	padding: 12px 20px;
}

.main-header__left-nav-link--isGray a {
	border: 2px solid #C4C4C4;
}

.main-header__left-nav-link--isRed a {
	background-color: #E7002D;
	border: 2px solid #E7002D;
	color: #FFFFFF;
	transition: all .45s ease;
}

.main-header__left-nav-link--isRed a:hover {
	color: #FFFFFF;
	background-color: #B40024;
	border: 2px solid #B40024;
	transition: all .45s ease;
}

@media (min-width: 821px) {
	.mega-nav__actions {
		display: none
	}
	.main-header__logo {
		height: 3em;
		width: 8.4375em;
	}
	.main-header__left-nav-links {
		align-items: center;
		display: flex;
	}
}

@media (max-width: 839px) {
	.main-header__left-nav-links .main-header__left-nav-link-item--isPhone {
		display: none
	}
}