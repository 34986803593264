.bc-mark__red {
	color:#E7002D
}
.bc-mark__sup {
	font-size:.625rem;
	display:inline;
	height:1em;
	position:relative;
	top:-0.25em;
	vertical-align:super;
	width:1em
}
@media screen and (min-width: 23.4375em)and (max-width: 47.9375em) {
	.bc-mark__sup {
		font-size: calc(5.2290076336px + 1.272264631vw)
	}
}
@media screen and (min-width: 48em)and (max-width: 63.9375em) {
	.bc-mark__sup {
		font-size: calc(0px + 1.953125vw)
	}
}
@media screen and (min-width: 64em) {
	.bc-mark__sup {
		font-size:20px
	}
}
@media print,screen and (min-width: 48em) {
	.bc-mark__sup {
		top:-0.5em;
		margin-left:.125em
	}
}
@media print,screen and (min-width: 64em) {
	.page-header__title .bc-mark__sup {
		font-size: 30px
	}
}