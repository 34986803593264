.people__portrait-img {
    width: 100%;
    max-width: 200px;
}
.people__portrait-img.is-circle img {
    border-radius: 50%;
}
.people__portrait-img.float-end {
    padding-left: 1em;
    padding-bottom: 1em;
}