@use "settings/colors";
@use "settings/fonts";
@use "settings/numbers";
@use "settings/paths";
@use "mixins/fluid-property";
@use "zurb/breakpoint";
@use "zurb/unit";

.pagination {
	@include fluid-property.mx((margin-top, margin-bottom), numbers.$group-margin-dbl);
	display: flex;
	font-family: fonts.$disp-regular;
	justify-content: center;

	a {
		text-decoration: none;
	}

	button {
		background-color: transparent;
		border: none;
		color: currentColor;
		padding: 0;
	}

	.nav-links {
		align-items: center;
		display: flex;
		justify-content: flex-end;
	}

	.page-numbers {
		color: colors.$red-light;
		margin-left: .75em;
		margin-right: .75em;

		&:hover {
			color: colors.$red-dark;
		}
	}

	.prev,
	.next {
		align-items: center;
		display: flex;
	}

	.prev:before,
	.next:after {
		font-family: "Font Awesome 5 Pro";
		font-size: unit.rem-calc(16, 14);
	}

	.prev {

		&:before {
			content: "\f104";
			margin-right: .5em;
		}
	}

	.current {
		align-items: center;
		border-radius: 100%;
		background-color: colors.$red-light;
		display: flex;
		height: unit.rem-calc(26, 14);
		justify-content: center;
		width: unit.rem-calc(26, 14);

		&,
		&:hover {
			color: colors.$white;
		}
	}

	.dots {

		&,
		&:hover {
			color: colors.$black;
		}
	}

	.next {

		&:after {
			content: "\f105";
			margin-left: .5em;
		}
	}
}