@use "sass:map";
@use "../mixins/typographic-item";
@use "../functions/em-calc";
@use "../zurb/unit";

@mixin list($settings: ()) {
	$defaults: (
		"list-style": disc,
		"margin-left": 1rem,
		"padding-left": 1rem,
	);
	$args: map.merge($defaults, $settings);
	@include typographic-item.mx($args);
	margin-bottom: 1.5rem;
	margin-top: 0;
	line-height: 1.7;

	font-size: unit.rem-calc(16, 13);

	@media screen and (min-width:992px) {
		font-size: unit.rem-calc(14, 13);
	}

	@media screen and (min-width:1380px) {
		font-size: unit.rem-calc(16, 13);
	}

	@media screen and (min-width:1680px) {
		font-size: unit.rem-calc(18, 13);
	}

	@media screen and (min-width:2000px) {
		font-size: unit.rem-calc(20, 13);
	}
}

@mixin li($settings: ()) {
	$defaults: (
		"margin-bottom": .5rem,
		"padding-left": 0.15rem,
	);
	$args: map.merge($defaults, $settings);
	@include typographic-item.mx($args);

	&:last-child {
		margin-bottom: 0;
	}
}
