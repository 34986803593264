body.post-type-archive-career {
    .content.career__content > .hentry > .alignfull.wp-block-group {
        margin-bottom: 0;

        > .wp-block-group__inner-container {
            padding-left: 16.25px;
            padding-right: 16.25px;
        }
    }
}

body.post-type-archive-career,
body.page-id-22627,
body.single-career {
    #menu-secondary {
        display: none;
    }
}