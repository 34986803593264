@use "headings-mixins";
@use "tables-mixins";
@use "../settings/colors";
@use "../settings/fonts";
@use "../functions/em-calc";
@use "../zurb/unit";

table:not(.ui-datepicker-calendar) {
	@include tables-mixins.table();

	tr {
		@include tables-mixins.table-row();
	}

	td {
		@include tables-mixins.table-cell();
	}

	th {
		@include headings-mixins.h6();
		@include tables-mixins.table-header();
	}
}

figure.is-style-stripes {

	table {
		@include tables-mixins.table((
			"border-width": 0px,
		));
	}

	tr {

		@include tables-mixins.table-row((
			"border-width": 0,
		));

		&:nth-child(odd) td {
			background-color: #fff;
		}

		&:nth-child(even) td {
			background-color: colors.$black-haze;
		}
	}

	th {

		@include tables-mixins.table-header((
			"border-width": 0,
		));

		@include headings-mixins.h4((
			"font-size": (
				13,
				13,
				13,
				14,
				15,
			),
		));

		border-bottom: 3px solid colors.$black;
		font-family: fonts.$disp-medium;
		font-weight: 500;
		letter-spacing: em-calc.func(1, 16);
		margin-top: 3rem;
		text-transform: uppercase;
		border-left: 0 !important;
	}

	td {

		@include tables-mixins.table-cell((
			"border-width": 0px,
			"padding-top": 1rem,
			"padding-bottom": 1rem,
		));

		border-left: 0 !important;
		border-top: 0 !important;
		padding: 30px 20px !important;
		font-size: unit.rem-calc( 15.75, 13) !important;

		em {
			font-size: unit.rem-calc(12.25, 13);
			font-style: normal;
			font-family: fonts.$disp-regular;
		}
	}

}

figure.is-style-bold {

	&,
	p {
		font-family: fonts.$text-regular;
		font-size: unit.rem-calc(14);
	}

	table {
		@include tables-mixins.table((
			"border-width": 0px,
		));
	}

	tr {
		border: none;
	}

	th {
		@include tables-mixins.table-header((
			"border-width": 0,
		));

		border-bottom: 3px solid colors.$black;
		font-family: fonts.$disp-medium;
		font-weight: 500;
		font-size: unit.rem-calc(12);
	}

	td {
		@include tables-mixins.table-cell((
			"padding-top": 1rem,
			"padding-bottom": 1rem,
		));

		border: unit.rem-calc(2) solid colors.$black-haze;
		border-left: 0;
		border-right: 0;
	}
}

// Matrix table styles
