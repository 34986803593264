@use "settings/colors";
@use "settings/fonts";
@use "settings/numbers";
@use "mixins/fluid-property";

.red {
	color: colors.$red-light !important;
}

.sans {
	font-family: fonts.$text-regular !important;
}

.sans-medium {
	font-family: fonts.$text-medium !important;
}

.sans-bold {
	font-family: fonts.$text-bold !important;
}

.bd-n {
	border: none !important;
}

.mt-0 {
	margin-top: 0 !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mt-half {
	margin-top: .5em !important;
}

.mb-half {
	margin-bottom: .5em !important;
}

.pt-0 {
	padding-top: 0 !important;
}

.pb-0 {
	padding-bottom: 0 !important;
}

.mt-1 {
	@include fluid-property.mx((margin-top), numbers.$group-margin, true, true, true);
}

.mb-1 {
	@include fluid-property.mx((margin-bottom), numbers.$group-margin, true, true, true);
}

.pt-1 {
	@include fluid-property.mx((padding-top), numbers.$group-margin, true, true, true);
}

.pb-1 {
	@include fluid-property.mx((padding-bottom), numbers.$group-margin, true, true, true);
}

.mt-2 {
	@include fluid-property.mx((margin-top), numbers.$group-margin-dbl, true, true, true);
}

.mb-2 {
	@include fluid-property.mx((margin-bottom), numbers.$group-margin-dbl, true, true, true);
}

.pt-2 {
	@include fluid-property.mx((padding-top), numbers.$group-margin-dbl, true, true, true);
}

.pb-2 {
	@include fluid-property.mx((padding-bottom), numbers.$group-margin-dbl, true, true, true);
}

.p-half {
	@include fluid-property.mx((padding), numbers.$group-margin-half, true, true, true);
}

.p-1 {
	@include fluid-property.mx((padding), numbers.$group-margin, true, true, true);
}

.p-2 {
	@include fluid-property.mx((padding), numbers.$group-margin-dbl, true, true, true);
}
