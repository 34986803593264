@use "../mixins/typographic-item";
@use "../settings/colors";
@use "../settings/fonts";
@use "sass:map";

@mixin link($settings: ()) {
	$defaults: (
		"color": colors.$red-light,
		"color-hover": colors.$red-dark,
	);
	$args: map.merge($defaults, $settings);
	@include typographic-item.mx($args);
}

@mixin default-anchor() {
	@include link(); // Just a simple alias.
}

@mixin link-strong($settings: ()) {
	$defaults: (
		"color": colors.$red-light,
		"color-hover": colors.$red-dark,
	);
	$args: map.merge($defaults, $settings);
	@include typographic-item.mx($args);
	text-decoration: underline;
	text-decoration-thickness: 2px;
	text-underline-offset: 4px;
	font-weight: 600;
}

@mixin underline() {
	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}
