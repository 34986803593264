@use "../settings/colors";
@use "../typography/headings-mixins";
@use "../zurb/unit";
@use "../settings/fonts";

// fix for accordion tabs in sidebar layouts
.layout-sidebar-left .layout-sidebar-left__right-side {
	.wp-block-cnpblock-tab {
		@media screen and (min-width: 992px) {
			margin-left: max( -32.202vw, -410px );
		}

		@media screen and (min-width: 1680px) {
			margin-left: -465px;
			
		}
	}
}

.wp-block-cnpblock-tab {

}

.wp-block-cnpblock-tab-titles {
	display: flex;
	margin-bottom: unit.rem-calc(-2);
	position: relative;

	&--isOverflowing {
		border: unit.rem-calc(2) solid colors.$iron-1;
		border-bottom: none;
		overflow: scroll;

		.wp-block-cnpblock-tab-title {
			border-top: none;
		}

		.wp-block-cnpblock-tab-title:first-child {
			border-left: none;
		}
	}
}

.wp-block-cnpblock-tab-title {
	@include headings-mixins.h5();
	appearance: none;
	background-color: colors.$white;
	border: unit.rem-calc(2) solid colors.$iron-1;
	border-bottom: none;
	cursor: pointer;
	padding: unit.rem-calc(12 32);
	position: relative;
	white-space: nowrap;
	font-weight: fonts.$font-weight-bold;

	&:after { // The bottom border is done as an after element because of how different border-colors intersect.
		background-color: colors.$white;
		bottom: 0;
		content: "";
		display: block;
		height: unit.rem-calc(2);
		left: 0;
		position: absolute;
		width: 100%;
	}

	&[aria-selected="false"] {
		background-color: colors.$black-haze;
		border-color: colors.$black-haze;

		&:after {
			background-color: colors.$iron-1;
			left: unit.rem-calc(-2);
			width: calc(100% + #{unit.rem-calc(4)});
		}
	}
}

.wp-block-cnpblock-tab-content {
	border: unit.rem-calc(2) solid colors.$iron-1;
}

.wp-block-cnpblock-tab-item {
	padding: unit.rem-calc(40 32);

	&[aria-expanded="false"] {
		display: none;
	}
}