@use "../settings/fonts";
@use "../settings/colors";
@use "../typography/button-mixins";
@use "../zurb/breakpoint";
@use "../zurb/unit";

// Spacing and structure for wp-block-buttons
p + .wp-block-buttons {
	margin-top: 2em;
}
.wp-block {
	$module: ".wp-block";

	&-buttons {
		display: flex;
		flex-wrap: wrap;

		&.is-vertical {
			flex-direction: column;

			.wp-block-button:not(:last-child).is-style-text {
				margin-bottom: unit.rem-calc(35, 13);
			}
		}
		&.is-style-space-between {

			.wp-block-button .wp-block-button__link {
				display: flex;
				justify-content: space-between;
			}
		}
		&:not(.is-vertical) {
			.wp-block-button + .wp-block-button {
				margin-left: unit.rem-calc(10, 13);
			}
			
		}
	}

	// Default button style
	&-button {

		// Default state
		&__link {
			@include button-mixins.button((
				"border-color-hover": colors.$red-dark,
				"background-hover": colors.$red-dark,
				// "border-color": #B30024,
				// "background": #B30024,
				"color-hover": colors.$white,
			));

			&.has-black-haze-color {
				color: #fff !important;
			}

			&.has-red-light-background-color {
				// background-color: #B30024 !important;
			}

			svg.svg-inline--fa {
				color: colors.$black;
			}
		}
	}

	// Outline button style
	&-button.is-style-outline {

		// Default state
		& .wp-block-button__link {
			@include button-mixins.button((
				"border-color": colors.$iron-1,
				"background": transparent,
				"color": colors.$black,
				"border-color-hover": colors.$iron-1,
				"background-hover": darken(colors.$black-haze, 2%),
				"color-hover": colors.$black,
			));
		}

		.has-red-dark-btn-color {

			// Default state
			&.wp-block-button__link {
				@include button-mixins.button((
					"border-color": colors.$red-dark,
					"background": transparent,
					"color": colors.$red-dark,
					"border-color-hover": colors.$red-dark
				));

				&:hover {
					background: colors.$red-dark;
					color: #fff !important;
				}
			}
		}

		.has-red-light-btn-color {

			// Default state
			&.wp-block-button__link {
				@include button-mixins.button((
					"border-color": colors.$red-light,
					"background": transparent,
					"color": colors.$red-light,
					"border-color-hover": colors.$red-light
				));

				&:hover {
					background: colors.$red-light;
					color: #fff !important;
				}
			}
		}
	}

	// Text link button style
	&-button.is-style-text {

		// Default state
		& .wp-block-button__link {
			@include button-mixins.button((
				"border-color": transparent,
				"background": transparent,
				"color": colors.$red-light,
				"padding": 0,
				"border-color-hover": transparent,
				"background-hover": transparent,
				"color-hover": colors.$red-dark,
			));
			border: none;
			white-space: normal;

			svg.svg-inline--fa {
				color: currentColor;
			}
		}
	}

	// Small text style
	&-button.is-style-text-small {
		margin: 0 .5em .5em;

		// Default state
		& .wp-block-button__link {
			background-color: transparent;
			border: none;
			color: colors.$red-light;
			font-family: fonts.$text-regular;
			font-size: unit.rem-calc(14);
			font-weight: 400;
			padding: 0;
			text-decoration: underline;
		}

		// Hover state
		& .wp-block-button__link:hover {
			text-decoration: none;
		}
	}

	// Buttons with icon
	&-button {

		svg.svg-inline--fa {
			font-size: 1.125rem;
			margin-left: .5em;
		}
	}

	&-button__link--icon-left {

		svg.svg-inline--fa {
			order: -1;
			margin-left: 0;
			margin-right: .5em;
		}
	}

	&-button__link--icon-right {

		svg.svg-inline--fa {
			margin-left: .5em;
		}
	}

  &-button.is-style-plain {
    .wp-block-button__link {
      background: transparent;
      color: #E7002D;
      border: none;
      padding: calc(1rem - 2px) 1rem;
      font-family: fonts.$disp-regular;
      font-size: 1.125em;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
