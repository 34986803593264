@use 'settings/fonts' as fonts;
@use 'zurb/unit' as unit;

.page-template-affiliated .hentry {
    h2{
		font-size: unit.rem-calc(48, 13);    
    }
}

body.themed--cim .secondary-nav__wrap {
	background-color: #D9232F;
}

body.themed--cim .page-header {
	background-color: #DF3742;
	background-image: linear-gradient(to right, #DF3742, #D9232F)
}

@media (min-width: 992px) {
	body.themed--cim .page-header {
		min-height: 390px;
	}
}

@media (min-width: 992px) {
	body.themed--cim .page-header .page-header__pre-title {
		font-size: 1.5em;
		letter-spacing: -.02083em;
		margin-top: -1.58333em
	}
}

body.themed--cim .secondary-nav .secondary-nav__logo {
	background-image: url(../img/affiliated-institution-landing/cim-logo-secondary-nav-white.svg)
}

body.themed--cim .secondary-nav .secondary-nav__link-item--active .secondary-nav__link {
	border-bottom: solid #000000 .25em;
	color: #FFFFFF
}

@media (min-width: 992px) {
	body.themed--cim .secondary-nav .secondary-nav__link:hover {
		border-bottom-color: #000000;
		color: #FFFFFF
	}
}

@media (min-width: 992px) {
	body.themed--cim .secondary-nav .btn-cta-round:hover {
		background: #FFFFFF;
		color: #D9232F
	}
}

body.themed--cim .bread-crumbs__link-list-item:not(:first-child) a {
	color: #D9232F
}

body.themed--cim .quick-links__link {
	background-image: url(../img/icons/chevron-right-orange.svg);
	color: #D9232F;
}

@media (min-width: 992px) {
	body.themed--cim .quick-links__link:hover {
		background-image: url(../img/icons/chevron-right-orange-hover.svg);
		color: #AD1C25;
	}
}

body.themed--cim .read-more {
	background-image: url(../img/icons/chevron-right-orange.svg);
	color: #D9232F;
}

body.themed--cim .sidebar-left-nav .sidebar-left-nav__link-item--active .sidebar-left-nav__link,
body.themed--cim .sidebar-left-nav .sidebar-left-nav__link-item:hover .sidebar-left-nav__link,
body.themed--cim .sidebar-left-nav .sidebar-left-nav__mobile-toggle {
	background: #D9232F
}

body.themed--cim .career-facts__num {
	color: #D9232F
}

body.themed--cim .career-facts__cta {
	background: #D9232F;
	border-color: #D9232F
}

@media (min-width: 992px) {
	body.themed--cim .career-facts__cta {
		background: 0 0;
		color: #D9232F
	}
	body.themed--cim .career-facts__cta:hover {
		background: #D9232F;
		color: #FFFFFF
	}
}

body.themed--cim .card-slider .slick-arrow {
	background-image: url(../img/icons/chevron-left-thin-cim.svg)
}

body.themed--cim .card-slider .slick-arrow:hover {
	background-image: url(../img/icons/chevron-left-thin-cim-hover.svg)
}

body.themed--cim .card-slider .slick-next {
	background-image: url(../img/icons/chevron-right-thin-cim.svg);
	right: 0;
}

body.themed--cim .card-slider .slick-next:hover {
	background-image: url(../img/icons/chevron-right-thin-cim-hover.svg)
}

body.themed--cim .card-event__day,
body.themed--cim .card-event__month {
	color: #D9232F
}

body.themed--cim .half-image-quote__quote-container {
	background: #D9232F
}

body.themed--cim .callout-contact .callout-contact__intro {
	color: #D9232F
}

body.themed--cim .callout-contact .callout-contact__form-submit {
	background: #D9232F;
	border-color: #D9232F
}

@media (min-width: 992px) {
	body.themed--cim .callout-contact .callout-contact__form-submit {
		background: 0 0;
		color: #D9232F;
	}
	body.themed--cim .callout-contact .callout-contact__form-submit:hover {
		background: #D9232F;
		color: #FFFFFF;
	}
}

body.themed--cim .campus-grid__name {
	color: #D9232F
}

body.themed--cim .btn-cta-round-cim {
	background: 0;
	border-color: #D9232F;
	color: #D9232F;
}

body.themed--cim .btn-cta-round-cim:hover {
	background: #D9232F;
	border-color: #D9232F;
	color: #FFFFFF;
}

body.themed--cim .select2-dropdown .select2-results__option--highlighted[aria-selected] {
	background: #D9232F;
	border-color: #D9232F
}

body.themed--cim .js-select2 + .select2-container .select2-selection__arrow {
	background: url(../img/icons/chevron-down-orange.svg) 50% 50% no-repeat
}

body.themed--cim .ui-datepicker .ui-datepicker-prev .ui-icon {
	background: url(../img/icons/chevron-left-thin-cim.svg) 50% no-repeat
}

body.themed--cim .ui-datepicker .ui-datepicker-next .ui-icon {
	background: url(../img/icons/chevron-right-thin-cim.svg) 100% 50% no-repeat
}

body.themed--cim .ui-datepicker tbody a.ui-state-active,
body.themed--cim .ui-datepicker tbody a.ui-state-highlight,
body.themed--cim .ui-datepicker tbody a.ui-state-hover {
	color: #D9232F
}

body.themed--adi .secondary-nav__wrap {
	background-color: #1E68AA;
}

body.themed--adi .is-style-quick-links .wp-block-button__link {
	color: #1E68AA;
}

body.themed--adi .is-style-quick-links .wp-block-button__link:hover {
	color: #164D7F;
}

body.themed--adi .page-header {
	background-color: #1E68AA;
	background-image: linear-gradient(to right, #1E68AA, #0F3B63)
}

@media (min-width: 992px) {
	body.themed--adi .page-header {
		min-height: 390px;
	}
}

@media (min-width: 992px) {
	body.themed--adi .page-header .page-header__pre-title {
		font-size: 1.5em;
		letter-spacing: -.02083em;
		margin-top: -1.58333em
	}
}

body.themed--adi .secondary-nav .secondary-nav__logo {
	background-image: url(../img/affiliated-institution-landing/adi-logo-secondary-nav-white.svg)
}

body.themed--adi .secondary-nav .secondary-nav__link-item--active .secondary-nav__link {
	border-bottom: solid #000000 .25em;
	color: #FFFFFF
}

@media (min-width: 992px) {
	body.themed--adi .secondary-nav .secondary-nav__link:hover {
		border-bottom-color: #000000;
		color: #FFFFFF
	}
}

@media (min-width: 992px) {
	body.themed--adi .secondary-nav .btn-cta-round:hover {
		background: #FFFFFF;
		color: #1E68AA;
	}
}

body.themed--adi .bread-crumbs__link-list-item:not(:first-child) a {
	color: #1E68AA
}

body.themed--adi .quick-links__link {
	background-image: url(../img/icons/chevron-right-adi.svg);
	color: #1E68AA;
}

body.themed--adi .sidebar__widget-link--directions a {
	background-image: url(../img/icon-directions-blue.svg);
	color: #1E68AA;
}

@media (min-width: 992px) {
	body.themed--adi .quick-links__link:hover {
		background-image: url(../img/icons/chevron-right-adi-hover.svg);
		color: #164D7F;
	}
	body.themed--adi .sidebar__widget-link--directions a:hover {
		background-image: url(../img/icon-directions-blue-hover.svg);
		color: #164D7F;
	}
}

body.themed--adi .read-more {
	background-image: url(../img/icons/chevron-right-adi.svg);
	color: #1E68AA;
}

body.themed--adi .sidebar-left-nav .sidebar-left-nav__link-item--active .sidebar-left-nav__link,
body.themed--adi .sidebar-left-nav .sidebar-left-nav__link-item:hover .sidebar-left-nav__link,
body.themed--adi .sidebar-left-nav .sidebar-left-nav__mobile-toggle {
	background: #1E68AA
}

body.themed--adi .career-facts__num {
	color: #1E68AA
}

body.themed--adi .career-facts__cta {
	background: #1E68AA;
	border-color: #1E68AA
}

@media (min-width: 992px) {
	body.themed--adi .career-facts__cta {
		background: 0 0;
		color: #1E68AA
	}
	body.themed--adi .career-facts__cta:hover {
		background: #1E68AA;
		color: #FFFFFF
	}
}

body.themed--adi .card-slider .slick-arrow {
	background-image: url(../img/icons/chevron-left-thin-adi.svg)
}

body.themed--adi .card-slider .slick-arrow:hover {
	background-image: url(../img/icons/chevron-left-thin-adi-hover.svg)
}

body.themed--adi .card-slider .slick-next {
	background-image: url(../img/icons/chevron-right-thin-adi.svg);
	right: 0;
}

body.themed--adi .card-slider .slick-next:hover {
	background-image: url(../img/icons/chevron-right-thin-adi-hover.svg)
}

body.themed--adi .card-event__day,
body.themed--adi .card-event__month {
	color: #1E68AA
}

body.themed--adi .half-image-quote__quote-container {
	background: #1E68AA
}

body.themed--adi .callout-contact .callout-contact__intro {
	color: #1E68AA
}

body.themed--adi .callout-contact .callout-contact__form-submit {
	background: #1E68AA;
	border-color: #1E68AA
}

@media (min-width: 992px) {
	body.themed--adi .callout-contact .callout-contact__form-submit {
		background: 0 0;
		color: #1E68AA;
	}
	body.themed--adi .callout-contact .callout-contact__form-submit:hover {
		background: #1E68AA;
		color: #FFFFFF;
	}
}

body.themed--adi .campus-grid__name {
	color: #1E68AA
}

body.themed--adi .btn-cta-round_adi {
	background: 0;
	border-color: #1E68AA;
	color: #1E68AA;
}

body.themed--adi .secondary-nav__cta .btn-cta-round {
    border-color: #fff;
    color: #fff;
}

body.themed--adi .btn-cta-round_adi:hover {
	background: #1E68AA;
	border-color: #1E68AA;
	color: #FFFFFF;
}

body.themed--adi .select2-dropdown .select2-results__option--highlighted[aria-selected] {
	background: #1E68AA;
	border-color: #1E68AA
}

body.themed--adi .js-select2 + .select2-container .select2-selection__arrow {
	background: url(../img/icons/chevron-down-blue.svg) 50% 50% no-repeat
}

body.themed--adi .ui-datepicker .ui-datepicker-prev .ui-icon {
	background: url(../img/icons/chevron-left-thin-adi.svg) 50% no-repeat
}

body.themed--adi .ui-datepicker .ui-datepicker-next .ui-icon {
	background: url(../img/icons/chevron-right-thin-adi.svg) 100% 50% no-repeat
}

body.themed--adi .ui-datepicker tbody a.ui-state-active,
body.themed--adi .ui-datepicker tbody a.ui-state-highlight,
body.themed--adi .ui-datepicker tbody a.ui-state-hover {
	color: #1E68AA
}

body.themed--grad .secondary-nav {
	position: relative
}

body.themed--grad .secondary-nav .secondary-nav__link-item--active .secondary-nav__link {
	border-bottom: solid #E7002D .25em;
	color: #E7002D
}

@media (min-width: 992px) {
	body.themed--grad .secondary-nav .secondary-nav__link:hover {
		border-bottom-color: #E7002D;
		color: #E7002D
	}
}

body.themed--grad .btn-cta-round {
	color: #E7002D
}

body.themed--grad .btn-cta-round:hover {
	color: #FFFFFF
}

@media (max-width: 991px) {
	body.themed--grad .btn-cta-round {
		background: #FFFFFF;
		color: #E7002D
	}
	body.themed--grad .btn-cta-round:hover {
		background: #E7002D;
		color: #FFFFFF;
	}
}

body.themed--grad .secondary-nav__link-list--main {
	margin-left: 12.625em
}

body.themed--adi .degree-section__cta,
body.themed--adi .degree-section__curriculum-courses-title {
    color: #1E68AA;
}
body.themed--adi .degree-section__cta:hover {
    color: #003577;
}

/*
body.themed--adi .btn-cta-round {
    background: #1E68AA;
    border-color: #1E68AA;
}
@media screen and (min-width: 992px) {
    body.themed--adi .btn-cta-round {
        color: #1E68AA;
        background: 0 0;
    }
    body.themed--adi .btn-cta-round:hover {
        color: #FFF;
        background: #1E68AA;
    }
}

body.themed--cim .btn-cta-round {
    background: #D9232F;
    border-color: #D9232F;
}
@media screen and (min-width: 992px) {
    body.themed--cim .btn-cta-round {
        color: #D9232F;
        background: 0 0;
    }
    body.themed--cim .btn-cta-round:hover {
        color: #FFF;
        background: #D9232F;
    }
} */

body.themed--cim .degree-section__cta,
body.themed--cim .degree-section__curriculum-courses-title {
    color: #D9232F;
}
body.themed--cim .degree-section__cta:hover {
    color: #A60000;
}

body.themed--adi .share__networks-bg {
    border-color: #1E68AA;
}
body.themed--adi .share__button-text {
    color: #1E68AA;
}
body.themed--adi .share__networks .share-close {
    border-color: #1E68AA;
    background-color: #1E68AA;
}

body.themed--cim .share__networks-bg {
    border-color: #D9232F;
}
body.themed--cim .share__button-text {
    color: #D9232F;
}
body.themed--cim .share__networks .share-close {
    border-color: #D9232F;
    background-color: #D9232F;
}

body.themed--adi .pagenav__link {
    color: #1E68AA;
}

body.themed--cim .pagenav__link {
    color: #D9232F;
}

body.themed--adi .pagenav__link:hover {
    border-bottom-color: #1E68AA;
}

body.themed--cim .pagenav__link:hover {
    border-bottom-color: #D9232F;
}

body.themed--adi .degree-section__at-a-glance-title {
    color: #1E68AA;
}

body.themed--cim .degree-section__at-a-glance-title {
    color: #D9232F;
}
body.themed--adi .degree-section__at-a-glance-stat {
    color: #1E68AA;
}

body.themed--cim .degree-section__at-a-glance-stat {
    color: #D9232F;
}

body.themed--adi a:not([class],.footer-section a,.main-header a) {
    color: #1E68AA;
}
body.themed--adi a:not([class],.footer-section a,.main-header a):hover {
    color: #003577;
}

body.themed--cim a:not([class],.footer-section a,.main-header a) {
    color: #D9232F;
}
body.themed--cim a:not([class],.footer-section a,.main-header a):hover {
    color: #A60000;
}

/* body.themed--adi .secondary-nav__link-list .secondary-nav__link,
body.themed--cim .secondary-nav__link-list .secondary-nav__link {
    color: #FFF;
} */

body.themed--adi .campus-popup__content a {
    color: #1E68AA;
}

body.themed--cim .campus-popup__content a {
    color: #D9232F;
}

body.themed--adi .campus-popup__content a:hover,
body.themed--cim .campus-popup__content a:hover {
    text-decoration: underline;
}

body.themed--adi .callout-contact__form .primaryAction {
	background-color: transparent;
    border-color: #1E68AA;
    color: #1E68AA;
}

body.themed--adi .btn-cta-round {
	background-color: transparent;
	border-color: #1E68AA;
	color: #1E68AA;
}

body.themed--adi .btn-cta-round:hover {
	background-color: #1E68AA;
	color: #FFFFFF;
}

body.themed--cim .callout-contact__form .primaryAction {
    border-color: #D9232F;
    color: #D9232F;
}

body.themed--adi .callout-contact__form .primaryAction:hover {
    background-color: #1E68AA;
    border-color: #1E68AA;
    color: #ffffff;
}

body.themed--cim .callout-contact__form .primaryAction:hover {
    background-color: #D9232F;
    border-color: #D9232F;
    color: #ffffff;
}

body.themed--adi .share__button-text {
	color: #1E68AA;
}

body.themed--adi .share__networks .share-close {
	background-color: #1E68AA;
	border-color: #1E68AA;
}

body.themed--adi .share__networks-bg {
	border-color: #1E68AA;
}

/* Other blocks */
body .news-events-grid {
  grid-column: 1/-1;
  padding-bottom: 0 !important;

	.news-events-grid__news-card {
		display: block; // Fix focus issue in Chrome.
	}

	.news-events-grid__item {
        margin-bottom: 1.875em;
        position: relative;
        padding: 0 .46em;

        &:first-child a {
            height: 100%;

            .news-events-grid__news-card-photo {
                height: 100%;

                img {
                    object-fit: cover;
                    height: 100%;
                }
            }
        }

        &:nth-child(2), &:nth-child(4), &:nth-child(6) {
            &:after {
                content: '';
                position: absolute;
                right: 0.75rem;
                bottom: 0;
                left: 0.75rem;
                background: #dfe2e3;
                display: block;
                height: 1px;
            }
        }

        &:nth-child(3) {
            background: #f7fafa;

            .news-events-grid__news-card {
                display: flex;
                flex-direction: column;

                .news-events-grid__news-card-photo {
                    min-height: 220px;
                    margin-bottom: 10px;
                }

                .news-events-grid__event-card-date {
                    padding: 1.25rem 0.813rem;
                    display: flex;
                    order: 3;
                }

                .news-events-card-title {
                    padding-top: 1.875rem;
                    margin-bottom: 0
                }
            }
        }

    .news-events-grid__news-card--full {
      display: block;
      position: relative;
    }
  }

  .l-flex-half {
    position: relative;

    .news-events-grid__news-card--full {
      display: block;
      position: relative;

      &:before {
        content: '';
        background: #000;
        opacity: .4;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      .news-events-grid__news-card-text-wrap {
        position: absolute;
        bottom: 2em;
        width: 100%;
        max-width: 38.125em;
        padding: 0 1.75em;
        color: #fff;
      }

      .news-events-grid__news-card-title {
        color: #fff;
        margin: 0;
      }
    }

    &:nth-child(5) {
      .news-events-grid__news-card {
        background: #E7002D;
        padding: 2.375em 1.75em 4.25em;
        display: block;
        height: 100%;
      }

      .news-events-grid__event-card-month {
        font-size: 1.5em;
        padding-bottom: 1.125em;
        letter-spacing: -.00208em;
        font-family: fonts.$disp-bold;
        font-weight: fonts.$font-weight-bold;
        color: #fff;
      }

      .news-events-grid__event-card-day {
        font-size: 5em;
        line-height: 1.2em;
        //letter-spacing: -.01875em;
        font-family: fonts.$sans-fancy;
        font-weight: fonts.$font-weight-black;
        color: #fff;
      }

      .news-events-grid__event-card-title {
        font-size: 2.25em;
        line-height: 1.33333em;
        color: #fff;
      }

      .news-events-grid__news-card-text-wrap {
        margin-top: 7.5rem;
      }

      .news-events-grid__event-card-location {
          color: #fff;
      }

    }
  }
}

.news-events-grid__news-card-photo {
  img {
    width: 100%;
  }
}


.text-image {
    margin: 0 auto
}

.text-image__header {
    margin-bottom: 1.875rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 1110px;
    padding-left: 20px;
    padding-right: 20px
}

.text-image__header+.text-image__inner .text-image__text {
    margin-top: 0
}

.text-image__headline {
    font-size: 1.5rem;
    letter-spacing: -0.5px;
    margin-bottom: .375em
}

.text-image__intro {
    font-family:fonts.$body;
    font-size: 18px;
    line-height: 1.6
}

.text-image__inner {
    margin: 0 auto;
    max-width: 1440px;
    padding: 0 30px
}

.text-image__text,.text-image__image {
    margin: 40px 0
}

.text-image__text {
    font-family: fonts.$body;
    line-height: 1.7
}

.text-image__subtitle {
    color: #E7002D;
    font-family: fonts.$disp-medium;
    font-size: 14px;
    letter-spacing: .05em;
    line-height: 1.7
}

.text-image__title,.text-image h2:first-child,.text-image h1:first-child {
    font-size: 1.625rem;
    color: #1f2228;
    font-family: fonts.$disp-bold;
    font-weight: fonts.$font-weight-bold;
    letter-spacing: -0.5px;
    line-height: 1.1;
    margin-bottom: 20px
}

.text-image__button {
    background-color: #E7002D;
    border: 2px solid #E7002D;
    border-radius: 100px;
    color: #fff;
    display: inline-flex;
    font-family: fonts.$disp-medium;
    line-height: 1.1875;
    padding: 13px 32px;
    text-decoration: none;
    transition: all .3s ease
}

.text-image__button:hover {
    background-color: #fff;
    color: #1f2228
}

.text-image__image img {
    display: block;
    width: 100%
}

.text-image p,.text-image ul,.text-image ol {
    margin-top: 0
}

.text-image p:not(:last-child),.text-image ul:not(:last-child),.text-image ol:not(:last-child) {
    margin-bottom: 1em
}

.text-image ul {
    list-style: disc;
    margin-left: 1em
}

.text-image ol {
    list-style: decimal;
    margin-left: 1em
}

.text-image li {
    line-height: 1.5
}

.text-image li:not(:last-child) {
    margin-bottom: 20px
}

.text-image h3 {
    font-size: 21.5px;
    letter-spacing: -0.5px
}

.text-image a:not(.text-image__button) {
    text-decoration: underline
}

.text-image a:not(.text-image__button):hover {
    text-decoration: none
}

.text-image--no-background.text-image {
    border: none
}

.text-image--ghost {
    margin: 0;
    padding: 70px 20px 40px
}

.text-image--ghost .text-image__inner {
    background: #fff;
    border-top: 4px solid #202227;
    box-shadow: 0 10px 30px rgba(0,0,0,.15);
    max-width: 999px;
    padding: 0
}

.text-image--ghost .text-image__text,.text-image--ghost .text-image__image {
    margin: unset
}

.text-image--ghost .text-image__text {
    padding: 30px
}

@media screen and (min-width: 1024px) {
    .text-image {
        background:linear-gradient(90deg, #FFFFFF 60%, #F5F7F7 0);
        border-bottom: 1px solid #e5e5e5;
        border-top: 1px solid #e5e5e5
    }

    .text-image__inner {
        -moz-column-gap: 5%;
        column-gap: 5%;
        display: grid;
        grid-template-columns: 1fr 50%;
        padding: 0 5%
    }

    .text-image__text,.text-image__image {
        margin: 90px 0
    }

    .text-image__text {
        display: flex;
        flex-direction: column;
        justify-content: center
    }

    .text-image__image {
        align-items: center;
        display: flex
    }

    .text-image--text-right {
        background: linear-gradient(90deg, #F5F7F7 40%, #FFFFFF 0)
    }

    .text-image--text-right .text-image__inner {
        grid-template-columns: 50% 1fr
    }

    .text-image--text-right .text-image__text {
        order: 2
    }

    .text-image--text-right .text-image__image {
        order: 1
    }

    .text-image--no-background {
        background: none
    }

    .text-image--ghost {
        background: unset;
        border-bottom: unset;
        border-top: unset;
        padding-bottom: 80px;
        padding-top: 80px
    }

    .text-image--ghost .text-image__inner {
        -moz-column-gap: unset;
        column-gap: unset
    }

    .text-image--ghost .text-image__text {
        order: 2;
        padding: 50px
    }

    .text-image--ghost .text-image__image {
        order: 1
    }

    .text-image--ghost .text-image__image img {
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover
    }
}

@media screen and (min-width: 23.4375em)and (max-width: 47.9375em) {
    .text-image__header {
        margin-bottom:calc(1.3740458015px + 7.6335877863vw)
    }

    .text-image__headline {
        font-size: calc(1.0992366412px + 6.106870229vw)
    }

    .text-image__title,.text-image h2:first-child, .text-image h1:first-child {
        font-size: calc(16.4580152672px + 2.5445292621vw)
    }
}

@media screen and (min-width: 48em) {
    .text-image__header {
        margin-bottom:60px
    }

    .text-image__headline {
        font-size: 48px
    }
}

@media print,screen and (min-width: 48em) {
    .text-image__header {
        text-align:center
    }

    .text-image__header+.text-image__inner .text-image__image {
        margin-top: 0
    }
}

@media screen and (min-width: 1440px) {
    .text-image__inner {
        -moz-column-gap:7%;
        column-gap: 7%;
        grid-template-columns: 1fr 53%;
        padding: 0 4.75%
    }

    .text-image--text-right__inner {
        grid-template-columns: 53% 1fr
    }
}

@media screen and (min-width: 48em)and (max-width: 63.9375em) {
    .text-image__title,.text-image h2:first-child,.text-image h1:first-child {
        font-size:calc(3px + 4.296875vw)
    }
}

@media screen and (min-width: 64em)and (max-width: 74.9375em) {
    .text-image__title,.text-image h2:first-child,.text-image h1:first-child {
        font-size:calc(29.5454545455px + 1.7045454545vw)
    }
}

@media screen and (min-width: 75em) {
    .text-image__title,.text-image h2:first-child,.text-image h1:first-child {
        font-size:50px
    }
}

@media print,screen and (min-width: 64em) {
    .text-image p,.text-image ul,.text-image ol {
        font-size:1.125em
    }
}

@media screen and (max-width: 767px) {
    .text-image--image-first-on-mobile .text-image__inner {
        display:flex;
        flex-direction: column
    }

    .text-image--image-first-on-mobile .text-image__image {
        margin-bottom: 0;
        order: -1
    }
}
