@use "settings/colors";
@use "settings/fonts";
@use "zurb/unit";

.bread-crumbs {
	display: none;
	font-family: fonts.$text-medium;
	font-weight: fonts.$font-weight-bold;
	padding: unit.rem-calc( 37, 13 ) 0 unit.rem-calc(40, 13);

	@media (min-width: 992px) {
		display: block;
	}

	&.has-color {
        background-color: colors.$black-haze;
		padding-bottom: 0;
		
		.bread-crumbs__link, .bread-crumbs__no-link {
			color: colors.$red-dark;
		}
	}

	&__link-list {
		list-style: none;

		&-item {
			display: inline-block;
			
			&::after {
				content: ">";
				display: inline-block;
				font-size: .625em;
				margin: 0 .45em;
			}

			&:last-child:after {
				display: none
			}

			&:first-child,
			&:first-child .bread-crumbs__no-link,
			&:first-child a {
				color: #1F2228
			}
		}
	}
	
	svg {
		color: #999;
		font-size: .875em;
		margin: 0 .5em;
	}

	&__link,
	&__no-link {
		color: #E7002D;
		font-family: fonts.$text-medium;
		font-size: unit.rem-calc(13, 13);
		letter-spacing: .05em;
		text-decoration: none;

		@media screen and (min-width: 992px) {
			font-size: calc(0.925 * 13px);
			line-height: 1.03704em;
			max-width: 60%;
			padding-bottom: 0;
		}

		@media screen and (min-width:1380px) {
			font-size: calc(0.925 * 14px);
		}

		@media screen and (min-width:1680px) {
			font-size: calc(0.925 * 16px);
		}

		@media screen and (min-width:2000px) {
			font-size: calc(0.925 * 18px);
		}
	}

	&__link:hover {
		text-decoration: underline
	}
}

.bread-crumbs + .content > .layout-sidebar-left,
.bread-crumbs + .content > .layout-sidebar-right{
	@media (min-width: 992px) {
		padding-top: 0
	}
}

.bread-crumbs + .content > .page__loop > .post > .wp-block-group:first-child,
.bread-crumbs + .content > .page__loop > .post > .wp-block-columns:first-child {
	@media (min-width: 992px) {
		margin-top: 0
	}
}

.single-career, .archive-career {
	.bread-crumbs {
		padding-top: 0;
	}	
}