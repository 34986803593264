@use "sass:map";
@use "../settings/breakpoints";
@use "../zurb/breakpoint";
@use "../zurb/unit";

.wp-block-columns {
	$prefix: ".wp-block-columns";
	display: grid !important;
	grid-auto-columns: 1fr;
	grid-auto-flow: column;
	grid-column-gap: unit.rem-calc(30,13);
	grid-row-gap: unit.rem-calc(30,13);
	margin-bottom: 0;
    align-items: unset !important;

	@include breakpoint.mx(small only) {
		grid-auto-flow: row;

		&.has-background {
			grid-row-gap: 0;

			ul:first-child:last-child {
				margin-bottom: 0.5rem;
			}
		}
	}

	&.mw-1200{
		grid-column-gap: unit.rem-calc(50, 13) !important;

		p {
			font-family: sans-serif;
			font-size: unit.rem-calc(16, 13);
		}
	}

	&.is-style-large-gap {
		grid-column-gap: unit.rem-calc(70, 13);
	}

	&[class*=is-style] { // Any columns block that has an applied columns style can use auto-flow row, because the columns template is specified.
		grid-auto-flow: row;
	}

	// Two column styles
	&.is-style-60-40 {

		@include breakpoint.mx(medium) {
			grid-template-columns: 6fr 4fr;
		}
	}

	&.is-style-40-60 {

		@include breakpoint.mx(medium) {
			grid-template-columns: 4fr 6fr;
		}
	}

	&.is-style-70-30 {

		@include breakpoint.mx(medium) {
			grid-template-columns: 7fr 3fr;
		}
	}

	&.is-style-30-70 {

		@include breakpoint.mx(medium) {
			grid-template-columns: 3fr 7fr;
		}
	}

	&.is-style-50-50 {

		@include breakpoint.mx(medium) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
	}

	// Three column split
	&.is-style-3 {
		@include breakpoint.mx(medium) {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}
	}

	// Four column split
	&.is-style-4 {
		@include breakpoint.mx(medium) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
		@include breakpoint.mx(large) {
			grid-template-columns: repeat(4, minmax(0, 1fr));
		}
	}

	// Add more column styles as needed.

	.wp-block-column {

	}
}


.interior .page__loop {

	.wp-block-columns:not(.glide__slides) {
		margin-left: auto;
		margin-right: auto;
		padding: 0 1.25em;
		max-width: unit.rem-calc(1150, 13);
		grid-column: 1/-1;

		@media screen and (min-width: 1380px) {
			max-width: unit.rem-calc( 1240px, 13 );
		}

		@media screen and (min-width: 1680px) {
			max-width: unit.rem-calc( 1416px, 13 );
		}
	}
}