@use 'settings/fonts' as fonts;

.post-type-archive-academic-calendar {
	.navigation { 
		display: none;
	}

	.events__loop {
	    display: none;
		margin-bottom: 3.5em;
	}
	
	.events__loop:first-child {
	    display: block;
	}

	.events__loop-header {
		border-bottom: 1px solid #979797;
		padding-bottom: .5em;
		margin-bottom: 2.25em;
	}

	.events__loop .events__loop-subtitle {
		color: #E7002D;
		font-size: 16px;
		margin: 0;
	}

	.events__loop .events__loop-title {
		font-size: 36px;
		margin: 0 0 .125em;
	}

	.event__date-start, .event__date-end {
		background-color: #EDEEEF;
		width: 68px;
		height: 56px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
	}

	.event {
		display: flex;
		margin-bottom: 2.5em;
		font-size: 16px;
		align-items: center;
	}
	
    .event.event--isFeatured .event__date-start, .event.event--isFeatured .event__date-end {
      background-color: #E7002D;
      color: #fff;
    }
    .event.event--isFeatured .event__text {
      color: #E7002D;
    }

	.event__date-day {
		font-family: fonts.$sans-fancy;
		font-weight: fonts.$font-weight-black;
		font-size: 28px;
		line-height: 1;
	}

	.event__date-month {
		margin-bottom: .375em;
		//letter-spacing: -1.18px;
		margin-top: .25em;
		font-weight: fonts.$font-weight-bold;
	}

	.event__date {
		display: flex;
		min-width: 170px;
	}

	.event__text {
		font-family: fonts.$body;
		line-height: 1.4;
		font-size: 17.5px;
	}

	.event__title {
		font-weight: bold;
	}

	.event__date-start + .event__date-end:after {
		content: '-';
		display: flex;
		position: absolute;
		left: -.125em;
		top: 40%;
		font-weight: bold;
		font-size: 28px;
		font-family: fonts.$sans-fancy;
	}

	.event__date.event__date--hasEnd .event__date-start, .event__date.event__date--hasEnd .event__date-end {
		width: 59px;
	}

	.event__filters-title {
		font-size: 16px;
		font-family: fonts.$disp-medium;
		background-color: #E7002D;
		color: #fff;
		padding: 0.5em 1.75em;
	}

	.event__filters {
		margin-top: 4em;
	}

	.event__filters-subtitle {
		margin: 1.5em 1.75em;
		font-size: 16px;
	}

	#event__filter-term {
		font-size: 16px;
		font-family: fonts.$disp-medium;
		background-color: #DEE1E2;
		border: none;
		box-shadow: none;
		padding: 0.5em 2em .5em 1em;
		margin-left: 1.75em;
		appearance: none;
		background-repeat: no-repeat;
		background-position: 90% 55%;
		background-image: url("data:image/svg+xml,%3Csvg width='13px' height='13px' viewBox='0 0 13 13' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='rotate(90 6 6)'%3E%3Cg transform='translate(-102.000000, -6.000000)' fill='%23000'%3E%3Cg%3E%3Cpath d='M112.054,11.988 C111.994,12.1080006 111.964,12.1919998 111.964,12.24 L111.874,12.402 C111.874,12.4140001 111.868,12.42 111.856,12.42 L106.672,17.676 C106.455999,17.8920011 106.192002,18 105.88,18 C105.567998,18 105.292001,17.8920011 105.052,17.676 C104.823999,17.4479989 104.71,17.1810015 104.71,16.875 C104.71,16.5689985 104.823999,16.2960012 105.052,16.056 L109.318,11.754 L105.052,7.452 C104.823999,7.22399886 104.71,6.95400156 104.71,6.642 C104.71,6.32999844 104.820999,6.06300111 105.043,5.841 C105.265001,5.61899889 105.534998,5.508 105.853,5.508 C106.171002,5.508 106.443999,5.61599892 106.672,5.832 L111.856,11.088 C111.88,11.1360002 111.901,11.1749998 111.919,11.205 C111.937,11.2350001 111.949,11.2589999 111.955,11.277 C111.961,11.2950001 111.967,11.304 111.973,11.304 C111.979,11.304 111.982,11.3099999 111.982,11.322 L112.054,11.538 L112.054,11.664 C112.066,11.7120002 112.072,11.7419999 112.072,11.754 L112.054,11.844 L112.054,11.988 Z' id='&gt;'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
	}

	@media (max-width: 991px) {
		.event__filters {
			margin-top: 2em;
		}

		.events__loop .events__loop-title {
			font-size: 24px;
		}

		.event {
			align-items: flex-start;
			flex-direction: column;
			margin-bottom: 1em;
		}

		.event__date-start + .event__date-end::after {
			top: 20%;
		}

		.event__date-start, .event__date-end {
			flex-direction: row;
			align-items: baseline;
			width: auto;
			height: auto;
			line-height: 1;
			padding: .25em 1em 0;
			margin-bottom: .25em;
		}

		.event__date-month, .event__date-day {
			font-size: 18px;
		}

		.event__date-day {
			margin-left: .25em;
			position: relative;
			top: 1px;
		}
	}
	@media (min-width: 992px) {
		.event__filters-title,
		.event__filters-subtitle,
		#event__filter-term {
			font-size: 13px;
		}
	}
	@media (min-width: 1380px) {
		.event__filters-title,
		.event__filters-subtitle,
		#event__filter-term {
			font-size: 14px;
		}
	}
	@media (min-width: 1680px) {
		.event__filters-title,
		.event__filters-subtitle,
		#event__filter-term {
			font-size: 16px;
		}
	}
	@media (min-width: 2000px) {
		.event__filters-title,
		.event__filters-subtitle,
		#event__filter-term {
			font-size: 18px;
		}
	}
}