
@use "../settings/numbers";
@use "../zurb/breakpoint";

.wp-block-cnpblock-xr {
    display: flex;
    grid-column: 1/-1 !important;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding-left: 35px;
    padding-right: 35px;

	@include breakpoint.mx(numbers.$default-breakpoint up) {
        grid-column: 2/-2 !important;
        flex-direction: row;
        align-items: flex-start;
        padding-left: 0;
        padding-right: 0;
    }

    &__icon {
        min-width: 160px;
        text-align: center;
        margin-bottom: 30px;
        
        @include breakpoint.mx(numbers.$default-breakpoint up) {
            margin-right: 60px;
            margin-bottom: 0;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &__title {
        @include breakpoint.mx(numbers.$default-breakpoint up) {
            max-width: 500px;
        }
    }
}

.page-template-degree .wp-block-cnpblock-xr {
    padding-bottom: 80px;
    padding-top: 80px;
    max-width: 840px;
    margin-left: auto;
    margin-right: auto;
}