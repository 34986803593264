@use "../settings/colors";
@use "../settings/fonts";
@use "../settings/numbers";
@use "../functions/clamps";
@use "../functions/em-calc";
@use "../mixins/fluid-property";
@use "../mixins/absolute-fit";
@use "../mixins/object-fit";
@use "../zurb/breakpoint";

.wp-block-cover {
	$prefix: ".wp-block-cover";
	position: relative;

	// The main background image.
	&__image-background {
		@include absolute-fit.mx();
		@include object-fit.mx();
		z-index: 0;
	}

	&__inner-container {

		> * {
			position: relative; // Lay on top of the background image/overlay
		}
		> *:last-child {
			margin-bottom: 0;
		}
	}

	&.has-background-dim #{$prefix}__inner-container:before,
	>span.has-background-dim {
		@include absolute-fit.mx();
		content: "";
		display: block;
		opacity: .5;
		z-index: 1;
		pointer-events: none;
	}

	@each $name, $color in colors.$palette {
		&.has-#{$name}-background-color #{$prefix}__inner-container:before {
			background-color: $color !important;
		}
	}

	&.has-custom-content-position #{$prefix}__inner-container {
		// TODO: prove a use case for custom content position before implementing.
	}

	&.is-style-academic-calendar {
		border-bottom: 1px solid #C4C4C4;
		border-top: 1px solid #C4C4C4;
		padding: clamps.func(40, 100, large) 0;

		h2.has-black-color {
			font-size: em-calc.func(42, 13);
			margin-bottom: 3px;
		}

		h3.has-black-color {
			font-size: em-calc.func(20, 13);
			margin-top: 0;
			font-family: fonts.$disp-bold;
			font-weight: normal;
		}

		+ .is-style-gray-half-background-left {
			@include breakpoint.mx(small only) {
				margin-top: 3em !important;
			}
		}
	}

	&.is-style-cta {
		padding-top: em-calc.func(150, 13);
		padding-bottom: em-calc.func(150, 13);
		color: colors.$white;
		padding-left: 20px;
		padding-right: 20px;

		* {
			color: currentColor;
		}

		.wp-block-cover__inner-container {
			position: static;

			> * {
				position: relative;
				z-index: 1;
			}
		}

		h2.has-text-align-center {
			font-family: fonts.$text-bold;
			font-weight: fonts.$font-weight-bold;
			font-size: em-calc.func(42, 13);
		}

		p.has-text-align-center.has-large-font-size {
			font-size: em-calc.func(18, 13);
		}

		& > .wp-block-cover__inner-container > .wp-block-group {
			grid-column: 2/-2 !important;
		}
	}

	&.is-style-hero {
		color: colors.$white;
		display: flex;
		grid-template-columns: none !important;

		* {
			color: currentColor;
		}

		#{$prefix}__image-background {

			@include breakpoint.mx(small only) {
				display: none;
			}
		}

		#{$prefix}__inner-container {
			@include fluid-property.mx(padding-top, (72, 120));
			@include fluid-property.mx(padding-bottom, (190, 205));
			grid-column: initial !important;
			grid-template-columns: auto 450px !important;
			max-width: 1240px;
			margin: 0 auto;
			padding-left: 20px;
			padding-right: 20px;

			@include breakpoint.mx(small) {
				display: grid;
				grid-template-rows: auto;

				& > * {
					grid-column: 2 !important;
				}
			}

			@media screen and (max-width: 1024px) {
				grid-template-columns: 450px auto !important;

				& > * {
					grid-column: 1 !important;
				}
			}

			.wp-block-image {

				@media screen and (max-width: 1024px) {
					@include absolute-fit.mx();

					img {
						@include object-fit.mx(cover, center);
					}
				}
				@media screen and (min-width: 1025px) {
					display: none;
				}
			}
		}

		.h1,
		.has-large-font-size,
		.wp-block-buttons {
			position: relative;
			z-index: 1;
		}

		.h1 {
			font-size: calc(36.8244274809px + 4.5801526718vw);

			@media screen and (min-width: 768px) {
				font-size: 72px;
				line-height: em-calc.func(68.4, 72);
			}

			margin-bottom: 20px;
			margin-top: 0;
			text-shadow: 0 1px 4px rgba(0, 0, 0, .45);
		}

		.wp-block-buttons {
			margin-top: 0;
		}

		.wp-block-button__link svg {
			font-size: em-calc.func(14);
			margin-left: 1em;
		}

		.has-large-font-size { // Style the large paragraph to match.
			@include fluid-property.mx(margin-bottom, (15, 20));
			font-family: fonts.$disp-medium;
			line-height: 1;
			max-width: em-calc.func(240);
			font-size: calc(12.3664122137px + 2.0356234097vw) !important;

			@media screen and (min-width: 768px) {
				font-size: 28px !important;
			}
		}
	}
}