@use "zurb/unit" as unit;
@use "settings/fonts" as fonts;

.page-template-college {
	.hentry {
		h2:not([class]) {
			font-size: unit.rem-calc(48, 13);
			margin-bottom: 20px;

			@media screen and (max-width: 991px) {
				font-size: unit.rem-calc(38, 13);
			}
		}
		
		h3:not([class]) {
			font-size: unit.rem-calc(30, 13);

			@media screen and (max-width: 991px) {
				font-size: unit.rem-calc(20, 13);
			}
		}

		p {
			font-size: unit.rem-calc(18, 13);

			@media screen and (max-width: 991px) {
				font-size: unit.rem-calc(14, 13);
			}
		}
	}
}

.page-template-interior-subnav { 

	.hentry {
		display: block; // Override the display grid default.

		h2 {
			font-size: unit.rem-calc(48, 13);
			margin-bottom: 20px;

			@media screen and (max-width: 991px) {
				font-size: unit.rem-calc(38, 13);
			}
		}
		
		h3 {
			font-size: unit.rem-calc(30, 13);

			@media screen and (max-width: 991px) {
				font-size: unit.rem-calc(20, 13);
			}
		}

		p {
			@media screen and (max-width: 991px) {
				font-size: unit.rem-calc(18, 13);
			}
		}

		.wp-block-button.is-style-text .wp-block-button__link.is-style-text {
			font-family: fonts.$body;
			text-decoration: underline;

			svg {
				text-decoration: none;
			}
		}

		ul {
			padding-top: 17.5px;
			padding-bottom: 17.5px;
		}
	}
}