@use "lists-mixins";
@use "../zurb/unit" as unit;

body.page-template-degree .degree-section__content ul > li{
	font-size: unit.rem-calc(17, 13) !important;
}

.content ul:not(.blocks-gallery-grid):not(.sidebar-left-nav__links){
	@include lists-mixins.list();
}

.content ol {
	@include lists-mixins.list((
		"list-style": counter,
	));
}

.content li {
	@include lists-mixins.li();
}

.mw-1200 ul.has-small-font-size li {
	font-size: unit.rem-calc(16, 13);
}