@use "sass:map";
@use "../settings/colors";
@use "../zurb/breakpoint";
@use "../zurb/unit";

//@import "wordpress-variables";
@import "../../../node_modules/@glidejs/glide/src/assets/sass/glide.core";
//@import "../../../node_modules/@wordpress/block-library/src/gallery/style"; // WP stylesheets have to be imported.

.wp-block-gallery-container {
	position: relative;

	.blocks-gallery-grid {
		margin-bottom: 0;
	}

	.blocks-gallery-item {
		margin-bottom: 0;
		padding-left: 0;

		figure,
		img {
			height: 100%;
		}

		img {
			object-fit: cover;
			width: 100%;
		}
	}

	button {
		appearance: none;
		cursor: pointer;
	}

	.glide__arrows {

		button {
			align-items: center;
			background-color: colors.$red-light;
			border: none;
			border-radius: 100%;
			color: colors.$white;
			display: flex;
			font-size: unit.rem-calc(36);
			height: 1em;
			justify-content: center;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 1em;

			&:hover {
				background-color: colors.$red-dark;
			}

			svg {
				font-size: .5em;
			}

			&:first-child {
				left: unit.rem-calc(30);
			}

			&:last-child {
				right: unit.rem-calc(30);
			}
		}
	}

	.glide__bullets {
		bottom: unit.rem-calc(30);
		display: flex;
		justify-content: center;
		left: 0;
		position: absolute;
		width: 100%;
	}

	.glide__bullet {
		background-clip: content-box;
		background-color: transparent;
		border: none;
		box-sizing: content-box;
		display: flex;
		font-size: 1em;
		height: 1em;
		margin-left: .5em;
		margin-right: .5em;
		padding: .5em;
		width: 1em;

		&:hover,
		&.glide__bullet--active {

			.glide__bullet-inside {
				background-color: colors.$black-haze;
			}
		}
	}

	.glide__bullet-inside {
		border: unit.rem-calc(1) solid colors.$black-haze;
		border-radius: 100%;
		box-sizing: border-box;
		display: block;
		height: 1em;
		width: 1em;
	}
}