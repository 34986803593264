@use "sass:list";
@use "../settings/numbers";
@use "../mixins/fluid-property";
@use "../zurb/breakpoint";
@use "../zurb/unit";

// Mostly just for top-level groups and columns. Anything else can take its inherit spacing.
.hentry {

	> .wp-block-cover,
	> .wp-block-group,
	> .wp-block-columns,
	> .wp-block-media-and-text {
		@include fluid-property.mx((margin-top, margin-bottom), numbers.$group-margin);

		&:last-child {
			@include fluid-property.mx((margin-bottom), numbers.$group-margin-dbl);
		}
	}

	> .wp-block-media-text {

		.wp-block-media-text__content {
			@include fluid-property.mx((padding-top, padding-bottom), numbers.$group-margin);
		}
	}

	> .wp-block-group {
		&[class*="-background-color"] { // Standardize padding for groups with background colors.
			@include fluid-property.mx((padding-top, padding-bottom), numbers.$group-margin-dbl);
		}

		&[class*="is-style-gray-half-background"] {
			@include fluid-property.mx((padding-top, padding-bottom), numbers.$group-margin, false);

			@include breakpoint.mx(large) {
				padding-bottom: unit.rem-calc(list.nth(numbers.$group-margin-dbl, 3));
				padding-top: unit.rem-calc(list.nth(numbers.$group-margin-dbl, 3));
			}
		}
	}
}