@forward "alignment";
@forward "headings";
@forward "buttons";
//@forward "forms";
@forward "links";
@forward "lists";
@forward "paragraph";
@forward "tables";
@forward "color-palette";

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
