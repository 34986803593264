@use "settings/fonts";
@use "settings/breakpoints";
@use "mixins/fluid-property";

// Fix box model
*,
*:before,
*:after {
	box-sizing: border-box;
}

html {
}

// Remove default margin and padding
body,
html {
	margin: 0;
	padding: 0;
	font-size: 13px;
	//@include fluid-property.mx(font-size, (n, n, 16, 18, 20));
}

body {
	font-family: fonts.$body;
	font-size: 1rem;
}

// Prevent double borders
table {
	border-collapse: collapse;
}

figure {
	margin: 0;
}

img {
	height: auto;
	max-width: 100%;
}

.fw-n {
	font-weight: normal !important;
}

@media (prefers-reduced-motion) {

	*,
	*:before,
	*:after {
		animation: none !important;
		transition: none !important;
	}
}
