@use "settings/fonts" as fonts;
@use "zurb/unit" as unit;

.single-tribe_events {
	.tribe-events-pg-template {
		max-width: 100%;
		margin: 0;
	}
	
	.layout-sidebar-right {
		padding-top: 0;

		&__right-side {
			@media screen and (min-width: 992px) {
				padding-left: 100px !important;
			}
		}

		.article-meta__title {
			margin-top: 0;
			margin-bottom: 0;
		}

		.sidebar__widget .social-media-widget {

			@media screen and (min-width:992px) {
				justify-content: space-between;

				a {
					width: 35px;
					min-width: 35px;
					height: 35px;
					display: flex;
					justify-content: center;
					align-items: center;
					border-width: 2px;

					&[href*=facebook] svg {
						width: 12px;
					}

					&[href*=youtube] svg,
					&[href*=twitter] svg,
					&[href*=instagram] svg {
						width: 20px;
					}

					&[href*=linkedin] svg {
						width: 17px;
					}
				}
			}
		}
	}

	.bread-crumbs {
		padding-top: 0;
	}

    .tribe-events-schedule,
    .tribe-block {
        display: none;;
    }

	.text-box {
		p {
			font-family: fonts.$text-regular;

			@media screen and (min-width:992px) {
				font-size: unit.rem-calc( 14.625, 13 );
			}
		}

		ul {
			font-family: fonts.$text-regular;

			@media screen and (min-width:992px) {
				font-size: unit.rem-calc( 13, 13 );
			}
		}
	}

	.campus-filter__input-label {
		@media screen and (min-width:992px) {
			font-size: unit.rem-calc(15, 13);
		}
	}
}