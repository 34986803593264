@use "sass:map";
@use "../mixins/typographic-item";
@use "../settings/colors";
@use "../settings/fonts";
@use "headings";

// TODO: see if we can centralize the typography vertical rhythm settings.
// TODO: just put the (16,18) font-size on the body if we're gonna use it everywhere.
$cell-padding-h: (16, 16, 16, 18, 20);
$cell-padding-v: (8, 8, 8, 9, 10);
$header-padding-h: (16, 16, 16, 18, 20);
$header-padding-v: (8, 8, 8, 9, 10);

@mixin table($settings: ()) {
	$defaults: (
		"border-color": colors.$iron-1,
		"border-width": 1px,
		"border-style": solid,
		"margin-bottom": 1em,
		"margin-top": 0,
		"font-size": 16px,
	);
	$args: map.merge($defaults, $settings);

	@include typographic-item.mx($args);
	border-collapse: collapse;
	font-family: fonts.$text-regular;
}

@mixin table-row($settings: ()) {
	$defaults: (
		"border-color": colors.$iron-1,
		"border-width": 1px,
		"border-style": solid,
	);
	$args: map.merge($defaults, $settings);

	@include typographic-item.mx($args);
}

@mixin table-cell($settings: ()) {
	$defaults: (
		"border-color": colors.$iron-1,
		"border-width": 1px,
		"border-style": solid,
		"padding-top": $cell-padding-v,
		"padding-bottom": $cell-padding-v,
		"padding-left": $cell-padding-h,
		"padding-right": $cell-padding-h,
	);
	$args: map.merge($defaults, $settings);

	@include typographic-item.mx($args);
	vertical-align: top;
}

@mixin table-header($settings: ()) {
	$defaults: (
		"background-color": transparent,
		"border-color": colors.$iron-1,
		"color": colors.$black,
		"border-width": 1px,
		"border-style": solid,
		"padding-top": $header-padding-v,
		"padding-bottom": $header-padding-v,
		"padding-left": $header-padding-h,
		"padding-right": $header-padding-h,
	);
	$args: map.merge($defaults, $settings);

	@include typographic-item.mx($args);
	text-align: left;
}
