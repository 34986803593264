@use "sass:math";
@use "../settings/numbers";
@use "../functions/vw-by-breakpoint";
@use "../mixins/fluid-property";
@use "../mixins/width_wide";
@use "../zurb/breakpoint";
@use "../zurb/unit" as unit;

.hentry {
	display: grid;
	//grid-template-columns: min(#{vw-by-breakpoint.func(30, 414)}, 50px) auto min(#{vw-by-breakpoint.func(30, 414)}, 50px);
    grid-template-columns: 16.5px 1fr 16.5px;

	> * {
		grid-column: 2;
		min-width: 0; // https://css-tricks.com/preventing-a-grid-blowout/
	}

	@include breakpoint.mx(numbers.$default-breakpoint) {
		grid-template-columns:1fr #{numbers.$default-column-width} #{numbers.$narrow-column-width} #{numbers.$default-column-width} 1fr;

		> * {
			grid-column: 2/-2;
		}
	}
	@include breakpoint.mx(numbers.$wide-breakpoint) {
		grid-template-columns:1fr minmax(162px, 218px) minmax(162px, 218px) minmax(512px, 568px) minmax(162px, 218px) minmax(162px, 218px) 1fr;

		> * {
			grid-column: 3/6;
		}
	}
}

.hentry .alignfull {
	grid-column: 1/4;

	@media screen and (max-width:1028px) {
		grid-column: 1/5;
	}

	// Reestablish the same grid here, so nested elements still live on the same track.
	&:not(.wp-block-group, .wp-block-cover), // But not on wp-block-group, because it has a full-width inner-container div. NOTE: we COULD add wp-block-separator here for the same reason, but it's more efficient to force the display to block at all times.
	> .wp-block-group__inner-container,
	> .wp-block-cover__inner-container { // Detect for wp-block-group.
		display: grid;
		grid-template-columns: min(#{vw-by-breakpoint.func(30, 414)}, 16.5px) auto min(#{vw-by-breakpoint.func(30, 414)}, 16.5px);

		> div:not([class*="align"],.is-style-slim) {
			grid-column: 2;
			min-width: 0;
		}
	}

	& > div:not([class*="align"]) { // Used div instead of * to prevent img tags in cover blocks from getting caught.
		grid-column: 2;
		//padding-left: min(#{vw-by-breakpoint.func(30, 414)}, 50px);
		//padding-right: min(#{vw-by-breakpoint.func(30, 414)}, 50px);
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		max-width: unit.rem-calc( 1150, 13 );
		

		@media screen and (min-width: 1380px) {
			max-width: unit.rem-calc( 1240px, 13 );
		}

		@media screen and (min-width: 1680px) {
			max-width: unit.rem-calc( 1416px, 13 );
		}
	}

	@include breakpoint.mx(numbers.$default-breakpoint up) {
		grid-column: 1/-1;
		grid-template-columns:1fr #{numbers.$default-column-width} #{numbers.$narrow-column-width} #{numbers.$default-column-width} 1fr;

		> div:not([class*="align"],.is-style-slim) {
			grid-column: 3;
		}

		&:not(.wp-block-group, .wp-block-cover),
		> .wp-block-group__inner-container,
		> .wp-block-cover__inner-container{
			grid-template-columns:1fr #{numbers.$default-column-width} #{numbers.$narrow-column-width} #{numbers.$default-column-width} 1fr;

			> div:not([class*="align"],.is-style-slim) { // We use this so that alignwide/alignnarrow don't have specificity issues.
				grid-column: 3;
			}
		}
	}
	// @include breakpoint.mx(numbers.$wide-breakpoint) {
	// 	grid-column: 1/-1;
	// 	grid-template-columns:1fr #{numbers.$wide-column-width} #{numbers.$default-column-width} #{numbers.$narrow-column-width} #{numbers.$default-column-width} #{numbers.$wide-column-width} 1fr;

	// 	> div:not([class*="align"],.is-style-slim) {
	// 		grid-column: 3/6;
	// 		padding-left: 0; // Drop the extra padding.
	// 		padding-right: 0;
	// 	}

	// 	&:not(.wp-block-group, .wp-block-cover),
	// 	> .wp-block-group__inner-container,
	// 	> .wp-block-cover__inner-container{
	// 		grid-template-columns:1fr #{numbers.$wide-column-width} #{numbers.$default-column-width} #{numbers.$narrow-column-width} #{numbers.$default-column-width} #{numbers.$wide-column-width} 1fr;

	// 		> div:not([class*="align"],.is-style-slim) {
	// 			grid-column: 3/6;
	// 		}
	// 	}
	// }
}


#content .hentry .alignwide-inside > div,
.hentry .alignwide {
	grid-column: 2;

	@include breakpoint.mx(numbers.$default-breakpoint) {
		grid-column: 1/-1;
	}
	@include breakpoint.mx(numbers.$wide-breakpoint) {
		grid-column: 1/-1;
	}

	& > div {
		margin-left: auto;
		margin-right: auto;
		//max-width: math.div(numbers.$site-width, 16) * 1rem;
		width: 100%;
		max-width: unit.rem-calc( 1150, 13 );
		padding-left: 16.25px;
		padding-right: 16.25px;

		@media screen and (min-width: 1028px) {
			padding-left: 0;
			padding-right: 0;
		}

		@media screen and (min-width: 1380px) {
			max-width: unit.rem-calc( 1240px, 13 );
		}

		@media screen and (min-width: 1680px) {
			max-width: unit.rem-calc( 1416px, 13 );
		}
	}
}

.hentry .alignnarrow,
.hentry .is-style-slim {
	grid-column: 2;

	@include breakpoint.mx(numbers.$default-breakpoint) {
		grid-column: 3;
	}
	@include breakpoint.mx(numbers.$wide-breakpoint) {
		grid-column: 4;
	}
}

.hentry .alignfull .is-style-slim {
	@include breakpoint.mx(numbers.$wide-breakpoint) {
		grid-column: 3;

		h2 { 
			font-size: calc(2.5vw - 4px);
		}
	}
}

.alignleft {
	margin-bottom: 1em;

	@include breakpoint.mx(medium) {
		float: left;
		margin-right: 1.5em;
		margin-top: .25em;
	}
}

.aligncenter {
	margin-left: auto;
	margin-right: auto;
	text-align: center; // Just in case.
}
img.aligncenter {
	display: block;
}

.alignright {
	margin-bottom: 1em;

	@include breakpoint.mx(medium) {
		float: right;
		margin-left: 1.5em;
		margin-top: .25em;
	}
}

.alignnone { // Why do we even /have/ this lever?!

}

.has-text-align-left {
	text-align: left;
}

.has-text-align-center {
	text-align: center;
}

.has-text-align-right {
	text-align: right;
}

.is-content-justification-left {
	display: flex; // Flex is included just in case the parent block doesn't have it.
	justify-content: flex-start;
}

.is-content-justification-center {
	display: flex;
	justify-content: center;
}

.is-content-justification-space-between {
	display: flex;
	justify-content: space-between;
}

.is-content-justification-right {
	display: flex;
	justify-content: flex-end;
}

.are-vertically-aligned-top {
	align-items: flex-start;
	display: flex;
}

.are-vertically-aligned-center {
	align-items: center;
	display: flex;
}

.are-vertically-aligned-bottom {
	align-items: flex-end;
	display: flex;
}