@use "../settings/colors";
@use "../settings/fonts";
@use "../functions/em-calc";
@use "../zurb/breakpoint";
@use "../zurb/unit";

.is-style-tab-accordion .wp-block-cnpblock-accordion .accordion__item[aria-expanded="false"] .accordion__item-content {
	display: none;
}

body.page-id-2199 .wp-block-cnpblock-accordion .accordion__item-content {
	p, li {
		font-family: sans-serif;
	}
}

.wp-block-cnpblock-accordion {
	$prefix: ".accordion__item";
	border-top: 1px solid #EDF0F0;

	&:last-child {
		@include breakpoint.mx(small only) {
			border-bottom: 1px solid #EDF0F0;
		}
	}

	.accordion__item {

		&:last-child {
			border-bottom: 1px solid #EDF0F0;
		}

		&[aria-expanded="false"] {

			#{$prefix}-content {			
				//display: none;
				display: inherit;
				opacity: 0;
				height: 0;
				padding-top: 0;
				padding-bottom: 0;
				overflow: hidden;
			}
		}

		&[aria-expanded="true"] {

			#{$prefix}-toggle svg {
				transform: rotate(-180deg);
			}
		}

		&-toggle {
			align-items: center;
			appearance: none;
			background-color: transparent;
			border: none;
			cursor: pointer;
			display: flex;
			font-family: fonts.$disp-medium;
			font-weight: fonts.$font-weight-bold;
			font-size: unit.rem-calc(21, 13);
			justify-content: space-between;
			padding: unit.rem-calc(14, 13) unit.rem-calc(20, 13) unit.rem-calc(14, 13) 0; // Removed padding-left/right of 20 here. Should only have padding-left on mobile in the tab/accordion group.
			position: relative;
			text-align: left;
			width: 100%;
			z-index: 1; // This places the outline above the content, instead of clipping off the bottom.
			transition: all .45s ease;

			svg {
				color: colors.$red-light;
			}

			&:hover,
			&[aria-selected="true"] {
				color: colors.$red-light;
			}
		}

		&-content {
			background-color: colors.$black-haze;
			padding: unit.rem-calc(25, 13) unit.rem-calc(30, 13);
			transition: all .4s;
			
			p, ul li, ol li { 
				font-size: unit.rem-calc(18, 13);

				@media screen and (min-width: 992px) {
					font-size: unit.rem-calc(16.25, 13);
				}

				br { 
					display: none;
				}

				a {
					text-decoration: underline;
				}
			}
		}
	}
}