@use './settings/fonts' as fonts;
@use './settings/colors' as colors;

.submenu-adi > a,
.submenu-cim > a {
	display: flex !important;
	align-items: center;
	justify-content: flex-start;
	background-color: transparent !important;
	
	&:before {
		content: "";
		display: block;
		height: 40px;
		width: 40px;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		margin-right: 8px;
	}

	&:hover {
		background-color: colors.$black-haze !important;
	}
}

.submenu-cim > a:before {
	background-image: url(/wp-content/themes/bc-theme/dist/img/mega-menu/cim.svg);
}

.submenu-adi > a:before {
	background-image: url(/wp-content/themes/bc-theme/dist/img/mega-menu/adi.svg);
}

/** DESKTOP ONLY STYLES **/
@media screen and (min-width: 821px) {

	.current-students {
		display: none;
	}

	.top-menu {
		background: #F5F5F5;
		width: 100%;
		position: relative;
		z-index: 1000;
		
		&__links {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 10.5px 0;

			li {
				a {

					font-family: fonts.$disp-regular;
					font-style: normal;
					font-weight: 400;
					font-size: 10px;
					line-height: 11px;
					display: block;
					text-align: center;
					letter-spacing: 1px;
					text-transform: uppercase;
					text-decoration: none;
					color: #202227;
					padding: 0 10.5px;
					border-right: 1px solid #C9CED1;

					&:hover {
						text-decoration: underline;
					}
				}

				&:last-child a {
					padding-right: 23px;
					border-right: none;
				}
			}
		}
	}

	.submenu-title > a:focus {
		outline: none !important;
	}

	.mega-nav {
		background: transparent !important;
		
		&__container {

			position: relative;

			&.is-half:before {
				width: 66.66%;
			}

			&:before {
				content: "";
				display: block;
				background: #fff;
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				width: 100%;
				transition: all 0.5s;
			}

			.mobile-back-btn > a {
				display: none;
			}

			// top level menu
			.menu {

				li.see-more > a {
					color: #E7002E !important;
					font-family: fonts.$disp-bold !important;
					font-weight: fonts.$font-weight-bold;
				}
										
				&:before {
					content: "";
					display: block;
					position: absolute;
					top: 0;
					left: 33.33%;
					bottom: 0;
					box-shadow: inset -1px 0px 0px rgba(201, 206, 209, 0.4);
					width: 1px;
					z-index: 1;
				}
				
				// far left list
				> li {
					width: 33.33%;

					// top-level link
					> a {
						font-family: fonts.$disp-bold;
						font-style: normal;
						font-weight: fonts.$font-weight-bold;
						font-size: 16px;
						line-height: 18px;
						color: colors.$baker-black;
						position: relative;
						display: block;
						width: 100%;
						padding: 21px 0 21px 32px;
						z-index: 10;
						border-top: 1px solid #E5E5E5;

						&:hover {
							background: colors.$black-haze;
							box-shadow: inset 8px 0px 0px #E7002E;

							&:before {
								background: url(/wp-content/themes/bc-theme/dist/img/icons/chevron-right-black.svg) right center no-repeat;
							}
						}

						&:before {
							background: url(/wp-content/themes/bc-theme/dist/img/icons/chevron-right-light.svg) right center no-repeat;
							content: "";
							display: block;
							width: 40px;
							height: 100%;
							position: absolute;
							right: 32px;
							top: 0;
							transition: all .5s ease;
						}

					}

					&:not(.submenu-expandable) { 

						&.active > a {
							font-family: fonts.$disp-bold !important;
							font-weight: fonts.$font-weight-bold;
							background: colors.$black-haze;
							box-shadow: inset 8px 0px 0px #E7002E;

							&:before {
								background: url(/wp-content/themes/bc-theme/dist/img/icons/chevron-right-black.svg) right center no-repeat;
							}
						}

						&.two-cols > ul.sub-menu {
							
							
							> li {

								&.submenu-left {
									float: left;
									width: 50%;
								}

								&.submenu-right {
									float: right;
									width: 50%;
								}

							}

						}

						&.active > ul.sub-menu {
							opacity: 1;
							transform: scaleY(1);

							&>li.submenu-title:last-child:after {
								transform: scaleX(1);
							}
						}

						> ul.sub-menu {
							position: absolute;
							left: 33.33%;
							top: 0;
							width: 66.66%;
							height: 100%;
							opacity: 0;
							transform: scaleY(0);
							transform-origin: top center;
							transition: all 0.5s;
							
							&>li.submenu-title {
								width: 50%;

								&:last-child {
									position: relative;

									&:after {
										content: "";
										background: #fff;
										position: absolute;
										bottom: 0;
										left: -100%;
										width: 200%;
										height: 50px;
										z-index: -1;
										transform: scaleX(0);
										transition: transform 0.5s;
									}
								}
								
								// All Caps Sub Menu Title
								> a {
									font-family: fonts.$disp-regular;
									font-style: normal;
									font-weight: normal;
									font-size: 10px;
									line-height: 38px;
									display: flex;
									align-items: center;
									letter-spacing: 3px;
									text-transform: uppercase;
									color: colors.$baker-black;
									padding: 18px 0 4px 32px;
									user-select: none;
									pointer-events: none;

									&:focus {
										outline: none !important;
									}
								}

								> ul.sub-menu:last-child {
									
									> li {

										&:not(.see-more) > a:hover {
											background-color: colors.$black-haze;
										}

										&.see-more > a:hover {
											text-decoration: underline;
										}

										> a {
											font-family: fonts.$disp-regular;
											font-size: 14px;
											line-height: 24px;
											color: colors.$baker-black;
											padding: 8px 0 8px 32px;
											display: block;
											width: 100%;
											position: relative;
										}

									}

								}

							}

						}

					}

				}

			}

		}

		.submenu-expandable {

			&.active {

				> ul.sub-menu {
					opacity: 1;
					max-height: inherit;
					overflow: visible;
				}

				> a {
					box-shadow: inset 8px 0px 0px #E7002E;

					&:before {
						background-image: url(/wp-content/themes/bc-theme/dist/img/icons/menu-minus-icon.svg) !important;
					}
				}
			}

			> a:before {
				display: block;
				content: "";
				transform: rotate(0);
				background-image: url(/wp-content/themes/bc-theme/dist/img/icons/menu-plus-icon.svg) !important;
			}

			// Degrees List
			> ul.sub-menu {
				opacity: 0;
				max-height: 0;
				overflow: hidden;
				transform-origin: top center;
				transition: all 0.5s;
				
				> li {
					width: 100%;

					&.active {
						> ul.sub-menu {
							opacity: 1;
							transform: scaleY(1);
						}

						> a {
							font-family: fonts.$disp-bold !important;
							font-weight: fonts.$font-weight-bold;
							background: colors.$black-haze;

							&:before {
								background: url(/wp-content/themes/bc-theme/dist/img/icons/chevron-right-black.svg) right center no-repeat;
							}
						}
					}

					> a {
						font-family: fonts.$text-regular;
						font-style: normal;
						font-weight: normal;
						font-size: 14px;
						color: colors.$baker-black;
						padding: 10px 0 10px 32px;
						display: block;
						line-height: 24px;
						position: relative;
						transition: all 0.5s;

						&:before {
							background: url(/wp-content/themes/bc-theme/dist/img/icons/chevron-right-light.svg) right center no-repeat;
							content: "";
							display: block;
							width: 40px;
							height: 100%;
							position: absolute;
							right: 32px;
							top: 0;
							transition: all .5s ease;
						}

						&:hover {
							background: colors.$black-haze;

							&:before {
								background: url(/wp-content/themes/bc-theme/dist/img/icons/chevron-right-black.svg) right center no-repeat;
							}
						}
					}

					// Degrees 1st Level Sub Menu
					> ul.sub-menu {
						position: absolute;
						left: 33.33%;
						top: 0;
						display: block;
						width: 66.66%;
						height: 100%;
						opacity: 0;
						transform: scaleY(0);
						transform-origin: top center;
						transition: opacity 0.5s, transform 0.5s;
										
						&:before {
							content: "";
							display: block;
							position: absolute;
							top: 0;
							left: 50%;
							bottom: 0;
							width: 1px;
							z-index: 10;
							box-shadow: inset -1px 0px 0px rgba(201, 206, 209, 0.4);
						}

						&>li.submenu-title {
							
							// All Caps Sub Menu Title
							> a {
								font-family: fonts.$disp-regular;
								font-style: normal;
								font-weight: normal;
								font-size: 10px;
								line-height: 38px;
								display: flex;
								align-items: center;
								letter-spacing: 3px;
								text-transform: uppercase;
								color: colors.$baker-black;
								padding: 18px 0 4px 32px;
								user-select: none;
								pointer-events: none;
							}

							// Degree Left Menu
							> ul.sub-menu {
								width: 50%;
								height: 100%;

								> li {
									width: 100%;

									&.active{
										> ul.sub-menu {
											transform: scaleY(1);
											opacity: 1;
										}

										> a {
											font-family: fonts.$disp-bold !important;
											font-weight: fonts.$font-weight-bold;
											background: colors.$black-haze;

											&:before {
												background: url(/wp-content/themes/bc-theme/dist/img/icons/chevron-right-black.svg) right center no-repeat;
											}
										}
									}

									&.see-more > a {
										&:before {
											display: none;
										}

										&:hover {
											text-decoration: underline;
										}
									}

									&:not(.see-more) > a:hover {
										background: colors.$black-haze;

										&:before {
											background: url(/wp-content/themes/bc-theme/dist/img/icons/chevron-right-black.svg) right center no-repeat;
										}
									}

									// Degree Left Menu List Item
									> a {
										font-family: fonts.$disp-regular;
										font-size: 14px;
										line-height: 24px;
										color: colors.$baker-black;
										padding: 10px 0 10px 32px;
										display: block;
										width: 100%;
										position: relative;

										&:before {
											background: url(/wp-content/themes/bc-theme/dist/img/icons/chevron-right-light.svg) right center no-repeat;
											content: "";
											display: block;
											width: 40px;
											height: 100%;
											position: absolute;
											right: 32px;
											top: 0;
											transition: all .5s ease;
										}

									}

									// Degree Right Menu
									& > ul.sub-menu {
										position: absolute;
										left: 50%;
										top: 0;
										width: 50%;
										display: block;
										opacity: 0;
										transform: scaleY(0);
										transform-origin: top center;
										transition: all 0.5s;

										> li.submenu-title {

											// All Caps Right Sub Menu Title
											> a {
												font-family: fonts.$disp-regular;
												font-style: normal;
												font-weight: normal;
												font-size: 10px;
												line-height: 38px;
												display: flex;
												align-items: center;
												letter-spacing: 3px;
												text-transform: uppercase;
												color: colors.$baker-black;
												padding: 18px 0 4px 32px;
												user-select: none;
												pointer-events: none;
											}

											> ul.sub-menu {

												> li {
													&:not(.see-more) > a:hover {
														background-color: colors.$black-haze;
													}
													&.see-more > a:hover {
														text-decoration: underline;
													}

													> a {
														font-family: fonts.$disp-regular;
														font-size: 14px;
														line-height: 24px;
														color: colors.$baker-black;
														padding: 10px 0 10px 32px;
														display: block;
														width: 100%;
													}
												}

											}

										}

									}

								}

							}

						}

					}

				}

			}

		}

		li.submenu-single > ul.sub-menu {
			width: 33.33% !important;

			>li.submenu-title {
				width: 100% !important;
			}
		}

	}
	
}

/** MOBILE ONLY STYLES **/
@media screen and (max-width: 820px) {
	.top-menu {
		display: none;
	}

	.current-students {
		background: #F5F7F7;
		padding: 21px 0 21px 32px;
		border-top: 1px solid #E5E5E5;

		> a {
			font-family: fonts.$disp-regular;
			font-style: normal;
			font-weight: 400;
			font-size: 10px;
			line-height: 11px;
			display: flex;
			align-items: center;
			letter-spacing: 3px;
			text-transform: uppercase;
			color: #1F2228;
			padding-bottom: 12px;
		}

		> ul > li > a {
			font-family: fonts.$disp-regular;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 18px;
			letter-spacing: 1px;
			text-transform: uppercase;
			color: #202227;
			display: block;
			padding: 16px 8px;
		}
	}

	.mega-nav {
		&__container {

			ul.menu {
				position: absolute;
				top: 55px;
				left: 0;
				width: 100%;
				height: calc(100% - 55px);
				overflow: auto;

				&.child-menu-open {
					overflow: hidden;
				}

				> li:not(.current-students) > a {
					font-family: fonts.$disp-bold;
					font-style: normal;
					font-weight: fonts.$font-weight-bold;
					font-size: 16px;
					line-height: 18px;
					color: colors.$baker-black;
					position: relative;
					display: block;
					width: 100%;
					padding: 21px 0 21px 32px;
					z-index: 10;

					&:before {
						background: url(/wp-content/themes/bc-theme/dist/img/icons/chevron-right-light.svg) right center no-repeat;
						content: "";
						display: block;
						width: 40px;
						height: 100%;
						position: absolute;
						right: 32px;
						top: 0;
						transition: all .5s ease;
					}

				}
			}
		}

		li.see-more > a {
			color: #E7002E !important;
			font-family: fonts.$disp-bold !important;
			font-weight: fonts.$font-weight-bold;
		}

		ul.menu > li.submenu-expandable {
			.submenu-title {

				// All Caps Sub Menu Title
				> a {
					font-family: fonts.$disp-regular;
					font-style: normal;
					font-weight: normal;
					font-size: 10px;
					line-height: 38px;
					display: flex;
					align-items: center;
					letter-spacing: 3px;
					text-transform: uppercase;
					color: colors.$baker-black;
					padding: 18px 0 4px 52px;
				}

				&:not(.mobile-back-btn) > a {
					user-select: none;
					pointer-events: none;
				}
			}

			>ul.sub-menu {
				display: none;

				> li {

					&.active {

						> ul.sub-menu {
							display: block;
							position: fixed;
							top: 156px;
							left: 0;
							width: 100%;
							bottom: 0;
							background: #fff;
							z-index: 10;
							overflow: auto;
							height: calc(100vh - 156px);

							> li.submenu-title  > ul.sub-menu > li.active > ul.sub-menu {
								display: block;
								position: fixed;
								top: 156px;
								left: 0;
								height: calc(100vh - 156px );
								width: 100%;
								background: #fff;
								z-index: 10;
								overflow: auto;

								.mobile-back-btn {
									position: relative;

									> a {
										font-family: fonts.$disp-bold !important;
										font-style: normal;
										font-weight: fonts.$font-weight-bold;
										font-size: 11px;
										line-height: 38px;
										padding: 18px 0 4px 52px;
										letter-spacing: 1px;
										text-transform: uppercase;
										color: colors.$baker-black;

										&::before {
											content: "";
											background: url(../img/icons/red-back-arrow.svg) 50% no-repeat;
											background-size: auto;
											display: block;
											height: 20px;
											position: absolute;
											transition: all 0.5s ease;
											width: 20px;
											left: 15px;
											transform: rotate(0);
											top: 25px;
										}
									}
								}
							}
						}
					}

					> a {
						font-family: fonts.$disp-regular;
						font-style: normal;
						font-weight: normal;
						font-size: 14px;
						line-height: 18px;
						color: colors.$baker-black;
						position: relative;
						display: block;
						width: 100%;
						padding: 21px 0 21px 32px;
						z-index: 1;

						&:after {
							background: url(/wp-content/themes/bc-theme/dist/img/icons/chevron-right-light.svg) right center no-repeat;
							content: "";
							display: block;
							width: 40px;
							height: 100%;
							position: absolute;
							right: 32px;
							top: 0;
							transition: all .5s ease;
						}
					}

					> ul.sub-menu {
						display: none;

						> li {
							&:not(.submenu-title):not(.mobile-back-btn) > a {
								font-family: fonts.$disp-regular;
								font-size: 14px;
								line-height: 24px;
								color: colors.$baker-black;
								padding: 8px 0 8px 52px;
								display: block;
								width: 100%;
								position: relative;
							}

							ul.sub-menu > li > a {
								font-family: fonts.$disp-regular;
								font-size: 14px;
								line-height: 24px;
								color: colors.$baker-black;
								padding: 16px 0 16px 52px;
								display: block;
								width: 100%;
								position: relative;
							}

							> ul.sub-menu > li:not(.see-more) > a:after {
								background: url(/wp-content/themes/bc-theme/dist/img/icons/chevron-right-light.svg) right center no-repeat;
								content: "";
								display: block;
								width: 40px;
								height: 100%;
								position: absolute;
								right: 32px;
								top: 0;
								transition: all .5s ease;
							}

							&.mobile-back-btn {
								position: relative;

								> a {
									font-family: fonts.$disp-bold !important;
									font-style: normal;
									font-weight: fonts.$font-weight-bold;
									font-size: 11px;
									line-height: 38px;
									padding: 18px 0 4px 52px;
									letter-spacing: 1px;
									text-transform: uppercase;
									color: colors.$baker-black;
									display: block;

									&::before {
										content: "";
										background: url(../img/icons/red-back-arrow.svg) 50% no-repeat;
										background-size: auto;
										display: block;
										height: 20px;
										position: absolute;
										transition: all 0.5s ease;
										width: 20px;
										left: 15px;
										transform: rotate(0);
										top: 25px;
									}
								}
							}

							&.submenu-title {

								// All Caps Sub Menu Title
								> a {
									font-family: fonts.$disp-regular;
									font-style: normal;
									font-weight: normal;
									font-size: 10px;
									line-height: 38px;
									display: flex;
									align-items: center;
									letter-spacing: 3px;
									text-transform: uppercase;
									color: colors.$baker-black;
									padding: 18px 0 4px 52px;
									user-select: none;
									pointer-events: none;
								}

								> ul.sub-menu > li > ul.sub-menu {
									display: none;
								}
							}
						}
					}
				}
			}

			&.active {

				> ul.sub-menu {
					display: block;
				}

				> a {
					color: #E7002E !important;

					&:before {
						background-image: url(/wp-content/themes/bc-theme/dist/img/icons/menu-minus-icon.svg) !important;
					}
				}
			}

			> a:before {
				display: block;
				content: "";
				transform: rotate(0);
				background-image: url(/wp-content/themes/bc-theme/dist/img/icons/menu-plus-icon.svg) !important;
			}
		}

		ul.menu >li:not(.submenu-expandable):not(.current-students) {
			border-top: 1px solid #E5E5E5;

			>ul.sub-menu {
				display: none;

				>li.mobile-back-btn {
					> a {
						font-family: fonts.$disp-bold !important;
						font-style: normal;
						font-weight: fonts.$font-weight-bold;
						font-size: 11px;
						line-height: 38px;
						padding: 18px 0 4px 52px;
						letter-spacing: 1px;
						text-transform: uppercase;
						color: colors.$baker-black;

						&::before {
							content: "";
							background: url(../img/icons/red-back-arrow.svg) 50% no-repeat;
							background-size: auto;
							display: block;
							height: 20px;
							position: absolute;
							transition: all 0.5s ease;
							width: 20px;
							left: 15px;
							transform: rotate(0);
							top: 7px;
						}
					}
				}

				>li.submenu-title {

					// All Caps Sub Menu Title
					> a {
						font-family: fonts.$disp-regular;
						font-style: normal;
						font-weight: normal;
						font-size: 10px;
						line-height: 38px;
						display: flex;
						align-items: center;
						letter-spacing: 3px;
						text-transform: uppercase;
						color: colors.$baker-black;
						padding: 18px 0 4px 52px;
						user-select: none;
						pointer-events: none;
					}

					> ul.sub-menu > li {
						> a {
							font-family: fonts.$disp-regular;
							font-size: 14px;
							line-height: 24px;
							color: colors.$baker-black;
							padding: 16px 0 16px 52px;
							display: block;
							width: 100%;
							position: relative;
						}
					}

				}
			}

			> a {
				position: relative;
				z-index: 1;
			}

			&.active {

				> ul.sub-menu {
					display: block;
					position: fixed;
					top: 156px;
					left: 0;
					width: 100%;
					background: #fff;
					z-index: 10;
					overflow: auto;
					height: calc(100vh - 156px );
				}
			}
		}
	}

	li.two-cols > ul.sub-menu {		
		
		> li {

			&.submenu-left {
				float: left;
				width: 100%;
			}

			&.submenu-right {
				float: right;
				width: 100%;
			}

		}

	}
}