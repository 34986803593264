@use "../settings/colors";
@use "../settings/fonts";
@use "button-mixins";

.btn {
	@include button-mixins.button((
		"border-color-hover": colors.$red-dark,
		"background-hover": colors.$red-dark,
		"color-hover": colors.$white,
	));

	&:focus {
		@include button-mixins.button-focus();
	}
}
.btn-angle-right {
	@include button-mixins.button((
		"border-color": transparent,
		"background": transparent,
		"color": colors.$red-light,
		"padding": 0,
		"border-color-hover": transparent,
		"background-hover": transparent,
		"color-hover": colors.$red-dark,
	));
	border: none;
	white-space: normal;

	svg.svg-inline--fa {
		color: currentColor;
		margin-left: .5em;
		font-size: 1.125rem;
	}
}

button {
	font-family: fonts.$body;
	color: #000;
}
