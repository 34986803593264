@use "settings/fonts" as fonts;

.contact-form__form span.error {
	color: #E7002D;
	display: block;
	font-size: .75rem;
	margin-top: .3125rem;
	position: absolute;
}

#tfa_captcha_text {
	margin-bottom: 1em
}

.captchaHelp {
	font-size: 13px;
	line-height: 1.4
}

.wFormContainer textarea {
	border: 1px solid #D2D2D2;
	height: 100px;
	width: 100%;
}

.wFormContainer .choices .label {
	font-family: fonts.$disp-regular;
	font-weight: normal;
}

.wFormContainer .wf-acl-hidden,
.wFormContainer .offstate {
	display: none
}

.wFormContainer .section {
	display: flex;
	/*flex-direction: column*/
	margin: 0 -5px;
}

@media (min-width: 640px) {
	.wFormContainer .section {
		flex-direction: row
	}
}

.wFormContainer .oneField {
	flex: 1 1 auto;
	margin: 0 5px;
	min-width: 1px;
	padding-bottom: 1.5em;
	width: 100%
}

.wFormContainer form > .oneField {
	margin: 0;
}

@media (min-width: 640px) {
	.wFormContainer .oneField {
		padding-bottom: 2.125em
	}
}

.wFormContainer input,
.wFormContainer select {
	width: 100%
}

.wFormContainer input:focus,
.wFormContainer select:focus {
	box-shadow: none !important;
	outline: 2px dotted #000000;
	outline-offset: -4px;
	transition: none
}

.wFormContainer input[type=checkbox],
.wFormContainer input[type=radio] {
	width: auto
}

.wFormContainer .oneChoice {
	align-items: flex-start;
	display: flex;
	margin-bottom: 1em
}

.wFormContainer .oneChoice:last-child {
	margin-bottom: 0
}

.wFormContainer .oneChoice label {
	margin: 0;
	max-width: 30em
}

.wFormContainer .oneChoice input {
	height: auto;
	line-height: 1;
	margin: .15em .75em 0 0;
	padding: 0;
	width: auto
}

.wFormContainer label {
	display: block;
	font-family: fonts.$disp-medium;
	font-weight: fonts.$font-weight-bold;
	margin-bottom: .5em
}

.wFormContainer input {
	border: solid 1px #D2D2D2;
	display: block;
	height: 3.75em;
	line-height: 3.75em;
	padding: 0 .9375em
}

.wFormContainer .section.inline.group .oneField:last-child input {
	/*border-left: none*/
}

.wFormContainer select {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background: #FFFFFF url(/wp-content/themes/bc-theme/dist/img/icons/chevron-down-red.svg) no-repeat 98% 50%;
	border: solid .05em #D2D2D2;
	border-radius: 0;
	color: #1F2228;
	font-family: fonts.$text-regular, sans-serif;
	font-size: 1.25em;
	height: 3em;
	line-height: 3em;
	padding: 0 .9em;
	width: 100%
}

.wFormContainer .primaryAction {
	background: transparent;
	border: solid 3px #E7002D;
	border-radius: 30px;
	color: #E7002D;
	cursor: pointer;
	display: inline-block;
	font-family: fonts.$disp-medium, sans-serif;
	font-size: 1.125em;
	height: 2.77778em;
	line-height: 2.5em;
	padding-left: 1.875em;
	padding-right: 1.875em;
	text-align: center;
	text-decoration: none;
	white-space: nowrap;
	width: 100%
}

.wFormContainer .primaryAction:hover {
	background: #E7002D;
	color: #FFFFFF;
}

.wFormContainer br {
	display: none
}

.wFormContainer .errFld input,
.wFormContainer .errFld select {
	border-color: red
}

.wFormContainer .errMsg {
	font-size: .75em;
	font-style: italic;
	margin-top: .5em
}

.wf-acl-hidden {
	display: none !important
}

#tfa_3249-E,
#tfa_3250-E {
	display: none !important
}

.wFormWrapper--23 .primaryAction {
	max-width: 19.72222em
}

@media (min-width: 992px) {
	.wFormWrapper--23 .primaryAction {
		margin-top: 3.4375em;
		width: 11.11111em
	}
}

.wFormWrapper--32 .primaryAction {
	max-width: 19.72222em
}

@media (min-width: 992px) {
	.wFormWrapper--32 .primaryAction {
		margin-top: 3.4375em;
		width: 11.11111em
	}
}

.wFormWrapper--32 #tfa_96,
.wFormWrapper--32 [id^="tfa_96["] {
	width: 100% !important
}

.wFormWrapper--32 #tfa_96-D,
.wFormWrapper--32 [id^="tfa_96["][class~=field-container-D] {
	width: 100% !important
}

.wFormWrapper--32 #tfa_3162,
.wFormWrapper--32 [id^="tfa_3162["] {
	width: 100% !important
}

.wFormWrapper--32 #tfa_3162-D,
.wFormWrapper--32 [id^="tfa_3162["][class~=field-container-D] {
	width: 100% !important
}

#tfa_captcha_text {
	margin-bottom: 1em
}

.captchaHelp {
	font-size: 13px;
	line-height: 1.4
}