@use "../settings/colors";
@use "../settings/fonts";
@use "../zurb/unit";

.scholarship-table__table-header {
	font-family: fonts.$disp-bold;
	font-size: 1.5rem;
	letter-spacing: -.03125rem;
	line-height: 2.25rem;
	padding: 1.875rem 1.25rem
}

@media (min-width: 992px) {
	.scholarship-table__table-header {
		border-bottom: .3125rem solid #1F2228;
		font-size: 2.25rem;
		margin-bottom: 1.75rem;
		padding: 1.875rem 0;
	}
}

.scholarship-table__table-row {
	border-bottom: 1px solid #EDF0F0;
	padding: 0 1.25rem;
	position: relative;
	transition: background .45s ease
}

@media (min-width: 992px) {
	.scholarship-table__table-row {
		padding: 0
	}
}

@media (max-width: 991px) {
	.scholarship-table__table-row:first-child {
		border-top: 1px solid #EDF0F0
	}
}

.scholarship-table__table-row--open {
	padding-bottom: 0
}

@media (max-width: 991px) {
	.scholarship-table__table-row--open {
		background: #F5F7F7
	}
}

.scholarship-table__table-row--open .scholarship-table__scholarship-name {
	color: #E7002D
}

@media (min-width: 992px) {
	.scholarship-table__table-row:before {
		right: .9375rem;
		top: 2.25rem
	}
}

.scholarship-table__scholarship-name {
	cursor: pointer;
	font-family: fonts.$text-medium;
	font-size: 1.25rem;
	line-height: 1.875rem;
	padding: .9375rem 1.875rem .9375rem 0;
	transition: all .45s ease;
}

@media (min-width: 992px) {
	.scholarship-table__scholarship-name {
		font-family: fonts.$disp-bold;
		font-size: 1.5rem;
		line-height: 2.25rem;
		padding: 1.5625rem 1.5625rem 1.5rem 0
	}
}

.scholarship-table__scholarship-name:hover {
	color: #E7002D
}

.scholarship-table__scholarship-dropdown p {
	font-family: fonts.$text-regular;
	font-size: 1.125rem;
	line-height: 1.6875rem;
	margin-bottom: 1.25rem;
}

@media (min-width: 992px) {
	.scholarship-table__scholarship-dropdown p {
		font-size: 1.25rem;
		line-height: 1.875rem;
		margin-bottom: 1.875rem
	}
}

.scholarship-table__scholarship-dropdown strong {
	font-family: fonts.$disp-bold;
	//letter-spacing: .0625rem
}

.scholarship-table__scholarship-dropdown .link-download {
	font-size: 1rem
}


.scholarship-table__scholarship-name {
	align-items: flex-start;
	display: flex;
	justify-content: space-between;
	min-width: 100%;
}

@media (max-width: 767px) {
	.scholarship-table .scholarship-table__scholarship-name {
		padding-right: 0;
	}
}

.scholarship-table .scholarship-table__table-row:before {
	display: none;
}

@media screen and (max-width: 991px) {
	.scholarship-table .scholarship-table__table-header {
		border-bottom: .125em solid #1F2228;
		margin-bottom: .5rem;
		margin-left: 1.25rem;
		margin-right: 1.25rem;
		padding: 1.875rem 0 .5rem;
	}

	.scholarship-table .scholarship-table__table-row:first-child {
		border-top: none;
	}
}

.scholarship-table__title {
	line-height: 1.5rem;
}

.scholarship-table__desc {
	font-family: fonts.$text-regular;
	font-size: 14px;
	line-height: 26px;
}

.scholarship-table__scholarship-name svg {
	color: colors.$red-light;
	font-size: unit.rem-calc(18);
	margin-top: .5em;
	transition: all .25s ease;
}

.scholarship-table__condition {
	color: #666666;
	font-family: fonts.$text-regular;
	font-size: 11px;
	letter-spacing: .5px;
	line-height: 15px;
	text-transform: uppercase;
	width: 300px;
}

.scholarship-table__scholarship-name-text {
	margin-right: 1em;
	width: 100%;
}

@media (min-width: 768px) {

	.scholarship-table__scholarship-name-text {
		align-items: baseline;
		display: flex;
	}

	.scholarship-table-name-left {
		margin-right: 2em;
		width: 80%;
	}

	img.scholarship-table__scholarship-name-caret {
		margin-top: .75em;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.scholarship-table .scholarship-table__scholarship-name {
		padding-left: 10px;
		padding-right: 10px;
	}
}

.scholarship-table__table-row--open .scholarship-table__scholarship-name-caret {
	transform: rotateX(180deg);
}

.scholarship-table ul ul li {
	list-style-type: circle;
}

body.user-is-tabbing div.scholarship-table__table-row:focus {
	outline: 2px dotted #000000;
	transition: none;
}

body.user-is-tabbing div.scholarship-table__table-row:active {
	outline: none;
}

.inner-remove-focus {
	outline: none
}