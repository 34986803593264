@use "settings/fonts" as fonts;
@use "settings/colors" as colors;
@use "zurb/unit" as unit;

.hide-fieldset-border {
	border: none;
	padding: 0;
	margin:0;
}

.hide-legend {
	position:absolute;
	left:-5000px;
}


/* hide legends on fieldsets*/

.campus-filter__form fieldset {
	border:none;
	padding: 0;
	margin: 0;
}

.campus-filter__form fieldset legend {
	position:absolute;
	left: -5000px;
}


.mega-nav-event-block {
	background: #f5f7f7 none repeat scroll 0 0;
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}
.mega-nav-event-block__header {
	background: #E7002D none repeat scroll 0 0;
	color: white;
	font-family: fonts.$disp-bold;
	font-size: 1.5rem;
	letter-spacing: -0.03125rem;
	line-height: 2.25rem;
	padding: 1.125rem 1.375rem 1.25rem;
}
.mega-nav-event-block__content-wrap {
	padding: 1.125rem 1.375rem;
}
.mega-nav-event-block__title {
	font-family: fonts.$disp-bold;
	font-size: 1.5rem;
	letter-spacing: -0.03125rem;
	line-height: 2.25rem;
	padding-bottom: 0.3125rem;
}
.mega-nav-event-block__description {
	font-family: fonts.$body;
	font-size: 1rem;
	line-height: 1.5rem;
	padding-bottom: 1.625rem;
}
.mega-nav-event-block__day {
	color: #E7002D;
	font-family: fonts.$disp-bold;
	font-size: 1.5rem;
	letter-spacing: -0.03125rem;
}
.mega-nav-event-block__date {
	color: #E7002D;
	font-family: fonts.$sans-fancy;
	font-size: 4rem;
	letter-spacing: -0.09375rem;
	line-height: 4.2rem;
	padding-bottom: 0.3125rem;
	padding-top: 0.4rem;
}
.mega-nav-event-block a.mega-nav-event-block__learn-more {
	background: rgba(0, 0, 0, 0) url(/wp-content/themes/bc-theme/dist/img/icons/chevron-right-red.svg) no-repeat scroll 100% 50% / auto 0.9375rem;
	color: #E7002D;
	font-family: fonts.$text-medium;
	font-size: 1.125rem;
	padding-right: 1.5625rem;
}
@media screen and (min-width: 992px) {
	.mega-nav-event-block a.mega-nav-event-block__learn-more:hover {
		background-image: url(/wp-content/themes/bc-theme/dist/img/icons/chevron-right-red-hover.svg);
		color: #bc0026;
	}
}


.text-box .table-callout {
	padding-bottom: 1.5625rem;
}

@media screen and (min-width: 992px) {
	.text-box .table-callout {
		padding-bottom: 3.125rem;
	}
}
.text-box .table-callout__header {
	max-width: 90em;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	padding-bottom: 2.5rem;
}

.text-box .table-callout__header::after {
	clear: both;
	content: "";
	display: block;
}

@media screen and (min-width: 992px) {
	.text-box .table-callout__header {
		padding-bottom: 3.8125rem;
	}
}

@media screen and (min-width: 992px) {
	.text-box .table-callout__title {
		float: left;
		display: block;
		margin-right: 1.0101%;
		width: 59.59596%;
	}

	.text-box .table-callout__title:last-child {
		margin-right: 0;
	}
}
.text-box .table-callout__title h2 {
	font-size: 2.25rem;
	line-height: 2.25rem;
	letter-spacing: -0.03125rem;
	font-family: fonts.$disp-bold;
	color: #1f2228;
}

@media screen and (min-width: 992px) {
	.text-box .table-callout__title h2 {
		font-size: 3.375rem;
		line-height: 3.75rem;
		letter-spacing: -0.0625rem;
	}
}
.text-box .table-callout__title h3 {
	font-size: 1.5rem;
	line-height: 1.5rem;
	letter-spacing: -0.03125rem;
	font-family: fonts.$disp-bold;
	color: #1f2228;
}

@media screen and (min-width: 992px) {
	.text-box .table-callout__title h3 {
		font-size: 2.25rem;
		line-height: 3rem;
		letter-spacing: -0.0625rem;
	}
}
.text-box .table-callout__note {
	color: #797C7E;
	font-size: 0.875rem;
	padding-top: 0.875rem;
}

@media screen and (min-width: 992px) {
	.text-box .table-callout__note {
		float: left;
		display: block;
		margin-right: 1.0101%;
		width: 39.39394%;
		padding-top: 2.1875rem;
		text-align: right;
	}

	.text-box .table-callout__note:last-child {
		margin-right: 0;
	}
}
.text-box .table-callout__table {
	width: 100%;
	border-collapse: collapse;
	border-top: none;
	border-left: none;
	border-right: none;
	font-family: fonts.$disp-bold;
	margin-bottom: 1.5625rem;
}

@media screen and (min-width: 992px) {
	.text-box .table-callout__table {
		margin-bottom: 3.125rem;
	}
}
.text-box .table-callout__table thead th {
	font-size: 0.75rem;
	letter-spacing: 0.0625rem;
	text-transform: uppercase;
	text-align: left;
	border-bottom: 0.1875rem #1f2228 solid;
	padding-bottom: 0.9375rem;
	vertical-align: bottom;
	border-top: none;
	border-left: none;
	border-right: none;
}

@media screen and (min-width: 992px) {
	.text-box .table-callout__table thead th {
		padding-bottom: 1.5625rem;
	}
}
.text-box .table-callout__table tbody:before {
	content: "-";
	display: block;
	line-height: 2.375rem;
	color: transparent;
}

@media screen and (min-width: 992px) {
	.text-box .table-callout__table tbody:before {
		line-height: 1.8125rem;
	}
}
.text-box .table-callout__table tbody td {
	max-width: 90em;
	margin-left: auto;
	margin-right: auto;
	font-size: .5rem;
	padding: 1.375rem 0;
	letter-spacing: -0.03125rem;
	border-bottom: solid 1px #EDF0F0;
	border-top:none;
	text-align: left;
}

.text-box .table-callout__table tbody tr {
	border-bottom: 0.125em #edf0f0 solid;
}

.text-box .table-callout__table tbody td p {
	font-family: fonts.$disp-bold;
	font-size: 1.15rem;
}

.text-box .table-callout__table tbody td p:nth-child(n + 2){
	text-align: right;
}

.text-box .table-callout__table tbody td::after {
	clear: both;
	content: "";
	display: block;
}

@media screen and (min-width: 992px) {
	.text-box .table-callout__table tbody td {
		font-size: 1.5rem;
		border-left: none;
		border-right: none;
		font-family: fonts.$disp-bold;
		padding: 1.71875rem 0;
	}
}
.text-box .table-callout__table tbody td span {
	display: block;
	font-size: 0.6875rem;
	color: #797C7E;
	padding-top: 0.3125rem;
}

@media screen and (min-width: 992px) {
	.text-box .table-callout__table tbody td span {
		font-size: 0.875rem;
	}
}
.text-box .table-callout__table--2col {
	table-layout: fixed;
}

.text-box .table-callout__table--2col thead th:last-child, .text-box .table-callout__table--2col tbody td:last-child {
	text-align: right;
	width: 35%;
}

@media screen and (min-width: 992px) {
	.text-box .table-callout__table--2col thead th:last-child, .text-box .table-callout__table--2col tbody td:last-child {
		text-align: left;
		width: 15%;
	}
}
.text-box .table-callout__table--3col {
	table-layout: fixed;
}

.text-box .table-callout__table--3col thead th:nth-child(n+2), .text-box .table-callout__table--3col tbody td:nth-child(n+2) {
	width: 28%;
	text-align: right;
	padding-left: 0.4375rem;
}

@media screen and (min-width: 992px) {
	.text-box .table-callout__table--3col thead th:nth-child(n+2), .text-box .table-callout__table--3col tbody td:nth-child(n+2) {
		text-align: left;
		width: 16%;
	}
}
.text-box .table-callout__table--4col {
	table-layout: fixed;
}

.text-box .table-callout__table--4col thead th:nth-child(n+2), .text-box .table-callout__table--4col tbody td:nth-child(n+2) {
	width: 20%;
	text-align: right;
	padding-left: 0.4375rem;
}

@media screen and (min-width: 992px) {
	.text-box .table-callout__table--4col thead th:nth-child(n+2), .text-box .table-callout__table--4col tbody td:nth-child(n+2) {
		text-align: right;
		width: 25% !important;
		padding-left: 1.75rem !important;
	}

	.text-box .table-callout__table--4col thead th:nth-child(3), .text-box .table-callout__table--4col tbody td:nth-child(3) {
		width: 20% !important;
		padding-left: 1rem !important;
	}

	.text-box .cim-header{
		margin-bottom: .2em !important;
	}

}

/* blog article and landing */

.section-title__blog {
	font-size: 2.25em;
	line-height: 1em;
	letter-spacing: -0.01389em;
	font-family: fonts.$disp-bold;
	color: #1f2228;
}

.section-title__blog-secondary {
	font-family: fonts.$disp-bold;
	padding-bottom: 1.27778em;
	color: #E7002D;
	font-size: unit.rem-calc(24.5, 13);
	margin-top: 0;
}

.events-feed__detail--location--blog {
	background-image: url(../img/icons/campus-attribute-ground--blog.svg);
	background-size: 1rem;
	background-position: 0.125rem;
}

.events-feed__detail--byline--blog {
	background-image: url(../img/icons/person-icon.svg);
	background-size: 1.25rem;
}

.blog__footer {
	padding-top: 1.8125em;

}

.blog__footer-bio {
	width: 79%;
	display: inline-block;
}

.blog__footer-image {
	display: inline-block;
	float: left;
	width: auto;
	max-width: 15%;
	max-height: 200px;
	border-radius: 50%;
	margin-right: 25px;
}

p.blog__cta {
	font-family: fonts.$disp-bold !important;
	color: #1f2228;
}

@media screen and (min-width: 992px) {
	.section-title__blog {
		font-size: 3.375em;
		line-height: 1.11111em;
		letter-spacing: -0.01852em;
	}
}

@media screen and (max-width: 650px) {
	.blog__footer-image {
		display: none;
	}

	.blog__footer-bio {
		width: 100%;
		display: block;
	}
}

/* fixes for scholarship sliders */
.scholarship-table__scholarship-dropdown .row {
	font-size: 16px;

	@media screen and (min-width:992px) {
		font-size: 14px;
	}

	@media screen and (min-width:1380px) {
		font-size: 16px;
	}

	@media screen and (min-width:1680px) {
		font-size: 18px;
	}

	@media screen and (min-width:2000px) {
		font-size: 20px;
	}
}

.scholarship-table__table-row[aria-expanded="false"] .scholarship-table__scholarship-dropdown {
	display: none;
}

.scholarship-table__table-row[aria-expanded="true"] .scholarship-table__scholarship-dropdown {
	display: block;
}

@media screen and (min-width: 992px) {
	.scholarship-table__scholarship-dropdown {
		padding: 2.375rem 2.5rem;
		background: #f5f7f7;
	}
}

.scholarship-table__scholarship-name svg {
	transform-origin: center;
}

.scholarship-table__table-row[aria-expanded="true"] .scholarship-table__scholarship-name svg {
	transform: rotateX(-180deg);
}

.scholarship-table__scholarship-name {
	appearance: none;
	background: transparent;
	border: none;
	min-width: 100%;
	text-align: left;
	z-index: 5;
}

.scholarship-table__table-row:before {
	cursor: pointer;
	z-index: -5;
}
.scholarship-table__scholarship-name {
    font-size: 1.25rem;
    line-height: 1.875rem;
    cursor: pointer;
    padding: .9375rem 1.875rem .9375rem 0;
    transition: all .45s ease;
    font-family: fonts.$text-medium;
}

@media screen and (min-width: 992px) {
    .scholarship-table__scholarship-name {
        font-family: fonts.$disp-bold;
		//font-weight: fonts.$font-weight-bold;
        font-size: 1.5rem;
        line-height: 2.25rem;
        padding: 1.5625rem 1.5625rem 1.5rem 0
    }
}

.scholarship-table__scholarship-name:hover {
    color: #e7002e
}

.scholarship-table__title {
	font-weight: fonts.$font-weight-bold;
}

.scholarship-table__desc {
	font-weight: fonts.$font-weight-normal;
}

.scholarship-table__scholarship-dropdown p,
.scholarship-table__scholarship-dropdown ul {
    font-size: 1.25rem !important;
    margin-bottom: 1.25rem;
    font-family: fonts.$text-regular;

	li {
		margin-bottom: 0;
	}
}

@media screen and (min-width: 992px) {
    .scholarship-table__scholarship-dropdown p,
	.scholarship-table__scholarship-dropdown ul {
        font-size: 1.5rem !important;
        margin-bottom: 1.875rem
    }
}

.scholarship-table__scholarship-dropdown strong {
    font-family: fonts.$disp-bold;
	font-weight: fonts.$font-weight-bold;
    //letter-spacing: .0625rem
}

.scholarship-table__scholarship-dropdown .link-download {
    font-size: 1rem;
}

/* transfer students & credits */

.transfer-student__span {
	position: relative;
}

.transfer-student__be{
	display: flex;
	margin-bottom: 30px;
}

.transfer-student__bc-logo {
	display: flex;
	height: 33px;
}

.transfer-student__tiles {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding-top: 143px;
	padding-bottom: 80px;
}

.transfer-student__tile {
	position: relative;
	width: 47.5%;
	height: 410px;
	margin-bottom: 60px;
}

.transfer-student__tile:hover{
	transition: all .5s ease;
	box-shadow: 0 12px 20px rgba(0, 0, 0, 0.12);
}

.transfer-student__tile-title {
	position: absolute;
	bottom: 0;
	width: 80%;
	background-color: black;
	margin-bottom: 50px;
}

.transfer-student__tile-wrap {
	height: 100%;
	width: 100%;
	object-fit: cover;
	background-repeat: no-repeat;
	background-size: cover;
}

h3.transfer-student__tile-text{
	color: #ffffff;
	padding: 16px 0 15px 30px;
	font-family: fonts.$disp-bold;
}

@media screen and (max-width: 992px) {
	.transfer-student__tiles {
		flex-direction: column;
		align-items: center;
	}

	.transfer-student__tile {
		width: 90%;
	}

	.transfer-student__tile-wrap {
		background-size: cover;
		background-position: center;
	}

	.transfer-student__bc-logo {
		height: 20px;
	}

}

@media screen and (max-width: 600px) {
	.transfer-student__be {
		display: none;
	}
}

@media screen and (max-width: 700px) {
	h3.transfer-student__tile-text {
		font-size: 24px;
	}
}

@media screen and (min-width: 769px){
	.block-grid__grid-item-transfer{
		width: 50% !important;
		float: right !important;
	}
}

/* transfer application checklist */

.application-checklist__steps{
	margin-top: 56px;
}

.application-checklist__step{
	display: flex;
	flex-direction: row;
	padding-bottom: 100px;
}

.application-checklist__step-number{
	width: 15%;
	margin-top: auto;
	margin-bottom: auto;
	padding-right: 50px;
}

.application-checklist__step-copy{
	padding-left: 60px;
	border-left: 2px solid #e5e5ea;
	padding-top: 1rem;
	width: 85%;
}

@media (max-width: 800px) {
	.application-checklist__steps{
		padding-top: 44px;
	}

	.application-checklist__step{
		flex-direction: column;
	}

	.application-checklist__step-number{
		padding-right: 0;
		margin: 0 auto;
		width: unset;
	}

	.application-checklist__step-copy{
		border-left: none;
		width: unset;
		text-align: center;
		padding: 0;
	}
}

/* transfer landing */

@media screen and (min-width: 769px){
	.block-grid__grid-item-transfer--landing{
		width: 47.5% !important;
		padding: 0;
		margin-bottom: 6rem !important;
	}
}

.transfer-callout__tile {
	position: relative;
	width: 100%;
	height: 7.75rem;
}

.transfer-callout__tile-text {
	font-size: 1.8em;
	line-height: 1.33333em;
	letter-spacing: -0.01389em;
	color: #ffffff;
	font-family: fonts.$disp-bold;
}

.transfer-callout__tile-wrap {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 75%;
	width: 100%;
	object-fit: cover;
	background-repeat: no-repeat;
	background-size: cover;
	transition: all 0.5s ease;
}

.transfer-callout__tile-wrap:hover {
	box-shadow: 0 1.3125em 1.25em 0 rgba(0,0,0,0.12);
}

.transfer-landing {
	padding-top: 18.3125rem !important;
}

.transfer-landing__page-header{
	background-image: url('http://baker.edu/media/transfer-students/transfer-landing__hero-mobile2@2x.png');
	min-height: 522px !important;
}

.btn-cta-round__transfer-landing {
	background-color: #fff;
	color: #E7002D;
	margin-top: 1rem;
	margin-bottom: 2rem;
	padding: 0 3.5rem;
}

.transfer-landing-overlapped {
	position: absolute;
	width: 90%;
	top: 111%;
	left: 50%;
	transform: translate(-50%,-50%);
	z-index:1000;
	margin: 0 auto;
	background-color: white;
	box-shadow: 0 -1.6875em 4.25em 0 rgba(0,0,0,0.12);
	padding-right: 1.5rem;
}


.transfer-landing-overlapped__wrap {
	padding-top: 1.5rem;
}

.transfer-landing-overlapped__copy {
	font-size: 1rem !important;
}

.transfer-landing-overlapped__copy-secondary {
	font-family: "fonts.$disp-regular" !important;
	font-size: 1rem !important;
	color: #505258;
}

.transfer-landing-overlapped__header-mobile {
	display: none;
}

.content-card__title-transfer--landing{
	color: #000000;
	font-size: 1.8rem;
	font-family: fonts.$disp-bold;
	font-weight: 700;
	line-height: 60px;
	letter-spacing: -1px;
}

.block-grid--landing {
	padding-top: 3rem;
}

.section-title--landing {
	padding-bottom: 1.75rem;
}

@media screen and (max-width: 500px) {
	.transfer-landing-overlapped__copy-secondary {
		font-family: "fonts.$disp-regular" !important;
		font-size: 1rem !important;
		color: #505258;
	}
}

@media screen and (min-width: 500px) {
	.transfer-landing {
		padding-top: 15.5rem !important;
	}

	.transfer-callout__tile {
		margin-bottom: 1rem;
	}

	.transfer-landing__page-header {
		min-height: 563px !important;
		background-image: url('http://baker.edu/media/transfer-students/transfer-landing__hero-2@2x-test.jpg');
	}
}

@media screen and (min-width: 625px) {
	.transfer-landing-overlapped {
		width: 100%;
	}
}


@media screen and (min-width: 769px) {

	.transfer-landing {
		padding-top: 20rem !important;
	}

	.transfer-callout__tile {
		margin-bottom: 2rem;
	}

	.transfer-callout__tile-text {
		font-size: 2.25em;
	}

	.transfer-callout__tile-wrap {
		height: 100%;
		width: 100%;
	}


	.transfer-landing--right{
		padding-right: 7rem;
	}

	.btn-cta-round__transfer-landing {
		background: #fff;
		color: #E7002D;
		margin-bottom: 3rem;
	}

	.transfer-landing-overlapped {
		width: 100%;
		top: 122%;
	}


	.transfer-landing-overlapped__wrap {
		padding-top: 4.34rem;
		padding-left: 4.25rem;
	}


	.transfer-landing-overlapped__copy-secondary {
		font-family: fonts.$disp-bold !important;
	}

	.content-card__title-transfer--landing{
		color: #000000;
		font-size: 3.375rem;
		font-family: fonts.$disp-bold;
		font-weight: 700;
		line-height: 60px;
		letter-spacing: -1px;
		padding-top: 1rem;
		padding-bottom: 1.25rem;
	}

}

@media screen and (min-width: 992px) {


	.transfer-landing-overlapped__header-mobile {
		display: block;
	}

	.transfer-landing-overlapped__header {
		padding-bottom: 2.5625rem;
	}

	.transfer-landing-overlapped__copy {
		padding-right: 7.5rem;
		font-size: 2.25em !important;
	}

	.block-grid--landing {
		padding-top: 5.9rem;
	}

	.section-title--landing{
		.padding-bottom: 3.4rem;
	}
}

@media screen and (min-width: 992px) {
	.transfer-landing{
		padding-top: 33.3125rem !important;
	}
}

/* home slider */

.home-slider {
	background-size: cover;
	color: white;
	min-height: 1vh;
	position: relative;
	overflow: hidden;
	min-height: 1vh
}

@media screen and (min-width: 992px) {
	.home-slider {
	}
}

.home-slider__slide {
	display: none;
	min-height: 1vh;
	position: relative;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat
}

.home-slider__slide--half-left {
	width: 50%;
	min-height: 50vh;
	float: left;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center
}

@media screen and (min-width: 992px) {
	.home-slider__slide--half-left {
		min-height:712px
	}
}

.home-slider__slide--half-right {
	positon: relative;
	float: left;
	background-color: #E7002D;
	width: 50%;
	min-height: 50vh
}

@media screen and (min-width: 992px) {
	.home-slider__slide--half-right {
		min-height:712px
	}
}

.home-slider__slide--half-right--content-wrap {
	min-height: 25vh;
	max-width: 60%;
	top: 15%;
	left: 41%;
	position: absolute;
	-ms-transform: translate(20%, 35%);
	transform: translate(20%, 35%)
}

@media screen and (min-width: 992px) {
	.home-slider__slide--half-right--content-wrap {
		min-height:356px
	}
}

.home-slider__slide--logo {
	width: 87px;
	height: 87px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	right: 0;
	top: 5%
}

.home-slider .slick-initlized {
	display: block
}

.home-slider .slick-arrow {
	font-size: 25px;
	background-repeat: no-repeat;
	top: 50%
}

@media screen and (min-width: 769px) {
	.home-slider .slick-arrow {
		top:50%
	}
}

@media screen and (min-width: 992px) {
	.home-slider .slick-arrow {
		top:50%
	}
}

.home-slider .slick-next {
	z-index: 9999;
	height: 46px;
	width: 32px;
	right: 25px
}

.home-slider .slick-next:before {
	color: #fff;
	content: " ";
	font-size: 36px
}

.home-slider .slick-prev {
	left: 25px;
	height: 46px;
	width: 32px;
	background-repeat: no-repeat;
	z-index: 9999
}

@media screen and (min-width: 992px) {
	.home-slider .slick-prev {
		left:3%
	}
}

@media screen and (min-width: 1400px) {
	.home-slider .slick-prev {
		left:1%
	}
}

.home-slider .slick-prev:before {
	color: #fff;
	content: " ";
	font-size: 36px
}

.btn-cta-round__home-slide {
	color: #fff;
	border: solid 3px #fff;
	position: absolute;
	background: transparent
}

.btn-cta-round__home-slide:hover {
	background: #fff;
	color: #E7002D;
	cursor: pointer
}

.btn-cta-round__home-slide--call-out {
	bottom: -21px;
	left: 8px
}

@media screen and (max-width: 767px) {
	.splash-slider__mobile {
		min-height:50vh
	}
}

@media screen and (min-width: 992px) {
	.splash-slider {
		min-height:712px
	}
}

.splash-slider .home-slider__slide {
	display: block !important
}

/* custom slick */

.slick-dots {
	list-style-type: circle;
	bottom: 2.5rem;
}

.slick-dots li {
	height: 20px;
	width: 20px;
	padding: 0 1.5rem;
}

.slick-dots li button:before{
	color: #d8d8d8;
	opacity: 1
}

.slick-dots li.slick-active button:before{
	color: #E7002D;
	opacity: 1;
}

.slick-dots li button:before {
	font-size: .75rem;
}

/* events/blog landing */

.events-feed__detail--date {
	background-image: url(../img/icons/date.svg);
	background-size: 1.25rem;
}

.sidebar__widget-title {
	font-family: fonts.$disp-bold;
}


.contact-form__field-wrap #LocationID2{
	-moz-appearance: none;
	appearance: none;
	-webkit-appearance: none;
	background: #fff url(../img/icons/chevron-down-red.svg) no-repeat 98% 50%;
	border-radius: 0;
	width: 100%;
	font-size: 1.25em;
	padding: 0 0.9em;
	height: 3em;
	border: solid 0.05em #D2D2D2;
	line-height: 3em;
	color: #1f2228;
	font-family: fonts.$text-regular;
}

.fullwidth-video-cta__bg-video-wrap-cover {
	background: url(../../assets/images/index/preload-cover-new__compressed2.png) 50% no-repeat;
	background-size:cover;
}

@media screen and (min-width: 992px) {
	.fullwidth-video-cta__wrap {
		padding-top: 10.0625rem !important;
	}

	.fullwidth-video-cta {
		height: 41.75rem !important;
	}

	.fullwidth-video-cta__bg-video-wrap-cover-fallback {
		display:none;
	}
}

@media screen and (max-width: 700px) {
	.fullwidth-video-cta__bg-video-wrap-cover-fallback {
		background: url(../../assets/images/index/preload-cover-new__compressed3.png) 50% no-repeat;
		background-size:cover;
		z-index: 10;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
}

.mobile-button-links .main-header__mobile-footer-link {
	display: block;
	height: 4.5625em;
	line-height: 4.5625em;
	width: calc(85%/3);
	float: left;
	color: white;
	font-family: fonts.$disp-medium;
}

.mobile-button-links .main-header__mobile-footer-link.Phone {
	width: 15%;
	padding-top: 3px;
}

.main-header__mobile-footer-links.main-header__mobile-footer-links--active {
	display: block;
}

.mobile-button-links {
	position: fixed;
	right: 0;
	top: 0;
	left: 0;
	transition: none;
	height: 4.5625em;
	text-align: center;
	background: #E7002D;
	display: none;
	z-index: 900;
}

.themed--cim .mobile-button-links {
	background:#df3742;
}

.themed--adi .mobile-button-links {
	background: #1E68AA;
}


.callout-featured-student {
	color: #fff;
	margin-bottom: 5.8125em;
}


.font-Regular {
	font-family: fonts.$disp-regular, sans-serif !important;
	font-size: 16px !important;
	font-weight: 700 !important;
	letter-spacing: normal !important;
}

.mega-nav__parent-link,
.mega-nav__link-item {
	font-size: unit.rem-calc(14, 13);

	@media screen and (min-width: 1680px) {
		font-size: unit.rem-calc(16, 13);
	}
}

.sidebar-left-nav__link-item-active .sidebar-left-nav__link {background: #E7002D;border-top-color: transparent;color: #FFFFFF;}
.page-id-2159 .loop.page__loop{padding: 0 1.25em;}
.page-id-2159 .loop.page__loop .wp-block-table table tr td:nth-child(2),
.page-id-2159 .loop.page__loop .wp-block-table table tr td:nth-child(2) p,
.page-id-2159 .loop.page__loop .wp-block-table table tr td:nth-child(2) li{font-family: fonts.$body; font-size: unit.rem-calc(16, 13); }
.course-lists .course-lists__list .row .course-lists__list-desc{font-family: fonts.$disp-regular, sans-serif !important; margin-bottom: 1rem;}
.page-id-2213 p {
	font-family: fonts.$disp-regular;

	strong {
		font-family: fonts.$disp-bold;
		font-weight: normal;
		display: block;
	}
}
.myth-sec .wp-block-cnpblock-subtitle {text-transform: uppercase;font-size: .875em;line-height: 1.15; color: colors.$red-dark !important;}
.myth-sec .wp-block-cnpblock-subtitle + h3 {color: #000;font-size: 1.3125em;line-height: 1.1;font-weight: 400;}
.myth-sec .wp-block-column .wp-block-group__inner-container p {font-family: "NeueHaasGroteskText-Regular";font-size: .875em; }

.glide__slides {
	@media screen and (max-width: 992px) {
		margin-left: -15px;		
	}
}

.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
  margin-top: 10px;
}

.wp-block-gallery-container {
  position: relative;
}
.wp-block-gallery-container .blocks-gallery-grid {
  margin-bottom: 0;
}
.wp-block-gallery-container .blocks-gallery-item {
  margin-bottom: 0;
  padding-left: 0;
}
.wp-block-gallery-container .blocks-gallery-item figure,
.wp-block-gallery-container .blocks-gallery-item img {
  height: 100%;
}
.wp-block-gallery-container .blocks-gallery-item img {
  object-fit: cover;
  width: 100%;
}
.wp-block-gallery-container button {
  appearance: none;
  cursor: pointer;
}
.wp-block-gallery-container .glide__arrows button {
  align-items: center;
  background-color: #E7002D;
  border: none;
  border-radius: 100%;
  color: #ffffff;
  display: flex;
  font-size: 2.25rem;
  height: 1em;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1em;
}
.wp-block-gallery-container .glide__arrows button:hover {
  background-color: #B40024;
}
.wp-block-gallery-container .glide__arrows button svg {
  font-size: 0.5em;
}
.wp-block-gallery-container .glide__arrows button:first-child {
  left: 1.875rem;
}
.wp-block-gallery-container .glide__arrows button:last-child {
  right: 1.875rem;
}
.wp-block-gallery-container .glide__bullets {
  bottom: 1.875rem;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  width: 100%;
}
.wp-block-gallery-container .glide__bullet {
  background-clip: content-box;
  background-color: transparent;
  border: none;
  box-sizing: content-box;
  display: flex;
  font-size: 1em;
  height: 1em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  padding: 0.5em;
  width: 1em;
}
.wp-block-gallery-container .glide__bullet:hover .glide__bullet-inside, .wp-block-gallery-container .glide__bullet.glide__bullet--active .glide__bullet-inside {
  background-color: #F5F7F7;
}

.is-style-pair.myth_slider h3 {
	font-family: fonts.$disp-medium;
	font-weight: normal;
}

@media( max-width: 992px ){
  .custom-table-style { background: #eee; display: flex; grid-column-gap: inherit; padding-left: 0 !important; padding-right: 0 !important;}
  .custom-table-style > div {width: 50%; border: 1px solid #b8b8b8; padding: 5px;}
}

.news-events-grid .news-events-grid__item:nth-child(3) .news-events-grid__event-card-location { font-family: fonts.$disp-medium; color:#505258; }
body .news-events-grid .l-flex-half:nth-child(5) .news-events-grid__event-card-location { color: #fff; }
//.zig-zag-sec h2 {font-size: 50px;}
.zig-zag-sec h2 > span { display:inline-block; }
.zig-zag-sec h2 > span > span {color: #ef0030;}
.zig-zag-sec h2 > span > img {top: -1em;margin-left: 0.125em; font-size: min( 20px, calc(5.2290076336px + 1.272264631vw));height: 1em;width:1em;position: relative;}
.align-box-wrapper .wp-block-buttons {position:absolute;bottom:25px;left:1.5rem;}
.align-box-wrapper .wp-block-columns > .wp-block-column {position: relative;padding-bottom: calc(2rem + 48px);}
//.fs-50 { font-size: 50px !important; }

.is-vertically-aligned-center {
	.wp-block-media-text__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

.page-id-2186 table {
	width: 100%;

	tr th:nth-child(3) {
		border-right-color: #fff;
	}
}

.is-style-matrix {
	.wp-block-columns {
		padding: 0 !important;
	}

	td {
		vertical-align: middle;
		text-align: center;
	}

	td:last-child,
	th:last-child {
		border-right: 1px solid #1F2228;
	}
}
.content .has-black-haze-background-color .wp-block-button__link.is-style-plain{
	border-color: colors.$red-dark;
	color: colors.$red-dark;
}

.has-black-haze-background-color .wp-block-cnpblock-subtitle.has-red-light-color,
.sidebar-left-nav__link-item.sidebar-left-nav__link-item--active.sidebar-left-nav__link-item--has-children .sidebar-left-nav__link-item.sidebar-left-nav__link-item--active .sidebar-left-nav_sub-nav-link,
.expandable-list__button .has-red-light-color {
	color: colors.$red-dark !important;
} 

.wp-block-cnpblock-accordion .accordion__item-content p a,
.content .has-black-haze-background-color .is-style-quick-links .wp-block-button__link,
.content .has-black-haze-background-color .wp-block-group__inner-container p a,
.content .has-black-haze-background-color .wp-block-column p.btn-angle-right,
.content .has-black-haze-background-color .wp-block-column li a,
.content .has-black-haze-background-color .wp-block-column p a {
	color: colors.$red-dark;
}

.sr-only {
	visibility: hidden;
}

.page-id-2311 .content.page__content {
    word-break: break-word;
}

.fluid-width-video-wrapper {
	position: relative;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}