@use "../settings/colors";
@use "../settings/fonts";
@use "../zurb/breakpoint";
@use "../zurb/unit";

.important-dates {

	&__list {
		margin: 20px auto;
		max-width: unit.rem-calc(1040);
		text-align: center;

		@media screen and (min-width: 1024px) {
			display: flex;
			justify-content: center;
		}
	}
	&__item {
		padding: 30px 0;
		position: relative;

		@media screen and (min-width: 1024px) {
			flex: 0 0 25%;
		}

		&::before {
			background-color: #C4C4C4;
			bottom: 0;
			content: "";
			display: block;
			height: 1px;
			left: 50%;
			position: absolute;
			transform: translateX(-50%);
			width: 131px;

			@media screen and (min-width: 1024px) {
				bottom: unset;
				height: calc(100% - 60px);
				left: 100%;
				top: 50%;
				transform: translateY(-50%);
				width: 1px;
			}
		}

		&:last-child:before {
			content: unset;
		}
	}

	&__date {
		color: colors.$red-light;
		font-family: fonts.$disp-bold;
		font-weight: fonts.$font-weight-bold;
		font-size: 30px;
		line-height: 1;
		margin-bottom: 3px;
	}

	&__note {
		color: #1F2228;
		font-family: fonts.$body;
		font-size: 14px;
		margin-bottom: 23px;
	}

	&__icons {
		display: flex;
		justify-content: center;
	}

	&__icon {
		font-family: fonts.$disp-regular;
		margin: 0 7px;
		font-size: 12px;

		img {
			display: block;
			margin: 0 auto 6px;
		}
	}
}