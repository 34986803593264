@use "sass:color";
@use "settings/colors";
@use "settings/fonts";
@use "mixins/fluid-property";
@use "typography/headings-mixins";
@use "typography/links-mixins";
@use "zurb/breakpoint";
@use "zurb/unit";

.search-results {

	&__intro {
		color: #FFFFFF;
		margin-bottom: 0;
	}

	.search-result {
		border-bottom: 1px solid colors.$iron-1;
		margin: 2em 0;
		padding-bottom: 2em;

		&__title {
			@include headings-mixins.h3();

			a {
				color: colors.$red-light;

				&:hover {
					color: colors.$red-dark;
				}
			}
		}

		&__header {
			align-items: baseline;
			color: colors.$baker-black;
			display: flex;
			flex-wrap: wrap;
		}

		&__sep {
			color: colors.$iron-1;
			margin: 0 .5em;

			&:nth-child(4) {

				@include breakpoint.mx(small only) {
					display: none;
				}
			}
		}

		&__post-type {
			color: colors.$baker-black;
			font-family: fonts.$disp-medium;
			font-size: 1em;
			font-weight: normal;
			letter-spacing: 0;
			margin: 0;

			.label {
				font-style: italic;
				letter-spacing: 0;
				text-transform: none;
			}
		}

		&__date {

		}

		&__path {
			color: colors.$baker-black;
			max-width: 25em;
			overflow: hidden;
			text-decoration: underline;
			text-overflow: ellipsis;
			white-space: nowrap;

			@include breakpoint.mx(small only) {
				width: 100%;
			}

			&:hover {
				color: colors.$red-light;
			}
		}

		&__content {
			display: flex;
			justify-content: space-between;
			margin-top: 1em;
		}

		&__image {
			margin: 0 0 1em 2em;
		}

		&__link {
		}
	}

	.highlighted {
		background-color: rgba(180, 0, 36, 0.1);
		padding-left: .25em;
		padding-right: .25em;
	}
}

.search-no-results {
	.content {
		margin-bottom: 5em;
	}
}
