@use "settings/colors";
@use "settings/fonts";
@use "zurb/unit";

.bread-crumbs--below-hero {
	display: none;
	font-family: fonts.$text-medium;
	font-weight: fonts.$font-weight-bold;
	padding: 1rem 0;

	@media (min-width: 992px) {
		display: block;
	}

	&__link-list {
		list-style: none;
        padding-left: 0 !important;
        margin-left: 0 !important;
        margin-bottom: 0.5rem !important;

		&-item {
			display: inline-block;
			
			&::after {
				content: ">";
				display: inline-block;
				font-size: .625em;
				margin: 0 .45em;
			}

			&:last-child:after {
				display: none
			}
		}
	}

	&__link, &__no-link {
		color: #E7002D;
		font-family: fonts.$text-medium;
		font-size: unit.rem-calc(13, 13);
		letter-spacing: .05em;
		text-decoration: none;

		@media screen and (min-width: 992px) {
			font-size: calc(0.925 * 13px);
			line-height: 1.03704em;
			max-width: 60%;
			padding-bottom: 0;
		}

		@media screen and (min-width:1380px) {
			font-size: calc(0.925 * 14px);
		}

		@media screen and (min-width:1680px) {
			font-size: calc(0.925 * 16px);
		}

		@media screen and (min-width:2000px) {
			font-size: calc(0.925 * 18px);
		}
	}

	&__link:hover {
		text-decoration: underline
	}

    &__link.current-page, &__no-link {
        color: colors.$black;
    }
}

.bread-crumbs--below-hero + .content > .layout-sidebar-left,
.bread-crumbs--below-hero + .content > .layout-sidebar-right{
	@media (min-width: 992px) {
		padding-top: 0
	}
}

.bread-crumbs--below-hero + .content > .page__loop > .post > .wp-block-group:first-child,
.bread-crumbs--below-hero + .content > .page__loop > .post > .wp-block-columns:first-child {
	@media (min-width: 992px) {
		margin-top: 0
	}
}

.single-career, .archive-career {
	.bread-crumbs--below-hero {
		padding-top: 0;
	}	
}