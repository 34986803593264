// Primary Palette
$baker-black: #1F2228;
$black: #000;
$red-light: #E7002D;
$red-dark: #B40024;
$baker-cim: #D9232F;
$black-haze: #F5F7F7;
$iron-1: #C9CED1;
$baker-adi: #3A62AA;
$white: #ffffff;
$abbey: #505258;
$rolling-stone: #797C7E;
$hit-gray: #A5A9AC;


// The color palette matches those colors defined in `inc\class-theme-setup.php`
$palette: (
	"baker-black": $baker-black,
	"black": $black,
	"red-light": $red-light,
	"red-dark": $red-dark,
	"baker-cim": $baker-cim,
	"black-haze": $black-haze,
	"white": $white,
	"iron-1": $iron-1,
	"baker-adi": $baker-adi,
);
