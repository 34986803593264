@use "zurb/breakpoint";
@use "settings/fonts" as fonts;

.chat {
	bottom: 1rem;
	position: fixed;
	right: 1rem;
	z-index: 1000;

	@include breakpoint.mx(small only) {
		display: none;
	}

	&__tip {
		background-color: #FFFFFF;
		border-radius: 100px;
		box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
		font-family: fonts.$text-regular, sans-serif;
		font-size: 14px;
		padding: 12px 20px;
		position: absolute;
		right: 0;
		top: 0;
		transform: translate(-25%, -100%);
		white-space: nowrap;

		&::before {
			border-color: transparent #FFFFFF transparent transparent;
			border-style: solid;
			border-width: 10px 15px 10px 0;
			bottom: 0;
			content: "";
			position: absolute;
			right: 0;
			transform: translate(-120%, 50%);
		}

		img {
			position: absolute;
			right: 0;
			top: 0;
			transform: translate(-7px, -50%);
		}
	}

	button {
		background: transparent;
		border: none;
		border-radius: 100%;
		box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 10px 25px 0 rgba(0, 0, 0, 0.2);
		cursor: pointer;
		height: 60px;
		margin: 0;
		padding: 0;
		width: 60px;
	}
}

.cx-form-inputs table tr th label {
	color: #fdfdfd !important;
	font-family: Roboto, sans-serif !important;
}