@font-face {
	font-family: NeueHaasGroteskDisp-Bold;
	font-style: normal;
	font-weight: 400;
	src: local('NeueHaasGroteskDisp-Bold'), url(../fonts/NeueHaasGroteskDisp-Bold/NeueHaasGroteskDisp-Bold.eot?#iefix) format("embedded-opentype"), url(../fonts/NeueHaasGroteskDisp-Bold/NeueHaasGroteskDisp-Bold.woff) format("woff"), url(../fonts/NeueHaasGroteskDisp-Bold/NeueHaasGroteskDisp-Bold.ttf) format("truetype"), url(../fonts/NeueHaasGroteskDisp-Bold/NeueHaasGroteskDisp-Bold.svg#NeueHaasGroteskDisp-Bold) format("svg");
	font-display: swap;
}

@font-face {
	font-family: NeueHaasGroteskTextMedium;
	font-style: normal;
	font-weight: 400;
	src: local('NeueHaasGroteskTextMedium'), url(../fonts/NeueHaasGroteskTextMedium/NeueHaasGroteskTextMedium.eot?#iefix) format("embedded-opentype"), url(../fonts/NeueHaasGroteskTextMedium/NeueHaasGroteskTextMedium.woff) format("woff"), url(../fonts/NeueHaasGroteskTextMedium/NeueHaasGroteskTextMedium.ttf) format("truetype"), url(../fonts/NeueHaasGroteskTextMedium/NeueHaasGroteskTextMedium.svg#NeueHaasGroteskTextMedium) format("svg");
	font-display: swap;
}

@font-face {
	font-family: NeueHaasGroteskDisp-Regular;
	font-style: normal;
	font-weight: 400;
	src: local('NeueHaasGroteskDisp-Regular'), url(../fonts/NeueHaasGroteskDisp-Regular/NeueHaasGroteskDisp-Regular.eot?#iefix) format("embedded-opentype"), url(../fonts/NeueHaasGroteskDisp-Regular/NeueHaasGroteskDisp-Regular.woff) format("woff"), url(../fonts/NeueHaasGroteskDisp-Regular/NeueHaasGroteskDisp-Regular.ttf) format("truetype"), url(../fonts/NeueHaasGroteskDisp-Regular/NeueHaasGroteskDisp-Regular.svg#NeueHaasGroteskDisp-Regular) format("svg");
	font-display: swap;
}

@font-face {
	font-family: NeueHaasGroteskDispMedium;
	font-style: normal;
	font-weight: 400;
	src: local('NeueHaasGroteskDispMedium'), url(../fonts/NeueHaasGroteskDispMedium/NeueHaasGroteskDispMedium.eot?#iefix) format("embedded-opentype"), url(../fonts/NeueHaasGroteskDispMedium/NeueHaasGroteskDispMedium.woff) format("woff"), url(../fonts/NeueHaasGroteskDispMedium/NeueHaasGroteskDispMedium.ttf) format("truetype"), url(../fonts/NeueHaasGroteskDispMedium/NeueHaasGroteskDispMedium.svg#NeueHaasGroteskDispMedium) format("svg");
	font-display: swap;
}

@font-face {
	font-family: NeueHaasGroteskText-Regular;
	font-style: normal;
	font-weight: 400;
	src: local('NeueHaasGroteskText-Regular'), url(../fonts/NeueHaasGroteskTextRegular/NeueHaasGroteskText-Regular.eot?#iefix) format("embedded-opentype"), url(../fonts/NeueHaasGroteskTextRegular/NeueHaasGroteskText-Regular.woff) format("woff"), url(../fonts/NeueHaasGroteskTextRegular/NeueHaasGroteskText-Regular.ttf) format("truetype"), url(../fonts/NeueHaasGroteskTextRegular/NeueHaasGroteskText-Regular.svg#NeueHaasGroteskText-Regular) format("svg");
	font-display: swap;
}

@font-face {
	font-family: NeueHaasGroteskTextBold;
	font-style: normal;
	font-weight: 400;
	src: local('NeueHaasGroteskTextBold'), url(../fonts/NeueHaasGroteskTextBold/NeueHaasGroteskTextBold.eot?#iefix) format("embedded-opentype"), url(../fonts/NeueHaasGroteskTextBold/NeueHaasGroteskTextBold.woff) format("woff"), url(../fonts/NeueHaasGroteskTextBold/NeueHaasGroteskTextBold.ttf) format("truetype"), url(../fonts/NeueHaasGroteskTextBold/NeueHaasGroteskTextBold.svg#NeueHaasGroteskTextBold) format("svg");
	font-display: swap;
}

@font-face {
	font-family: NeueHaasGroteskDispXLight;
	font-style: normal;
	font-weight: 400;
	src: local('NeueHaasGroteskDispXLight'), url(../fonts/NeueHaasGroteskDispXLight/NeueHaasGroteskDispXLight.eot?#iefix) format("embedded-opentype"), url(../fonts/NeueHaasGroteskDispXLight/NeueHaasGroteskDispXLight.woff) format("woff"), url(../fonts/NeueHaasGroteskDispXLight/NeueHaasGroteskDispXLight.ttf) format("truetype"), url(../fonts/NeueHaasGroteskDispXLight/NeueHaasGroteskDispXLight.svg#NeueHaasGroteskDispXLight) format("svg");
	font-display: swap;
}

//400 is normal, 700 is bold, 900 is black/headings
$roboto: 'Roboto', sans-serif;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-black: 900;

$body: $roboto;
$header: $roboto;
$disp-black: $roboto;
$disp-bold: $roboto;
$disp-medium: $roboto;
$disp-regular: $roboto;
$disp-light: $roboto;
$text-bold: $roboto;
$text-medium: $roboto;
$text-regular: $roboto;
$serif: "Georgia", Times, "Times New Roman", serif;
$serif-fancy: "eloquent-jf-pro", serif;
$sans: $roboto;
$sans-fancy: $roboto;