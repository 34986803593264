@use "sass:map";
@use "../settings/colors";
@use "../settings/fonts";
@use "../settings/paths";
@use "../functions/em-calc";
@use "../mixins/typographic-item";
@use "../zurb/breakpoint";

@mixin h1($settings: ()) {
	$defaults: (
		"color": colors.$baker-black,
		"font-size": (
			48,
			52,
			64,
			72,
			81,
		),
	);
	$args: map.merge($defaults, $settings);
	@include typographic-item.mx($args);
	line-height: 1.05;
}

@mixin h2($settings: ()) {
	$defaults: (
		"color": colors.$baker-black,
		"font-size": (
			28,
			34,
			34,
			44,
			52,
		),
	);
	$args: map.merge($defaults, $settings);
	@include typographic-item.mx($args);
}

@mixin h3($settings: ()) {
	$defaults: (
		"color": colors.$baker-black,
		"font-size": (
			18,
			21,
			28,
			33.6,
			38.8
		),
	);
	$args: map.merge($defaults, $settings);
	@include typographic-item.mx($args);
}

@mixin h4($settings: ()) {
	$defaults: (
		"color": colors.$baker-black,
		"font-size": (
			18,
			24,
			28.8,
			32.4,
			36,
		),
	);
	$args: map.merge($defaults, $settings);
	@include typographic-item.mx($args);
	font-family: fonts.$disp-bold;
}

@mixin h5($settings: ()) {
	$defaults: (
		"color": colors.$baker-black,
		"font-size": (
			16,
			18,
			20,
			22.5,
			25,
		)
	);
	$args: map.merge($defaults, $settings);
	@include typographic-item.mx($args);
	font-family: fonts.$disp-bold;
}

@mixin h6($settings: ()) {
	$defaults: (
		"color": colors.$baker-black,
		"font-size": (
			13,
			14,
			16,
			18,
			20,
		),
		"font-weight": 500,
	);
	$args: map.merge($defaults, $settings);
	@include typographic-item.mx($args);
	font-family: fonts.$disp-medium;
}

@mixin stat($settings: ()) {
	$defaults:(
		"color": colors.$red-light,
		"font-size": (32, 48, 64)
	);
	$args: map.merge($defaults, $settings);
	@include typographic-item.mx($args);
	font-family: fonts.$sans-fancy;
}