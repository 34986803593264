@use './settings/fonts' as fonts;
@use './settings/colors' as colors;

.menu__interior-subnav--wrapper {
    max-width: 75% !important;
}

.menu__interior-subnav--custom {

    margin-left: 0 !important;
    padding-left: 0 !important;

    li, .sub-menu li {
        display: block;
        margin-bottom: 0 !important;
    }

    .current_page_item, .current-menu-item {
        a, a:link, a:active {
            color: colors.$black;
        }
        a:hover, a:focus {
            color: colors.$red-dark;
        }
    }

    a {
        font-weight: bold;
        font-size: 1.3rem;
        padding: 0.5rem 1rem 0.5rem 1rem;
        margin-top: 0;
        margin-bottom: 0;
        border-bottom: 3px solid colors.$black;
        display: block;
    }

    .sub-menu {
        padding-left: 0 !important;
        margin-left: 0 !important;
        padding-top:0;

        li {
            padding-left: 0;
            display: block;
        }
        a {
            font-weight: 500;
            font-size: 1.1rem;
            border-bottom: 0;
            display: block;
            background-color: colors.$black-haze;
            padding-left: 2rem;
        }
    }
}