@use "sass:math";
@use "zurb/unit" as unit;
@use "settings/fonts" as fonts;
@use "settings/numbers";
@use "zurb/breakpoint";

body.page-template-affiliated,
body.page-template-campus {
    overflow: initial;

    .photo-slider {
        margin-left: auto;
        margin-right: auto;
        width: 100%;

		max-width: unit.rem-calc(1150, 13);
		grid-column: 1/-1;

		@media screen and (min-width: 1380px) {
			max-width: unit.rem-calc( 1240px, 13 );
		}

		@media screen and (min-width: 1680px) {
			max-width: unit.rem-calc( 1416px, 13 );
		}

        .slick-dots {
            margin-bottom: 0px !important;
            bottom: 0.625em;
            z-index: 2;
        
            li {
                width: 0.5em;
            }
        
            button {
                &:before {
                    background: #FFFFFF;
                    border: solid 1px #FFFFFF;
                    border-bottom-left-radius: 50%;
                    border-bottom-right-radius: 50%;
                    border-top-left-radius: 50%;
                    border-top-right-radius: 50%;
                    box-sizing: border-box;
                    content: "";
                    height: 13px;
                    opacity: 1;
                    padding: 0;
                    width: 13px;
                }
                &:hover {
                    &:before {
                        background: 0;
                    }
                }
            }

            .slick-active {
                button {
                    &:before {
                        background: 0;
                    }
                }
            }
        }

        .slick-list {
            padding: 0px;
            overflow: hidden;
        }

        .slick-slide > div {
            height: 100%;
        }

        .slick-arrow {
            background: url(../img/icons/slider-left.png) 50% no-repeat;
            background-size: 3em 3em;
            display: none;
            font-size: 1em;
            height: 4.375em;
            width: 4.375em;
            z-index: 3;

            &:before {
                display: none;
            }
        }

        .slick-prev {
            left: 0.9375em;
        }

        .slick-next {
            background-image: url(../img/icons/slider-right.png);
            right: 0.9375em;
        }

        &__slick {
            position: relative;

            &:before {
                background: #000000;
                bottom: 0;
                content: "";
                display: block;
                left: 0;
                opacity: 0.3;
                position: absolute;
                right: 0;
                top: 0;
                transition: all 0.45s ease;
                z-index: 1;
            }
            
            &:hover {
                &:before {
                    opacity: 0;
                }
            }

            &--single {
                &:before {
                    opacity: 0;
                }
            }

            &.slick-dotted {
                margin: 0;
            }
        }

        &__slide {
            background: 50% no-repeat;
            background-size: cover;
            height: 100%;
        }
    }

    @media screen and (max-width: 769px) {
        
        .photo-slider .slick-dots {
            bottom: 1.4375em;
        }
        .photo-slider .slick-dots li {
            width: 1.25em;
        }
        .photo-slider .slick-dots button:before {
            height: 0.8125rem;
            width: 0.8125rem;
        }
        .photo-slider__slick.slick-dotted {
            margin: 0;
        }
        .photo-slider .slick-track {
            height: 46.4375em;
        }
        .photo-slider .slick-arrow {
            display: block;
        }
    }

    .wp-block-group.is-style-quick-links {
        border-top: 0.3076923077rem solid #1F2228;

        @media screen and (min-width: 992px) {
            max-width: 340px;
            margin-left: auto;   

            h3 {
                font-size: unit.rem-calc(24,13) !important;
                
                &:not(:first-child) {
                    margin-top: unit.rem-calc(48, 13);
                }
            }
        }

        a {
            font-size: unit.rem-calc(18, 13);
        }

        p {
            font-family: sans-serif;
        }

        h3 {
            border-top: none;
        }
    }

    .page-header {
        align-items: center;
        display: flex;
        background-size: cover;
        margin-bottom: 0;

        @media screen and (min-width: 992px) {            
            min-height: 335px;
        }

        @media screen and (min-width: 1680px) {            
            min-height: 370px;
        }
        
        &:after {
            top: 0;
            background-color: rgba(0, 0, 0, .5);
            height: 100%;
            position: absolute;
            width: 100%;
            z-index: 10
        }

        &__content-wrap {
            padding-bottom: 1.25em;
            padding-top: 1.25em;
            position: relative;
            transform: none;
            z-index: 20;
        }

        &__pre-title {
            display: block;
            margin-bottom: 4px;
            margin-top: 0;
            position: static;
            top: 0;

            @media screen and (max-width: 991px) {
                display: none;
            }

            @media screen and (min-width: 992px) {
                font-size: unit.rem-calc(24, 13);
                margin-bottom: 30px;
                margin-top: -30px;
                top: 0;
                position: unset;
            }
        }

        &__title {

            @media screen and (min-width: 1380px) {
                font-size: unit.rem-calc(71, 13);
            }


            @media screen and (min-width: 1680px) {
                font-size: unit.rem-calc(81, 13);
            }
        }

        &__campus-attributes {
            display: flex;
            margin: 16px 0 10px;
        }

        &__campus-attribute {
            appearance: none;
            background: transparent;
            border: none;
            color: #fff;
            align-items: center;
            display: flex;
            flex-direction: column;
            margin-right: 45px;
            font-family: sans-serif;

            @media screen and (min-width: 1680px) {             
                font-size: unit.rem-calc(16, 13);   
            }

            img {
                margin-bottom: 10px;
            }

            svg {
                display: inline-block;
                margin-bottom: 6px;
                height: 65px;
                width: 65px;

                @media screen and (min-width: 1680px) {  
                    height: 80px;
                    width: 80px;
                }
            }

            .active {
                display: block;
                
                @media screen and (max-width: 768px) {
                    display:none;
                }
            }

            .inactive {
                display: none
            }

            &.page-header__campus-attribute-inactive {
                .active {
                    display: none
                }
                .active-mobile {
                    display: none
                }
            }

            &.page-header__campus-attribute-inactive {
                color: #c70e20;

                .inactive {
                    display: block;

                    @media screen and (max-width: 768px) {
                        display:none;
                    }
                }

                .inactive-mobile {
                    display: none;

                    @media screen and (max-width: 768px) {
                        display:block;
                    }
                }
            }

            .active-mobile {
                display: none;

                @media screen and (max-width: 768px) {
                    display: block;
                }
            }

            .inactive-mobile {
                display: none
            }
        }
    }

    .hentry {
        p {
            font-size: unit.rem-calc(15, 13);         

            @media screen and (max-width: 992px) {
                font-size: unit.rem-calc(18, 13);                
            }

            @media screen and (min-width: 1680px) {
                font-size: unit.rem-calc(18, 13);                
            }
        }
    }

    .is-upcoming-events,
    .is-latest-news-list {
        grid-column: 1/-1 !important;
        overflow: hidden;

        @media screen and (max-width: 1220px) {
            padding-left: 30px;
            padding-right: 30px;
        }

        .row {
            padding: 0 !important;
        }

        .tribe-compatibility-container {
            margin-left: auto;
            margin-right: auto;

            max-width: unit.rem-calc(1150, 13);

            @media screen and (min-width: 1380px) {
                max-width: unit.rem-calc( 1240px, 13 );
            }

            @media screen and (min-width: 1680px) {
                max-width: unit.rem-calc( 1416px, 13 );
            }
        }

        .tribe-events-widget-events-list__event-date-tag-month {text-transform: inherit !important;line-height: 1.2em !important;}
        h3.tribe-events-widget-events-list__event-title {font-family: fonts.$disp-bold !important;}

        .tribe-events-widget-events-list__event-venue, 
        .tribe-events-widget-events-list__event-datetime { 
            font-size: 16px !important; 
            font-family: fonts.$disp-medium !important; 
            color: #505258 !important; 
            background-position:left 10px !important; 
            
            a{ 
                color:#505258 !important; 
            }
        }
        
        .tribe-events-widget-events-list__event-date-tag-daynum {
            font-weight: fonts.$font-weight-black !important;
        }
        
        .slick-list {
            padding: 0 10% 0 0;
            overflow: visible;/*important, allows overflow slides to be visible*/
        }

        h2 {
            position: relative;
            margin: 0 auto;

            max-width: unit.rem-calc(1150, 13);

            @media screen and (min-width: 1380px) {
                max-width: unit.rem-calc( 1240px, 13 );
            }

            @media screen and (min-width: 1680px) {
                max-width: unit.rem-calc( 1416px, 13 );
            }

            .card-slider__slick-nav {
                top: -20px;
                right: 0;
                width: auto;
            }
        }
        
        .news-events-grid__news-card{
            transition: box-shadow 0.2s;
        
            &:hover {
                box-shadow: 0 unit.rem-calc(5, 13) unit.rem-calc(15, 13) rgba(0, 0, 0, .25);
            }
        }
    }
    
    .card-slider__slick-nav {
        top: 0;

        .slick-arrow {
            font-size: 18px;
            z-index: 2;
            background: url(../../dist/img/icons/chevron-left-thin-red.svg) 50% no-repeat;
                background-size: auto;
            background-size: 1.0625em 1.625em;
            height: 4.375em;
            width: 4.375em;
            position: relative;
            display: inline-block;
            top: auto;
            -ms-transform: translate(0, 0);
            transform: translate(0, 0);

            &:before {
                content: '';
            }
        }

        .slick-prev {
            left: 0;
        }
        .slick-next {
            right: 0;
            background-image: url(../../dist/img/icons/chevron-right-thin-red.svg);
        }
    }

    h2 {
        position: relative;
    }

    .is-upcoming-events {
        .slick-slide {
            margin: 0 20px;
        }

        .slick-track {
            margin: 0 -20px;
        }
    }

    @media screen and (max-width: 991px) {
        .page-header {
            &__content-wrap {
                display: flex;
                flex-direction: column;
                justify-content: end;
                padding-bottom: 0;
                padding-top: 60px;
            }
            
            &__campus-attributes {
                margin: 0 -20px 40px;
                padding: 14px 20px;
                white-space: nowrap
            }
            
            &__campus-attribute {
                font-family: fonts.$text-regular;
                font-size: 13px;
                margin-right: 30px;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                font-weight: bold;

                svg {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }

                &-inactive{
                    opacity: .4;
                    color: #fff !important;
                }
            }
        }
    }

    @media print, screen and (min-width: 48em) {
        .page-header__campus-attribute {
            transition: transform .2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
            
            &:hover {
                transform: scale(1.1);
            }

            svg {
                margin-bottom: 10px;
            }
        }
    }
}

@media screen and (min-width: 23.4375em)and (max-width: 47.9375em) {
    body.page-template-campus .page-header__campus-attribute svg {
        font-size: calc(16.5496183206px + 3.0534351145vw)
    }
}

@media screen and (min-width: 48em) {
    body.page-template-campus .page-header__campus-attribute svg {
        font-size: 40px
    }
}

/** ROYAL OAK **/
.page-id-2285 {
    h2.has-text-align-center {
        width: 100%;
        margin-top: 0;
        margin-bottom: 30px;
    }

    .hentry {
        .h3 {
            font-size: unit.rem-calc(32, 13);         
        }

        .h4 {
            font-size: unit.rem-calc(28, 13);         
        }

        .wp-block-media-text {
            grid-column-gap: 50px;
        }
    }

    @media screen and (max-width: 1255.98px){
        .section-width,
        .section-width-small {
            grid-column: 1/-1 !important;
            padding-left: 30px !important;
            padding-right: 30px !important;
        }
        .campus-map .wp-block-cover__inner-container {
            padding-top: 50px;
            padding-left: 30px;
            padding-right: 30px;
        }
        .campus-updates.grid-40-60 {
            grid-template-columns: initial;
            grid-gap: 30px !important;
        }

        .hentry {
            .h3 {
                font-size: unit.rem-calc(25, 13);         
            }

            .h4 {
                font-size: unit.rem-calc(20, 13);         
            }
        }
    }

    @media screen and (max-width: 1028px) {
        .hentry .alignfull {
            grid-column: 1/-1;
        }
    }
    
    @media screen and (min-width: 769px) and (max-width: 1124px){
        .grid-40-60 .wp-block-column + .wp-block-column {
            width: 450px;
        }
    }

    @media screen and (min-width: 769px){
        body .campus-about {
            grid-auto-flow: column !important;
            grid-gap: 3.125rem;
        }
        .grid-40-60 {
            grid-template-columns: 1fr 1fr!important;
            grid-gap: 50px !important;
        }
    }

    @media screen and (max-width: 768px){
        .campus-slider-row .prev, .campus-slider-row .next {
            transform: translate(0, 80%);
        }
        .campus-slider-row .slider-content {
            display: flex;
        }
        .campus-slider-row .slider-content .slider-column:not(first-child):not(last-child) {
            width: 22%;
        }
        .campus-slider-row .slider-content .slider-column:first-child, .campus-slider-row .slider-content .slider-column:last-child {
            display: block;
        }
        .campus-slider-row .slider-content .slider-column:first-child {
            margin-left: -34px;
        }
        .campus-slider-row .slider-content .slider-column:last-child {
            margin-right: -23px !important;
        }
    }

    @media screen and (max-width: 600px){
        .section-width-small.grid-40-60.campus-updates .wp-block-column + .wp-block-column {
            width: 350px !important;
        }
    }

    @media screen and (max-width: 480px){
        .campus-slider-row .prev, .campus-slider-row .next {
            transform: translate(0, 50%);
            width: 21px;
        }
        .campus-slider-row .slider-content {
            justify-content: space-evenly;
        }
        .campus-slider-row .slider-content .slider-column {
            width: 33% !important;
        }

        .campus-slider-row .slider-content .slider-column:first-child, 
        .campus-slider-row .slider-content .slider-column:last-child {
            display: none;
        }

        .campus-updates {
            padding: 50px 0;
        }

        .campus-slider-row .prev, .campus-slider-row .next {
            transform: translate(0, 35%);
            width: 21px;
        }

        .campus-slider-row .prev {
            left: -0.5rem;
        }

        .campus-slider-row .next {
            right: -0.5rem;
        }
    }

    .campus-slider-row {
        position: relative;
        margin-top: 2rem;
        padding: 0 1.125rem;
    }

    .campus-slider-row .campus-slider__prev,
    .campus-slider-row .campus-slider__next {
        width: 25px;
    }

    .campus-slider-row .slider-content {
        overflow: hidden;
    }

    .campus-slider-row .slider-content .slider-column {
        float: left;
        width: 22%;
        padding: 0 4px;
    }

    .campus-slider-row .slider-content .slider-column:first-child {
        margin-left: -41px;
        float: left;
    }

    .campus-slider-row .slider-content .slider-column:last-child {
        float: right;
        margin-right: -38px;
    }

    .campus-slider-row .slider-content .slider-column:not(first-child):not(last-child) {
        width: 24%;
    }

    .campus-slider-row .slider-column {
        width: 107px;
    }

    .campus-slider-row:after {
        content: "";
        display: table;
        clear: both;
    }

    .gallery-slider {margin-top: 20px;padding: 0 50px;position: relative;}
    .gallery-slider .slick-list {padding: 0px;overflow: hidden;}
    .gallery-slider .slick-slide {margin: 0 5px;}
    .gallery-slider .slick-slide img {max-height: 58px;}
    .gallery-slider .slick-arrow {height: 32px;width: 32px;}
    .gallery-slider .slick-prev{left: 10px;}
    .gallery-slider .slick-next{right: 10px;}
    .gallery-slider .slick-prev:before,
    .gallery-slider .slick-next:before {position: absolute;content: '';height: 32px;width: 32px;top: 0;left: 0;background-repeat: no-repeat;background-size: cover;background-position: center;}
    .gallery-slider .slick-prev:before{background-image: url('/wp-content/uploads/chevron-left-circle-1.svg');}
    .gallery-slider .slick-next:before{background-image: url('/wp-content/uploads/chevron-right-circle-1.svg');}

    .section-width-small {max-width: 1000px !important;margin: 0 auto;grid-column: 2/-2 !important;width: 100%;}
    .section-width-small.grid-40-60 .wp-block-column + .wp-block-column{margin: 0 auto;width: 550px;}

    .campus-map{min-height: 595px !important;}
    .campus-map .wp-block-cover__inner-container {
        padding-top: 8%;
        max-width: 1000px;
        margin: 0 auto;
        display: block !important;
    }
    .campus-map .wp-block-cover__inner-container h2:first-child {
        margin-bottom: 15px;
        font-style: normal;
        font-size: 33px !important;
        line-height: 36px;
        letter-spacing: -0.5px;
        color: #000;
    }
    .campus-map .wp-block-cover__inner-container h2:nth-child(2) {
        color: #E7002D;
        font-style: normal;
        font-size: 24px !important;
        line-height: 33px;
        margin: 0;
    }
    .campus-map .wp-block-cover__inner-container p {
        font-family: fonts.$body;
        font-style: normal;
        font-weight: 400;
        font-size: 17.5px;
        line-height: 26px;
        color: #1f2228;
    }

    .wp-block-columns.campus-explore {
        padding: 6.25rem 0;
        grid-column: 3/-3;
        grid-column-gap: 55px;

        p a {
            text-decoration: underline;
        }
    
        h2 {
            font-style: normal;
            font-size: 33px !important;
            line-height: 36px;
            letter-spacing: -0.5px;
            color: #1f2228;
        }
        
        .wp-block-button__link {
            color: #E7002D;
            background: 0 0;
            border-color: #E7002D;
        
            &:hover {
                background: #E7002D;
                color: #fff;
                cursor: pointer;
            }
        }
    }
}