@use "./settings/fonts";
@use "./functions/em-calc";
@use "./mixins/fluid-property";
@use "./zurb/breakpoint";
@use "./zurb/unit" as unit;

body.page-template-degree {
    overflow: initial;

    .content.page__content > .quote.is-style-default .quote__inner {
        grid-column: 2/-2 !important;
    }

    .hentry {
        display: block;
    }

    .degree-section__content p {
        margin-bottom: 1em;
    }

    .degree-section__content ul {
        list-style: disc;
        margin-bottom: 1em;
        padding-left: 1em;
    }

    .page-header {
        background-size: cover;
        align-items: center;
        display: flex;
        
        &:after {
            top: 0;
            background-color: rgba(0, 0, 0, .5);
            height: 100%;
            position: absolute;
            width: 100%;
            z-index: 10;
        }
    
        &__content-wrap {
            padding-bottom: 1.25em;
            padding-top: 1.25em;
            position: relative;
            transform: none;
            z-index: 20;
        }
    
        &__pre-title {
            display: block;
            margin-bottom: 4px;
            margin-top: 0;
            position: static;
            top: 0;
        }

        &__degree-attributes {
            display: flex;
            margin: 16px 0 10px;
        }

        &__degree-attribute {
            appearance: none;
            background: transparent;
            border: none;
            color: #fff;
            cursor: pointer; 
            align-items: center;
            display: flex;
            margin-right: 45px;

            img {
                margin-right: 10px;
            }
            
            svg {
                display: inline-block;
                font-size: 1.75rem;
                height: 1em;
                margin-right: 6px;
                width: 1em;
            }

            .active {
                display: block;
            }

            .inactive {
                display: none;
            }

            &.page-header__degree-attribute-inactive {
                order: 2;
                opacity: .4;
                
                .active {
                    display: none;
                }
                
                .inactive {
                    display: block;
                }
            }
        }
    }
}

#campus-popup {
    background: #fff;
    box-shadow: 0px 10px 40px rgba(0, 0, 0, .25);
    display: none;
    left: 50%;
    max-width: 350px;
    min-height: 300px;
    padding: 75px 36px 36px;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 99999;
    
    &[aria-expanded=true] {
        display: block;
    }

    &__close {
        align-items: center;
        background: none;
        border: none;
        display: flex;
        font-family: fonts.$disp-medium;
        font-size: 12px;
        letter-spacing: 1px;
        position: absolute;
        right: 1.375em;
        text-transform: uppercase;
        top: 1.375em;
        
        i {
            font-size: 19px;
            margin-left: .25em;
        }
    }

    .campus-popup__content {
        display: none;
    
        &[aria-expanded=true] {
            display: block;
        }
    }

    h3 {
        font-size: 24px !important;
        line-height: 1.1;
    }

    p {
        font-family: fonts.$body;
        font-size: 17.5px;
        line-height: 170%;
        margin-bottom: .75em;
        margin-top: .75em;
    }

    a {
        font-family: fonts.$disp-medium;
        font-size: 17.5px;
        
        i {
            margin-left: .25em;
        }
    }
}

.fade-screen.campus-popup-active {
    opacity: .6;
    z-index: 9999 !important;
}

#social-sharing-mobile-js .share {
    margin-bottom: 2.5em;
}

.pagenav {
    background-color: #fff;
    border-top: 1px solid #edf0f0;
    box-shadow: 0 20px 20px 0px rgba(0, 0, 0, .07);
    display: none;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 4;
    
    &__row {
        display: flex;
        justify-content: space-between;
    }

    &__links {
        display: flex;
        margin-left: -12px;
    }

    &__link {
        align-items: center;
        border-bottom: 4px solid transparent;
        display: flex;
        font-family: fonts.$disp-regular;
        margin: 0 12px;
        padding: 20px 0;
        font-size: 13px;

        @include breakpoint.mx(large) {
            font-size: 16px;            
        }
    }

    &__link-active {
        border-bottom: 4px solid #000;
        color: #000;
    
        &:hover {
            border-bottom-color: #b40024;
        }
    }

    &__actions {
        align-items: center;
        display: flex;
    }

    &__action {
        background-color: #E7002D;
        color: #fff;
        display: block;
        margin: 17px 0;
        padding: 15px 30px;
        
        &:hover {
            background-color: #b40024;
            color: #fff;
        }
    }
    
    .share {
        margin: 10px 0 8px;
        
        .share__links {
            order: -1;

            a {
                font-size: 13px;

                @include breakpoint.mx(large) {
                    font-size: 16px;            
                }
            }
        }
        
        .share__networks .share-open__inside {
            min-width: 1px;
        }
    }
}

#social-sharing-mobile-js .share {
    padding: 0 40px;
}

.degree-section {
    padding: 90px 0;
}

.degree-section + .degree-section {
    position: relative;
}

.degree-section + .degree-section::before {
    background-color: #dee1e2;
    content: "";
    display: block;
    height: 1px;
    left: 50%;
    max-width: 88.5em;
    position: relative;
    top: -90px;
    transform: translateX(-50%)
}

.degree-section .btn-cta-round {
    left: 50%;
    margin-top: 50px;
    position: relative;
    transform: translateX(-50%)
}

.degree-section__title {
    color: #1f2228;
    font-family: fonts.$disp-bold;
    font-size: 36px;
    letter-spacing: 0;
    line-height: 1.1;
    margin-bottom: 28px;
    padding-bottom: 0;
    text-align: center;
}

.degree-section__content {
    font-family: fonts.$body;
    font-size: 17px;
    line-height: 1.5;
    margin-bottom: 28px;
    margin-top: 28px;

    p {
        font-size: 17px;
        line-height: 1.5;
    }
}

.degree-section__content h1 {
    color: #1f2228;
    font-family: fonts.$disp-bold;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 1.1;
    margin-bottom: 0;
}

.degree-section__content h2 {
    color: #1f2228;
    font-family: fonts.$disp-bold;
    font-size: 24px !important;
    letter-spacing: 0;
    line-height: 1.2;
    margin-bottom: 0;
    padding-bottom: 16px;
}

.degree-section__content-wide {
    margin-left: auto;
    margin-right: auto;
    max-width: 793px;
}

.degree-section__content-thin {
    margin-left: auto;
    margin-right: auto;
    max-width: 713px;
}

.degree-section__cta {
    display: inline-block;
    font-family: fonts.$body;
    font-size: 17px;
    left: 50%;
    line-height: 1.2;
    position: relative;
    transform: translateX(-50%)
}

.degree-section__highlights-glance {
    text-align: center;
}

.degree-section__highlights .degree-section__content {
    margin-top: 0;
}

.degree-section__at-a-glance {
    padding: 48px 70px;
    position: relative;
}

.degree-section__at-a-glance::before {
    background-color: #979797;
    content: "";
    display: block;
    height: 1px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 50px;
}

.degree-section__at-a-glance-title {
    color: #E7002D;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.2;
    padding-bottom: 12px;
    font-family: sans-serif;
}


.degree-section__at-a-glance-stat {
    color: #E7002D;
    font-family: fonts.$sans-fancy;
    font-weight: fonts.$font-weight-bold;
    font-size: 48px;
    line-height: 1.4;
}

.degree-section__at-a-glance-desc {
    margin-bottom: 0 !important;
    font-size: 20px;

    @media screen and (min-width: 650px) {
        font-size: 29.25px;
    }
}

.degree-section__careers {
    background-color: #f7fafa;
    padding: 90px 0;
}

.degree-section__careers .degree-section__content {
    margin-top: 54px;
}

.degree-section__careers .career-facts__num {
    font-size: 48px;
    line-height: 1.25;
}

.degree-section__careers .career-facts__fact-text {
    margin: 0 auto;
    max-width: 250px;
}

.degree-section__careers-facts .degree-section__title {
    padding-bottom: 0;
    padding-top: 90px;
}

.degree-section__curriculum-courses {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.degree-section__curriculum-courses-title {
    color: #E7002D;
    font-family: "Neue Haas Grotesk Display Pro";
    font-size: 14px;
    font-weight: bold;
    line-height: 1.2;
    margin: 45px 0 53px;
    position: relative;
    text-align: center;
}

.degree-section__curriculum-courses-title::after {
    background-color: #979797;
    bottom: -11px;
    content: "";
    display: block;
    height: 1px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 32px;
}

.degree-section__curriculum-course {
    margin: 0 0 20px;
    max-width: 360px;
    min-width: 300px;
    overflow: hidden;
    position: relative;
}

.degree-section__curriculum-course:focus {
    outline: none;
}

.degree-section__curriculum-course-intro-left {
    display: flex;
    flex-direction: column;
}

.degree-section__curriculum-course-subtitle {
    color: #868a8c;
    font-size: 14px;
    line-height: 1.15;
    margin-bottom: 18px;
    text-transform: uppercase;
    font-family: sans-serif;
}

.degree-section__curriculum-course-number.degree-section__curriculum-course-subtitle {
    color: #6e7173;
}

.degree-section__curriculum-course-title {
    color: #1f2228;
    font-size: 20px;
    line-height: 1.3;
    margin-bottom: .25em;
}

.degree-section__curriculum-course-description, .degree-section__curriculum-course-hours {
    font-family: fonts.$body;
    font-size: 16px;
    line-height: 1.625;
}

.degree-section__curriculum-course-description {
    margin-top: 20px;
}

.degree-section__curriculum-course-hours {
    font-style: italic;
    margin-top: 18px;
}

.degree-section__accreditation-image {
    margin: 15px 0;
    text-align: center;
}

@media screen and (max-width: 47.99875em) {
    body.page-template-degree .page-header__content-wrap {
        display: flex;
        flex-direction: column;
        justify-content: end;
        padding-bottom: 0;
        padding-top: 60px;

        .page-header__pre-title {
            width: 100%;
        }
    }

    body.page-template-degree .page-header__degree-attributes {
        background: #202227;
        margin: 60px -20px 0;
        padding: 14px 20px;
        white-space: nowrap;
    }

    body.page-template-degree .page-header__degree-attribute {
        font-family: fonts.$text-regular;
        font-size: 13px;
        margin-right: 30px;
    }
}

@media print, screen and (min-width: 48em) {
    body.page-template-degree .page-header__degree-attribute {
        transition: transform .2s cubic-bezier(0.18, 0.89, 0.32, 1.28)
    }

    body.page-template-degree .page-header__degree-attribute:hover {
        transform: scale(1.1)
    }

    body.page-template-degree .page-header__degree-attribute svg {
        margin-right: 10px;
    }
}

@media screen and (min-width: 23.4375em)and (max-width: 47.9375em) {
    body.page-template-degree .page-header__degree-attribute svg {
        font-size: calc(16.5496183206px + 3.0534351145vw)
    }
}

@media screen and (min-width: 48em) {
    body.page-template-degree .page-header__degree-attribute svg {
        font-size: 40px;
    }
}

@media screen and (max-width: 991px) {
    body.page-template-degree #performance .degree-section__content {
        border-left: 1px solid #000;
        border-right: 1px solid #000;
        margin: 1.5625rem 0;
        overflow: scroll;
    }

    body.page-template-degree #performance table thead tr {
        background: #fff;
        position: static;
    }

    body.page-template-degree #performance .degree-section__content table td {
        border-left: 1px solid #000;
    }

    body.page-template-degree #performance .degree-section__content table td:first-child {
        border-left: none;
    }

    body.page-template-degree #performance .degree-section__content table td:last-child {
        border-right: none;
    }

    body.page-template-degree #performance .degree-section__content table {
        border: none;
        height: auto !important;
        margin: 0;
        text-align: left !important;
    }

    body.page-template-degree #performance .degree-section__content table thead {
        display: table-header-group;
    }

    body.page-template-degree #performance .degree-section__content table tbody {
        display: table-row-group;
    }

    body.page-template-degree #performance .degree-section__content table th {
        display: table-cell;
    }

    body.page-template-degree #performance .degree-section__content table td {
        display: table-cell;
    }

    body.page-template-degree #performance .degree-section__content table tr {
        display: table-row;
    }
}

@media screen and (min-width: 992px) {
    body.page-template-degree .page-header__pre-title {
        margin-bottom: 8px;
        position: unset;
        top: 0;
    }

    .degree-section__careers .career-facts {
        padding: 42px 0 52px;
    }

    .degree-section__careers .career-facts .row {
        display: flex;
        justify-content: center;
    }

    .degree-section__careers .career-facts__fact-col:last-child::after {
        display: none;
    }

    .degree-section__careers .career-facts__fact-col {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .degree-section__careers .career-facts__num {
        font-size: 70px;
    }

    .degree-section__curriculum-course {
        margin: 20px;
    }
}

.themed--adi .degree-section__curriculum-course-intro-caret,
.themed--cim .degree-section__curriculum-course-intro-caret {
    filter: saturate(0.125);
}

@media screen and (min-width: 1000px) {
    .pagenav {
        display: block;
    }
}

@media screen and (min-width: 48em) {
    .pagenav .share .share__links {
        margin-right: 20px;
    }
}

@media (min-width: 992px) {
    .degree-section__title {
        font-size: 47px;
    }

    .degree-section__content h1 {
        font-size: 47px;
    }

    .degree-section__content h2 {
        font-size: 31px !important;
        line-height: 1.4;
    }

    .degree-section__curriculum-course:hover .degree-section__curriculum-course-content, 
    .degree-section__curriculum-course:focus .degree-section__curriculum-course-content {
        transform: translateY(0)
    }

    .degree-section__curriculum-course-intro, .degree-section__curriculum-course-content {
        height: 100%;
        padding: 30px 24px;
        width: 100%
    }

    .degree-section__curriculum-course-intro {
        background-color: #f6f7f7;
        border-top: 4px solid #1f2228;
        left: 0;
        position: absolute;
        top: 0;
        z-index: 1;
    }

    .degree-section__curriculum-course-intro-caret {
        display: none;
    }

    .degree-section__curriculum-course-title {
        font-family: fonts.$disp-bold;
        font-weight: fonts.$font-weight-bold;
        font-size: 32px;
        margin-bottom: 0;
        margin-top: 18px;
    }

    .degree-section__curriculum-course-content {
        background-color: #1f2228;
        border-top: 4px solid #E7002D;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        left: 0;
        position: relative;
        top: 0;
        transform: translateY(-101%);
        transition: transform .5s cubic-bezier(0.99, 0.4, 0.49, 1.27);
        z-index: 2;
    }

    body.themed--adi .degree-section__curriculum-course-content {
        border-top-color: #1E68AA;
    }

    body.themed--cim .degree-section__curriculum-course-content {
        border-top-color: #D9232F;
    }
}

@media screen and (min-width: 650px) {
    .degree-section__highlights .row {
        display: grid;
        grid-gap: 30px;
        grid-template-columns:2fr 1fr;
    }
}

@media screen and (min-width: 2000px) {
    .degree-section__curriculum-course {
        max-width: 440px;
    }
}

@media (max-width: 991px) {
    .degree-section__curriculum-course-intro {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
    }

    .degree-section__curriculum-course-intro-caret {
        margin-left: 2em;
        margin-top: .5em;
        transition: all .25s ease;
    }

    .degree-section__curriculum-course-content:not(.is-showing) {
        display: none;
    }

    .degree-section__curriculum-course-content .degree-section__curriculum-course-subtitle {
        display: none;
    }

    .degree-section__curriculum-course {
        border-bottom: 1px solid #edf0f0;
        margin: 0;
        padding-bottom: 15px;
        padding-top: 15px;
        width: 100%
    }

    .degree-section__curriculum-course.open .degree-section__curriculum-course-intro-caret {
        transform: rotateX(180deg)
    }

    .degree-section__curriculum-course-subtitle {
        margin-bottom: 0;
        order: 2;
    }
}

@media screen and (min-width:992px) {
    .themed--adi .secondary-nav__logo,
    .themed--cim .secondary-nav__logo {
        background-position: center;
        left: 10px;
        top: 0;
    }
}

.callout-boxes {
    margin-bottom: 2.5rem;
    font-size: 16px;
    grid-column-end: 3;
    grid-column-start: 1;
}

.callout-boxes__inside {
    display: flex;
}

.callout-boxes__box {
    border: 2px solid #e4e4e4;
    flex: 1 1 auto;
    padding: 2.125em;
}

.callout-boxes__box h3 {
    font-size: 1.25em !important;
    margin-bottom: .5em;
}

.callout-boxes__box p {
    font-family: fonts.$body;
    font-size: 1.09375em;
    line-height: 1.7;
    margin-bottom: .5714285714em;
}

.callout-boxes__box a {
    display: inline-block;
    font-family: fonts.$disp-bold;
    font-weight: fonts.$font-weight-bold;
    font-size: 1.125em;
}

.callout-boxes__box a i {
    font-size: 1.0555555556em;
    margin-left: .3157894737em;
}

@media screen and (min-width: 23.4375em)and (max-width: 47.9375em) {
    .callout-boxes {
        margin-bottom:calc(1.8320610687px + 10.1781170483vw)
    }
}

@media screen and (min-width: 48em) {
    .callout-boxes {
        margin-bottom:80px;
    }
}

@media screen and (max-width: 47.99875em) {
    .callout-boxes__inside {
        flex-direction:column;
    }

    .callout-boxes__box:first-child {
        margin-bottom: 2.5em;
    }
}

@media print,screen and (min-width: 48em) {
    .callout-boxes__inside {
        margin:0 -1.25em;
        padding: 0 1.25em;
    }

    .callout-boxes__box {
        margin: 0 1.25em;
        max-width: calc(50% - 2.5em)
    }
}

@media screen and (min-width: 105em) {
    .callout-boxes__inside {
        padding-left:0;
        padding-right: 3.75em;
    }
}

@media print,screen and (min-width: 64em) {
    .callout-boxes__box:only-child {
        max-width:64%;
    }
}