@use "sass:math";
@use "../settings/colors";
@use "../settings/namespaces";
@use "../settings/numbers";
@use "../functions/em-calc";
@use "../functions/vw-by-breakpoint";
@use "../mixins/absolute-fit";
@use "../mixins/fluid-property";
@use "../mixins/object-fit";
@use "../typography/button-mixins";
@use "../typography/headings-mixins";
@use "../typography/links-mixins";
@use "../typography/paragraph-mixins";
@use "../zurb/breakpoint";
@use "../zurb/unit";
@use "../settings/fonts" as fonts;

.wp-block-group {

	&.alignwide > .wp-block-group__inner-container > h2 {
		padding-left: 16.25px;
		padding-right: 16.25px;
		margin-left: auto;
		margin-right: auto;

		max-width: unit.rem-calc(1150, 13);

		@media screen and (min-width: 1380px) {
			max-width: unit.rem-calc( 1240px, 13 );
		}

		@media screen and (min-width: 1680px) {
			max-width: unit.rem-calc( 1416px, 13 );
		}
	}

	&.learn-your-way {
		& > .wp-block-group__inner-container {
			max-width: 975px;
		}

		.wp-block-columns:not([class*=is-style]) {
			grid-column-gap: 20px !important;
		}

		@media screen and (max-width: 768px) {
			& > .wp-block-group__inner-container > h2,
			.aligncenter {
				margin-left: 0;
				padding-left: 0;
			}

			.has-text-align-center {
				text-align: left;
			}
		}
	}	

	&.is-learn-your-way-buttons {
		max-width: unit.rem-calc(560, 13);
		padding-left: 20px;
		padding-right: 20px;
		margin-left: auto;
		margin-right: auto;

		.sans strong {
			font-size: unit.rem-calc(20, 13);
			font-family: fonts.$disp-medium;
			font-weight: fonts.$font-weight-bold;
			line-height: 23px;
		}
	}

	&.is-style-blurb-list {

		.wp-block-columns:not([class*=is-style]) {

			@include breakpoint.mx(medium) {
				grid-gap: unit.rem-calc(40);
				grid-template-columns: 1fr 1fr 1fr;
			}
		}

		.wp-block-image {
			position: relative;

			&:not(.is-default){
				&:before {
					content: "";
					display: block;
					padding-top: numbers.$blurb-list-img;
				} 
				
				img {
					@include absolute-fit.mx();
					@include object-fit.mx();
				}
			}
		}

		.wp-block-column {
			padding-bottom: unit.rem-calc(30);
		}

		.wp-block-column > *:not(figure) {
			padding-left: unit.rem-calc(20);
			padding-right: unit.rem-calc(20);
		}

		h2.is-style-default {
			grid-column: 1/12;
			text-align: center;
		}
	}

	&.is-style-callout-bar {
		background-color: colors.$black-haze;
		border: 1px solid rgba(colors.$iron-1, .3);

		margin-top: em-calc.func(60, 13) !important;
		margin-bottom: em-calc.func(100, 13) !important;

		@media screen and (max-width: 1280px) and (min-width: 836px) {			
			grid-column: 2/-2 !important;
		}

		& > .wp-block-group__inner-container {
			align-items: flex-start;
			display: flex;
			margin: 0 auto;
			padding: 2rem;

			@media screen and (min-width: 1024px) {
				padding: 2rem 5rem;
			}
		}

		.wp-block-group {
			padding: 0;
		}

		.wp-block-group__inner-container > .wp-block-image:first-child { // Meant to be an accompanying icon.
			flex: 0 0 auto;
			margin: 0 1rem 0 0;
		}

		strong {
			color: colors.$baker-black;
			font-size: em-calc.func(20, 14);
			line-height: em-calc.func(24, 20);
			font-family: fonts.$disp-bold;
			//font-weight: normal;
		}

		p {
			color: colors.$black;
			font-family: fonts.$body;
			font-size: em-calc.func(14, 13);
			margin-bottom: em-calc.func(8, 14);
		}

		a:not([class]) {
			color: #000;
			text-decoration: underline;

			&:hover {
				color: colors.$red-dark;
			}
		}
	}

	&.is-style-callout-box {
		background-color: colors.$black-haze;
		border: 1px solid rgba(colors.$iron-1, .3);
		margin-bottom: 2rem;

		.wp-block-group__inner-container {
			border-top: 5px solid colors.$baker-black;
			padding: 2.5rem 2rem;
		}

		.wp-block-button.is-style-text .wp-block-button__link {
			color: darken(colors.$red-light, 1%);
		}

		.wp-block-button.is-style-text .wp-block-button__link:hover {
			color: colors.$red-dark;
		}

		.wp-block-buttons,
		.wp-block-button + .wp-block-button {
			margin: 0;
		}

		&.has-white-background {
			background-color: colors.$white;
		}
	}

	&.is-style-callout-box-icon {
		margin-top: 2.5rem;

		.wp-block-group__inner-container {
			background-color: colors.$black-haze;
			border: 1px solid rgba(colors.$iron-1, .3);
			padding: 0 2rem 3rem 2rem;

			& > h2 {
				margin-top: 0;
			}
		}

		.wp-block-group__inner-container > .wp-block-image:first-child {
			margin: 0;
			text-align: center;
			transform: translateY(-50%);

			img {
				background-color: white;
				border-radius: 100%;
			}
		}

		p {
			font-family: fonts.$text-regular;
		}
	}

	&.is-style-cards {

		.wp-block-columns:not([class*=is-style]) {
			display: grid;
			grid-auto-flow: row;
			grid-row-gap: unit.rem-calc(30);
			grid-template-columns: 1fr;

			@include breakpoint.mx(640) {
				grid-column-gap: unit.rem-calc(40);
				grid-template-columns: 1fr 1fr;
			}
			@include breakpoint.mx(medium) {
				grid-column-gap: unit.rem-calc(20);
				grid-template-columns: 1fr 1fr 1fr;
			}
		}

		.wp-block-columns:not(.is-not-stacked-on-mobile) > .wp-block-column:not(:first-child) {
			margin-left: 0;
		}

		.wp-block-image {
			position: relative;

			&:before {
				content: "";
				display: block;
				padding-top: numbers.$blurb-list-img;
			}
		}

		img {
			@include absolute-fit.mx();
			@include object-fit.mx();
		}

		.wp-block-cnpblock-link-container {
			display: flex;
			flex-direction: column;
			height: 100%;
			padding-bottom: unit.rem-calc(30);

			@include breakpoint.hover() {
				background-color: #FFFFFF;
				box-shadow: 0 0 unit.rem-calc(12) rgba(#000000, 0.2);

				h1,
				h2,
				h3,
				h4,
				h5,
				h6,
				.wp-block-button {
					color: colors.$red-dark;
				}
			}

			.wp-block-button__link {
				color: currentColor;
			}

			> *:not(figure) {
				padding-left: unit.rem-calc(20);
				padding-right: unit.rem-calc(20);
			}
		}

		img {
			width: 100%;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: colors.$red-light;
		}

		p:not([class*=btn]) {
			color: colors.$baker-black;
		}

		.wp-block-button { // Line up the buttons.
			align-items: flex-end;
			display: flex;
			flex: 1 0 auto;
		}

		.btn-angle-right {
			justify-content: flex-start;
		}
	}

	&.is-style-cards-gray-border {

		.wp-block-columns:not([class*=is-style]) {
			grid-row-gap: unit.rem-calc(20);
			grid-template-columns: 1fr;

			@include breakpoint.mx(640) {
				grid-template-columns: 1fr 1fr;
			}
			@include breakpoint.mx(medium) {
				grid-column-gap: unit.rem-calc(16);
				grid-template-columns: 1fr 1fr 1fr;
			}
		}

		.wp-block-column {
			background-color: colors.$white;
			border: 2px solid #E5E5E5;
			padding: unit.rem-calc(24);
		}

		.wp-block-column,
		.wp-block-group,
		.wp-block-group > .wp-block-group__inner-container { // Pass the height down
			display: flex;
		}

		.wp-block-group > .wp-block-group__inner-container {
			flex-direction: column;
		}

		.wp-block-buttons {
			align-items: flex-end;
			flex: 1 0 auto;
		}
	}

	&.is-style-explore {
		@include links-mixins.underline();

		@include breakpoint.mx(small down) {
			.wp-block-columns.alignwide {
				grid-column-gap: 0;
			}
		}

		.wp-block-group[class*=-background-color] {
			padding: unit.rem-calc(30) 0 unit.rem-calc(40);
			border-bottom: unit.rem-calc(1) solid #E5E5E5;
		}

		.wp-block-group__inner-container {
			position: relative;
			z-index: 99;
		}

		.wp-block-column .sans a {
			font-size: 16px;
		}

		.wp-block-column .sans {
			line-height: 1;
		}

		.wp-block-cnpblock-subtitle {
			background-color: colors.$white;
			font-size: unit.rem-calc(13, 13);
			letter-spacing: unit.rem-calc(2, 13);
			margin-bottom: 0;
			margin-top: unit.rem-calc(-42);
			padding: unit.rem-calc(15) unit.rem-calc(20) unit.rem-calc(10);
			text-transform: uppercase;
			width: fit-content;
			z-index: 99;

			@include breakpoint.mx(large) {
				//margin-left: min(#{vw-by-breakpoint.func(30, 414)}, 50px);
			}
		}

		.wp-block-group.alignwide,
		.wp-block-columns:not([class*=is-style]) {
			margin-left: auto;
			margin-right: auto;
			width: 100%;

			max-width: unit.rem-calc(1150, 13);

			@media screen and (min-width: 1380px) {
				max-width: unit.rem-calc( 1240px, 13 );
			}

			@media screen and (min-width: 1680px) {
				max-width: unit.rem-calc( 1416px, 13 );
			}
		}

		.wp-block-columns:not([class*=is-style]) {
			background-color: colors.$white;
			width: 100%;
			grid-template-columns: 1fr 1fr;

			@media screen and (min-width: 800px) {
				grid-template-columns: repeat(3, 1fr);
			}

			@include breakpoint.mx(xlarge) {
				grid-auto-flow: column;
				grid-template-columns: repeat(5, 1fr);
			}
		}

		.wp-block-column {
			padding-left: unit.rem-calc(20);
			padding-right: unit.rem-calc(20);

			.h5 {
				font-size: 20px;
			}
		}

		#{namespaces.$headings} {
			margin-bottom: 0;
		}

		.explore__item-prefix {
			color: colors.$red-light;
			float: left;
			margin-left: -1em;
		}
	}

	&.is-style-formats {
		@include fluid-property.mx((margin-top, margin-bottom), (40, 60, 80));

		> .wp-block-group__inner-container {
			align-items: center;
			display: flex;
			flex-direction: column;
		}

		> .wp-block-group__inner-container > h2 { // The section title.
			font-family: fonts.$disp-bold;
			margin-bottom: unit.rem-calc(20);
			font-size: unit.rem-calc(42, 13);

			& + p {
				margin-top: 0;
			}
		}

		> .wp-block-group__inner-container > p {
			font-family: fonts.$body;
			line-height: 1.7;
			margin-left: auto;
			margin-right: auto;
			margin-top: 1.5em;
			max-width: unit.rem-calc(725, 13);
		}

		.wp-block-columns:not([class*=is-style]) {
			@include fluid-property.mx(margin-top, (30, 50));			            
			display: flex;
			flex-direction: column;
			grid-column-gap: 0;
			grid-row-gap: unit.rem-calc(40);
			justify-content: space-between;
			width: 100%;

			@include breakpoint.mx(medium) {
				flex-direction: row;
				margin-left: unit.rem-calc(-25);
				margin-right: unit.rem-calc(-25);
			}
		}

		.wp-block-column {
			@include fluid-property.mx(padding-top, (20, 30));
			border-top: unit.rem-calc(4) solid #1F2228;
			font-family: fonts.$text-regular;
			width: 100%;

			&--noBorder {
				border-top: none;
				padding-top: 0;
			}

			@include breakpoint.mx(medium) {
				margin: 0 unit.rem-calc(12);
			}

			figure {
				margin: 0;
			}

			img:not([src$=".svg"]) {
				margin-bottom: 1.5em;
			}

			// This block allows for one group inside the column for the icon and title. If more than one group is used, we'll have UI errors.
			.wp-block-group__inner-container {
				align-items: center;
				display: flex;
				margin-bottom: unit.rem-calc(10);
			}

			img[src$=".svg"] {
				margin-right: unit.rem-calc(10);
			}

			h3 {
				color: #000000;
				font-family: fonts.$disp-black;
				font-size: unit.rem-calc(20, 13);
				font-weight: 900;
				line-height: 1;
				margin: 0;
			}

			p {
				line-height: 1.7;

				&:not(:last-child) {
					margin-bottom: 1.125em;
				}

				font-size: unit.rem-calc(16, 13);
			}

			ul {
				list-style: inherit;
				margin: 0 0 unit.rem-calc(18);
				padding-left: 1em;

				li {
					line-height: 1.4;
					margin-bottom: 0.5em;
					font-size: unit.rem-calc(16, 13);
				}
			}

			p a {
				text-decoration: underline;
				font-family: fonts.$text-regular;
			}
		}
	}

	&.is-style-gray-half-background-left {

		@media screen and (min-width: 1024px) {
			background: linear-gradient(270deg, #FFFFFF 60%, #F5F7F7 0);
			border-bottom: 1px solid #E5E5E5;
			border-top: 1px solid #E5E5E5;
		}
	}

	&.is-style-gray-half-background {

		@media screen and (min-width: 1024px) {
			background: linear-gradient(90deg, #FFFFFF 60%, #F5F7F7 0);
			border-bottom: 1px solid #E5E5E5;
			border-top: 1px solid #E5E5E5;
		}
	}

	&.is-style-icon-grid-description {

		.wp-block-columns:not([class*=is-style]) {
			display: grid;
			grid-row-gap: unit.rem-calc(30);
			grid-template-columns: 1fr;

			@include breakpoint.mx(640) {
				grid-column-gap: unit.rem-calc(40);
				grid-template-columns: 1fr 1fr;
			}
			@include breakpoint.mx(medium) {
				grid-column-gap: unit.rem-calc(20);
				grid-template-columns: 1fr 1fr 1fr;
			}
		}

		.wp-block-column {
			padding: unit.rem-calc(30);

			@include breakpoint.hover() {
				transition: all 0.3s ease;
			}

			&:hover,
			&:focus-within {
				box-shadow: 0 30px 50px -20px rgba(0, 0, 0, 0.25);
			}
		}

		#{namespaces.$headings},
		figure,
		p {
			text-align: center;
		}

		p {
			@include paragraph-mixins.p-small();
			font-family: fonts.$text-regular;
		}

		a {
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	&.is-style-icon-grid-links {

		.wp-block-columns:not([class*=is-style]) {
			display: grid;
			grid-auto-flow: row;
			grid-row-gap: unit.rem-calc(30);
			grid-template-columns: 1fr;

			@include breakpoint.mx(640) {
				grid-column-gap: unit.rem-calc(40);
				grid-template-columns: 1fr 1fr;
			}
			@include breakpoint.mx(medium) {
				grid-column-gap: unit.rem-calc(20);
				grid-template-columns: 1fr 1fr 1fr;
			}
			@include breakpoint.mx(large) {
				grid-template-columns: 1fr 1fr 1fr 1fr;
			}
		}

		.wp-block-column {
			padding: unit.rem-calc(30);

			@include breakpoint.hover() {
				transition: all 0.3s ease;
			}

			&:hover,
			&:focus-within {
				box-shadow: 0 30px 50px -20px rgba(0, 0, 0, 0.25);
			}
		}

		#{namespaces.$headings}, figure {
			text-align: center;
		}

		@include breakpoint.mx(medium) {
			#{namespaces.$wp-buttons} {
				opacity: 0;
				transition: opacity 0.3s ease 0.2s;
			}
			
			.wp-block-column:hover,
			.wp-block-column:focus-within {

				#{namespaces.$wp-buttons} {
					opacity: 1;
				}
			}
		}
	}

	&.is-style-icon-list {

		#{namespaces.$headings},
		figure {
			text-align: center;
		}
	}

	&.is-style-matrix { // Matrix table styles have a special group container for the horizontal labels.
		$pad: 20;
		$label-width: 100;

		&--isOverflowing {
			position: relative;

			> .wp-block-group__inner-container{
				overflow: scroll;
				-webkit-overflow-scrolling: touch;
			}

			&:after {
				background: linear-gradient(270deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0) 100%);
				content: "";
				display: block;
				height: 100%;
				position: absolute;
				right: 0;
				top: 0;
				width: em-calc.func(8);
				z-index: 1;
			}
		}

		> .wp-block-group__inner-container {
			display: flex;
			position: relative;
		}

		.wp-block-columns:not([class*=is-style]) {
			display: flex;
			flex: 0 0 auto;
			flex-direction: column;
			grid-row-gap: 0;
			margin-right: -1px; // Overlap the left black border.
			margin-top: unit.rem-calc(100);
			position: relative;
			width: unit.rem-calc($label-width, 13);
			z-index: 1;
		}

		.wp-block-column {
			align-items: center;
			border: 1px solid colors.$baker-black;
			border-top: none;
			display: flex;
			flex: 0 0 auto;
			grid-column-start: 1;
			justify-content: center;
			position: relative;
			text-transform: uppercase;

			&:first-child {
				border-top: 1px solid colors.$baker-black;
			}

			p {
				font-family: fonts.$disp-medium;
				font-size: em-calc.func(14);
				letter-spacing: em-calc.func(2, 14);
				transform: rotate(-90deg);
				white-space: nowrap;
			}
		}

		.is-style-matrix-overflow {
			max-width: calc(100vw - #{unit.rem-calc(58)} - min(7vw, 50px));

			@media screen and (max-width: 720px) {
				max-width: calc(80vw - #{unit.rem-calc(58)});				
			}
		}

		.wp-block-table {
			min-width: unit.rem-calc(900);
			overflow: hidden;
		}

		table {
			margin-bottom: 0;
		}

		table,
		tr,
		td,
		th {
			border: none;
		}

		th {
			@include headings-mixins.h5((
				"background-color": colors.$red-light,
				"color": colors.$white,
			));
			font-weight: fonts.$font-weight-bold;
		}

		tr {
			border-left: 1px solid colors.$baker-black;
			border-right: 1px solid colors.$baker-black;
		}

		tr:first-child { // Should be the first body <tr>
			border-bottom: 1px solid colors.$baker-black;
			border-left-color: colors.$red-light;
			border-right: colors.$red-light;
		}

		th,
		td {
			padding: unit.rem-calc(17) unit.rem-calc(21);
		}

		td {
			@include paragraph-mixins.p-small((
				"border-bottom": 1px solid colors.$black,
				"font-family": fonts.$text-regular,
				"line-height": 1.7
			));

			&:nth-child(odd) {
				background-color: colors.$white;
			}

			&:nth-child(even) {
				background-color: colors.$black-haze;
			}
		}
	}

	&.is-style-pair {

		.wp-block-columns:not([class*=is-style]) {

			@include breakpoint.mx(medium) {
				grid-gap: unit.rem-calc(16);
			}
		}

		.glide__slides > * {
			white-space: normal !important;
		}

		.glide__bullets {
			display:flex;
			justify-content:center
		}
		.glide__bullet {
			font-size:16px;
			width:1em;
			height:1em;
			-webkit-appearance:none;
			-moz-appearance:none;
			appearance:none;
			background:none;
			border:1px solid #797979;
			border-radius:100%;
			margin:0 8px
		}
		.glide__bullet--active {
			background-color:#797979
		}
		@media print,screen and (min-width: 48em) {
			.glide__slides {
				overflow:visible
			}
			.glide__bullets {
				display:none
			}
		}
		@media screen and (min-width: 48em)and (max-width: 63.99875em) {
			.glide__slides {
				flex-wrap:wrap
			}
		}

		.wp-block-column {
			background-color: colors.$white;
			border-top: unit.rem-calc(5) solid colors.$baker-black;
			box-shadow: 0 unit.rem-calc(26) unit.rem-calc(44) unit.rem-calc(-17) rgba(colors.$black, .25);

			.wp-block-group:first-child { // The top half
				background-color: colors.$black-haze;
				padding: unit.rem-calc(35) unit.rem-calc(26);

				@include breakpoint.mx(medium) {
					padding-left: unit.rem-calc(40);
					padding-right: unit.rem-calc(40);
				}
			}

			.wp-block-group:last-child { // The bottom half
				padding: unit.rem-calc(17) unit.rem-calc(20) unit.rem-calc(20);

				@include breakpoint.mx(medium) {
					padding: unit.rem-calc(24) unit.rem-calc(18) unit.rem-calc(36);
				}

				#{namespaces.$headings} {
					margin-bottom: unit.rem-calc(30);
				}
			}
		}
	}

	&.is-style-quick-links {

		#{namespaces.$headings} {
			border-top: unit.rem-calc(4, 13) solid colors.$baker-black;
			margin-top: 0;
			padding-top: unit.rem-calc(20, 13);

			&:not(:first-child) {
				margin-top: unit.rem-calc(50, 13);
			}
		}

		h3 {
			font-size: unit.rem-calc(19.5, 13) !important;
			font-family: fonts.$disp-bold;
		}

		.wp-block-buttons {
			margin-top: unit.rem-calc(32, 13);
			
			.wp-block-button.is-style-text {
				width: 100%;
				margin-left: 0;
			}

			.wp-block-button:not(:last-child).is-style-text {
				margin-bottom: unit.rem-calc(35, 13);
			}
		}

		.is-style-quick-links {
			border-top: none !important;
			padding-top: 0;
		}

		 a.wp-block-button__link {			 
            font-size: unit.rem-calc(18, 13);
		 }
	}

	&.is-style-stats,
	&.is-style-stats-no-dividers {

		.wp-block-columns:not([class*=is-style]) {
			grid-template-columns: repeat(2, 1fr);

			@include breakpoint.mx(large) {
				grid-template-columns: repeat(4, 1fr);
			}
		}

		.wp-block-column {
			padding: unit.rem-calc(16 20);

			@include breakpoint.mx(large) {
				padding-left: unit.rem-calc(30);
				padding-right: unit.rem-calc(30);
			}

			strong {
				@include headings-mixins.stat();
				font-weight: fonts.$font-weight-black;
			}

			p {
				@include headings-mixins.h5();
				line-height: 1.1;
			}
		}
	}

	&.is-style-stats {

		.wp-block-column:not(:first-child) {

			@include breakpoint.mx(medium) {
				border-left: 1px solid #C9CED1;
			}
		}
	}

	&.is-style-tab-accordion {

		&.alignwide {
			@media screen and (max-width: 1256px) and (min-width: 992px) {
				grid-column: 1/6 !important;
				margin-right: 40px;
				margin-left: 40px;
			}
		}

		.wp-block-columns:not([class*=is-style]) {

			@include breakpoint.mx(medium) {
				grid-column-gap: unit.rem-calc(90, 13);
				grid-template-columns: unit.rem-calc(415, 13) 1fr;
			}

			p {
				font-size: em-calc.func(16, 13);
			}

			.h3 {
				font-size: em-calc.func(42, 13);
			}
		}

		.wp-block-cnpblock-form-button {
			align-items: center;
			box-shadow: inset 0 2px #E5E5E5;
			display: flex;
			font-family: fonts.$disp-bold;
			font-weight: fonts.$font-weight-bold;
			font-size: unit.rem-calc(20, 13);
			justify-content: space-between;
			line-height: 1;
			padding: unit.rem-calc(17.5, 13) unit.rem-calc(12.5, 13);
			white-space: nowrap;
			width: 100%;

			@include breakpoint.mx(small only) {
				display: none;
			}

			svg {
				color: #C4C4C4;
				font-size: unit.rem-calc(16, 20);
			}

			@include breakpoint.hover-support() {
				cursor: pointer;

				svg {
					opacity: 0;
					transform: translateX(200%);
					transition: transform .2s ease-out, opacity .2s ease;
					will-change: opacity, transform;
				}

				&:hover {
					background-color: colors.$black-haze;

					svg {
						opacity: 1;
						transform: translateX(0%);
					}
				}
			}

			&[aria-selected="true"] {
				color: colors.$red-light;

				&,
				&:hover {
					background-color: colors.$white;
				}

				svg {
					color: colors.$red-light;
					opacity: 1;
					transform: translateX(0%);
				}
			}
		}

		.accordion__item-toggle {

			@include breakpoint.mx(medium) {
				display: none;
			}

			@include breakpoint.mx(small) {
				padding-left: 1.25rem;
				padding-right: 1.25rem;

				svg {
					display: none;
				}
			}
		}

		.accordion__item-content,
		.accordion__item .wp-block-group,
		.accordion__item .wp-block-group__inner-container {
			height: 100%;
		}

		> .wp-block-group__inner-container > .wp-block-columns > .wp-block-column:last-child,
		.wp-block-cnpblock-accordion,
		.accordion__item-content .wp-block-group__inner-container {

			@include breakpoint.mx(medium) {
				display: flex;
			}
		}

		.wp-block-cnpblock-accordion {
			border-top: 2px solid #E5E5E5;

			&:last-child {
				border-bottom: 2px solid #E5E5E5;
			}
		}

		.accordion__item[aria-expanded="true"] {

			@include breakpoint.mx(small only) {
				border-top: 4px solid colors.$red-light;
				box-shadow: 0 4px 10px rgba(colors.$black, .25);
			}
		}

		.accordion__item-toggle {

			@include breakpoint.mx(small only) {
				padding-left: unit.rem-calc(20);
			}
		}

		.accordion__item-content {
			background-color: colors.$white;
			position: relative;
			padding: 0;

			.wp-block-group .wp-block-group {
				position: relative;
				z-index: 2;

				@include breakpoint.mx(small down) {
					padding: 0 unit.rem-calc(20) unit.rem-calc(25);
				}
				@include breakpoint.mx(medium) {
					padding: unit.rem-calc(40);

					&,
					* {
						color: #FFFFFF;
					}
				}
			}

			.wp-block-group__inner-container .wp-block-group__inner-container {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
			}

			h4 {

				@include breakpoint.mx(small only) {
					display: none;
				}
			}
		}

		.wp-block-image {
			position: relative;

			@include breakpoint.mx(small down) {
				height: 55vw;
				max-height: unit.rem-calc(350);
				min-height: unit.rem-calc(230);
			}
			@include breakpoint.mx(medium) {
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
			}

			&:before {
				background: linear-gradient(179.66deg, rgba(0, 0, 0, 0) 0.43%, rgba(0, 0, 0, 0.49) 99.84%), radial-gradient(90.86% 90.86% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
				content: "";
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 1;

				@include breakpoint.mx(large) {
					background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 63.85%), radial-gradient(90.86% 90.86% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
				}
			}

			img {
				display: block;
				height: 100%;
				object-fit: cover;
				object-position: center;
				width: 100%;
				background-color: #000;
			}
		}
	}

	&.is-style-three-column-nav {

		.wp-block-columns:not([class*=is-style]) {
			grid-auto-flow: row;

			@include breakpoint.mx(medium) {
				grid-gap: unit.rem-calc(16);
				grid-row-gap: unit.rem-calc(32);
				grid-template-columns: 1fr 1fr;
			}
			@include breakpoint.mx(large) {
				grid-template-columns: 1fr 1fr 1fr;
			}
		}

		.wp-block-column {
			border-top: unit.rem-calc(5) solid colors.$baker-black;
			padding: unit.rem-calc(32 32 32 24);
		}

		&.is-match-height {
			.wp-block-column {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;

				&>p {
					flex: 1 1 auto;
				}
			}
		}
	}

	&.has-min-width {
		@media screen and (min-width: 580px) {
			min-width: 580px;
		}
	}
}