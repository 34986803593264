@use "paragraph-mixins";

p {
	//@include fluid-property.mx(margin-bottom, (30, 50));
	@include paragraph-mixins.p();
}

p.has-small-font-size {
	@include paragraph-mixins.p-small();
}

p.has-medium-font-size {

}

p.has-large-font-size {
	@include paragraph-mixins.p-large();
}

p.has-huge-font-size {
	@include paragraph-mixins.p-huge();
}


