@use "../settings/fonts";
@use "../functions/em-calc";
@use "../mixins/fluid-property";
@use "../zurb/breakpoint";

$namespace: '.expandable-list';

.expandable-list {
	display: flex;
	flex-direction: column;
	margin-top: em-calc.func(20);
	position: relative;

	&[aria-expanded="true"] {

		#{$namespace}__inside {
			max-height: none;

			&:after {
				opacity: 0;
			}
		}
		#{$namespace}__button {
			display: none;
		}
	}

	&__inside {
		max-height: 200px;
		overflow: hidden;
		position: relative;

		&:after {
			background: linear-gradient(180deg, rgba(248, 250, 250, 0) 0%, rgba(245, 247, 247, 0) 0.01%, #F5F7F7 100%);
			bottom: 0;
			content: "";
			display: block;
			height: 100px;
			left: 0;
			position: absolute;
			width: 100%;
			pointer-events: none;
		}

		ul {
			font-size: em-calc.func(13, 13) !important;

			&.is-horizontal-list {
				column-count: 3;
				column-gap: 1.25em;

				@include breakpoint.mx(small only) {
					column-count: 2;
				}

				li {
					font-size: em-calc.func(13, 13) !important;
					width: 100%;
				}
			}
		}
	}

	&__button {
		appearance: none;
		background: transparent;
		border: none;
		box-shadow: none;
		font-family: fonts.$text-bold;
		font-size: em-calc.func(12);
		letter-spacing: em-calc.func(1, 12);
		text-transform: uppercase;
	}
}