@use "../settings/colors";
@use "../settings/namespaces";
@use "../mixins/fluid-property";
@use "../mixins/remove-child-margins";
@use "../typography/links-mixins";
@use "../zurb/breakpoint";
@use "../zurb/unit";
@use "../settings/fonts";

@import "wordpress-variables";
@import "../../../node_modules/@wordpress/block-library/src/media-text/style"; // WP stylesheets have to be imported.


.wp-block-media-text {
	$prefix: ".wp-block-media-text";
	display: flex; // Override WP
	flex-direction: column;
	margin: 0;

	@include breakpoint.mx(medium) {
		display: grid;
	}

	&__content {
		@include remove-child-margins.mx();
		padding: 0 0 unit.rem-calc(30, 13);

		@include breakpoint.mx(medium) {
			min-height: 100%;
			padding: 0 unit.rem-calc(50, 13) 0 0;
		}
	}

	&__media {
		
		@include breakpoint.mx(small only) {
			order: 2;
			min-height: 400px !important;
			width: 100%;
		}
	}

	.wp-block-button__link {
		padding: unit.rem-calc(13, 13) unit.rem-calc(32, 13);
	}

	h2 {
		@include breakpoint.mx(large) {
			font-size: unit.rem-calc(50, 13);
		}
	}

	#{namespaces.$headings} {
		margin-bottom: 1.5rem;
		margin-top: 0;
	}

	&.is-reverse-img {
		#{$prefix}__media {
			@include breakpoint.mx(small down) {
				width: 100%;
				order: 0;
				height: 80vw;
			}
		}

		#{$prefix}__content {
			padding: calc(4.4329896907px + 2.5773195876vw) !important;
		}
	}

	&.has-media-on-the-right {

	}

	&.alignwide {
		grid-column: 1/-1 !important;
		max-width: 1440px;
		margin-left: auto;
		margin-right: auto;
		grid-column-gap: 50px;

		&:not(.is-style-ghost) {
			padding-left: 16.5px;
			padding-right: 16.5px;
		}

		@media screen and (max-width: 992px) {
			grid-column: 2 !important;
		}

		#{$prefix}__content {
			@include breakpoint.mx(large) {
				padding: unit.rem-calc(50);
			}
		}
	}

	&.is-style-dark {
		#{$prefix}__content {
			font-family: fonts.$disp-regular;

			strong, em, a {
				font-family: fonts.$disp-bold;
				font-weight: normal;
			}
		}
	}

	&.is-style-ghost,
	&.is-style-dark {

		grid-column-gap: 0;

		#{$prefix}__media {
			height: 100% !important;

			img {
				height: 100% !important;
				object-fit: cover;
			}
		}
	}

	&.is-style-ghost {
		background: colors.$white;
		border-top: unit.rem-calc(4) solid colors.$baker-black;
		box-shadow: 0 unit.rem-calc(10) unit.rem-calc(30) rgba(colors.$black, .15);
		grid-column: 1/-1;
		max-width: 999px;
		margin-left: auto;
		margin-right: auto;

		#{$prefix}__content {
			padding: unit.rem-calc(30);
		}
	}

	&.is-style-dark {

		#{$prefix}__content {
			@include links-mixins.underline();
			background-color: colors.$baker-black;
			padding: unit.rem-calc(30);

			&,
			*,
			*:hover {
				color: colors.$white;
			}

			a:hover {
				transition: all 0.2s;
				color: colors.$red-dark;
			}
		}
	}
}


@media screen and (max-width: 501px){
  .wp-block-media-text__media{
    min-height: inherit !important;
  }
}