@use "../zurb/unit";
@use "fluid-property";
@use "sass:list" as list;
@use "sass:math" as math;
@use "sass:meta" as meta;
@use "sass:string" as string;

/**
 * Typographic item
 *
 * Helper mixin to take a map of configurable typography properties and apply the correct mixins/functions to said properties.
 * Pass in a map of property/value pairs.
 * Use "-hover", "-focus", or "-active" on the end of a property to indicate a state selector.
 * Pass in a list value in order to trigger the usage of the fluid-property mixin.
 *
 * Example args:
 * $args: (
 *		'border-color': colors.$white,
 *		'border-color-hover': colors.$black,
 *		'color': colors.$black,
 *		'font-size': unit.em_calc(12),
 *		'font-weight': 700,
 *		'height': (20, 40, 60),
 * );
 */
@mixin mx($args: ()) {
	@each $property, $value in $args {
		@if meta.type-of($value) == list and not (string.index($property, "-hover") or string.index($property, "-focus")) {
			// "List" is a squishy data type in SASS. (16,18) is a list. So is "Montserrat", Arial, sans-serif. We want this to apply to lists with only numbers in them, so we have to do some more checking.
			$numbers_list: true;

			@each $item in $value {
				@if meta.type-of($item) != number {
					// This will catch string lists ("Montserrat", Arial, sans-serif).
					$numbers_list: false;
				} @else if false == math.is-unitless($item) {
					// This will catch lists like padding: 10px 10px 20px.
					$numbers_list: false;
				}
				@if $item == n {
					$numbers_list: true;
				}
			}
			@if comma != list.separator($value) {
				// We only want comma-separated lists, e.g. (16, 18).
				$numbers_list: false;
			}
			@if true == $numbers_list {
				@include fluid-property.mx($property, $value);
			} @else {
				#{$property}: $value;
			}
		} @else if string.index($property, "-hover") {
			&:hover {
				#{string.slice($property, 1, -7)}: $value;
			}
		} @else if string.index($property, "-focus") {
			&:focus {
				#{string.slice($property, 1, -7)}: $value;
			}
		} @else if string.index($property, "-active") {
			&:active {
				#{string.slice($property, 1, -8)}: $value;
			}
		} @else if string.index($property, "-checked") {
			&:checked {
				#{string.slice($property, 1, -9)}: $value;
			}
		} @else {
			#{$property}: $value;
		}
	}
}
