@use "between-with-media-queries";
@use "../zurb/unit";
@use "../settings/breakpoints";
@use "sass:map";

@mixin mx($properties, $values, $include-default: true, $continue-behavior: true, $important: false) {
	$counter: 1;
	$has-default: false;

	$breakpoints-keys: map.keys(breakpoints.$sizes);

	@each $current-size in $values {
		$continue: false;
		$next-size: n;

		@if (length($values) == $counter + 1 and true == $continue-behavior) {
			$continue: true;
		}
		@if (length($values) != $counter) {
			$next-size: nth($values, $counter + 1);
		}

		$current-breakpoint: map.get(breakpoints.$sizes, nth($breakpoints-keys, $counter));
		@if (1 == $counter) {
			$current-breakpoint: 375px;
		}

		@if (n != $current-size and n != $next-size) {
			$next-breakpoint: map.get(breakpoints.$sizes, nth($breakpoints-keys, $counter + 1));

			@if (false == $has-default and true == $include-default) {
				@each $property in $properties {
					#{$property}: unit.rem-calc($current-size);
				}
				$has-default: true;
			}
			@include between-with-media-queries.mx(
				$properties,
				$current-size * 1px,
				$next-size * 1px,
				$current-breakpoint,
				$next-breakpoint,
				$continue,
				$important
			);
		}
		$counter: $counter + 1;
	}
}
