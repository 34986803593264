@use "./mixins/fluid-property";
@use './settings/colors' as colors;

body.page-template-college {

    .bread-crumbs {
        background-color: colors.$black-haze;
        &__link,
        &__no-link {
            color: colors.$red-dark;
        }
    }

    .loop.page__loop > .hentry > .wp-block-group:first-child {
        position: relative;

        &::before{
            content: "";
            display: block;
            position: absolute;
            left: -1000px;
            top: -40px;
            background-color: colors.$black-haze;
            bottom: -40px;
            z-index: 1;
            width: calc( 100vw + 1000px );
        }

        & > * {
            z-index: 2;
            position: relative;
        }
    }

}