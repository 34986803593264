@use "../settings/colors";

@each $name, $color in colors.$palette {
	.has-#{$name}-color {
		color: $color !important;
	}

	.has-#{$name}-background-color {
		background-color: $color !important;
	}
}