@use "../mixins/fluid-property";
@use "../zurb/breakpoint";

.wp-block-table {
	max-width: 100%;
	overflow: scroll;

	&.aligncenter {

		table {
			margin-left: auto;
			margin-right: auto;
		}
	}
}